<template>
    <div>
        <div id="page-wrapper">
            <div class="page-full">
                <div class="page-container">
                    <header class="header-desktop">
                        <app-topbar></app-topbar>
                    </header>

                    <div class="main-content">
                        <slot></slot>
                    </div>
                </div>
            </div>

            <footer id="main-footer" class="pt-5"></footer>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        components: {

        },
        mounted()
        {

        },
        methods: {

        },
    }
</script>