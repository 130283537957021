<template>
    <div>
        <card-default v-show="!isCreating && !isEditing" title="Editor de DNS" icon="fas fa-globe">
            <template slot="buttons">
                <button type="button" class="btn btn-primary btn-rounded" @click="showCreateForm">Adicionar registro</button>
                <button type="button" class="btn btn-secondary btn-rounded" data-toggle="modal" data-target="#modalConfirmReset">Restaurar padrão</button>
            </template>

            <p>
                Com o Editor de DNS você pode adicionar, editar ou remover registros da zona DNS de seu domínio.
            </p>
            <p class="mb-4">
                O nome do registro na tabela abaixo é um prefixo do seu domínio atual. Por exemplo, em um registro onde
                o nome exibido é <strong>www</strong>, o seu nome real será <strong>www.seudominio.com.br</strong>.
                Nos registros onde o nome for <strong>@</strong> entende-se que o nome real é apenas o seu domínio, sem um prefixo.
            </p>

            <div class="btn-toolbar mb-3" role="toolbar">
                <div class="input-group input-group-rounded" v-input-group-focus>
                    <div class="input-group-prepend">
                        <div class="input-group-text bg-white pr-0">
                            <i class="fas fa-search" aria-hidden="true"></i>
                        </div>
                    </div>
                    <input type="search" class="form-control border-left-0" v-model="filterText" placeholder="Buscar registros..." spellcheck="false">
                </div>
            </div>

            <div class="card card-table">
                <table class="table table-dns" v-bind:class="[records.length ? 'table-hover' : '']">
                    <thead>
                        <tr>
                            <th scope="col" class="col-200">Nome</th>
                            <th scope="col">Registro</th>
                            <th scope="col" class="col-options "><span class="sr-only">Opções</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="isLoading">
                            <td colspan="3">
                                <i class="fas fa-circle-notch fa-spin mr-1"></i> Carregando...
                            </td>
                        </tr>
                        <tr v-else-if="!isLoading && !records.length">
                            <td colspan="3">Nenhum registro encontrado.</td>
                        </tr>
                        <template v-else v-for="(item, index) in filteredItems">
                            <tr v-if="index === 0 || item.type !== filteredItems[index-1].type || (item.type === 'TXT' && item.txtType !== filteredItems[index-1].txtType)" class="table-active table-sm">
                                <td colspan="3">
                                    <span v-if="item.type === 'TXT' && item.txtType">{{ item.txtType }}</span>
                                    <span v-else>{{ item.type }}</span>
                                    <span v-if="item.type === 'A'"> (Hostname)</span>
                                    <span v-if="item.type === 'CNAME'"> (Alias)</span>
                                    <span v-if="item.type === 'MX'"> (Mail Exchanger)</span>
                                    <span v-if="item.type === 'SRV'"> (Service)</span>
                                    <span v-if="item.type === 'TXT' && !item.txtType"> (Text)</span>
                                    <span v-if="item.type === 'TXT' && item.txtType === 'DKIM'"> (DomainKeys Identified Mail)</span>
                                    <span v-if="item.type === 'TXT' && item.txtType === 'SPF'"> (Sender Policy Framework)</span>
                                    <span v-if="item.type === 'TXT' && item.txtType === 'DMARC'"> (Domain-based Message Authentication, Reporting, and Conformance)</span>
                                </td>
                            </tr>
                            <tr class="copy-text-wrapper">
                                <td class="col-200 col-nowrap">
                                    {{ item.name || '@' }}
                                </td>
                            <td v-bind:class="{ 'col-record': item.type === 'TXT' }">
                                    <span v-if="item.type === 'TXT'" :title="item.value" v-bind:class="{'txt-value': item.type === 'TXT'}">{{ item.value }}</span>
                                <text-copy v-else :text="item.value" :title="item.value"></text-copy>
                                    <span v-if="item.type === 'MX' || item.type === 'SRV'" class="d-block">Prioridade: {{ item.priority }}</span>
                                    <span v-if="item.type === 'SRV'" class="d-block">Peso: {{ item.weight }}</span>
                                    <span v-if="item.type === 'SRV'" class="d-block">Porta: {{ item.port }}</span>
                            </td>
                            <td class="col-options">
                                <div class="btn-group visible-on-hover" role="group">
                                    <button type="button" class="btn btn-sm btn-outline-primary" title="Editar" @click="showEditForm(index)">
                                        <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                                        <span class="sr-only">Editar</span>
                                    </button>
                                    <button type="button" class="btn btn-sm btn-outline-danger" title="Excluir" @click="confirmRemove(index)">
                                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                                        <span class="sr-only">Excluir</span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </card-default>

        <card-default v-show="isCreating || isEditing">
            <h3 class="card-title-main mb-4">
                {{ isCreating ? 'Adicionar registro' : (isEditing ? 'Editar registro' : '') }}
            </h3>

            <form role="form" @submit.prevent="save">
                <div class="col-lg-3 px-0">
                    <form-control :error="errors.type">
                        <label class="form-label" for="recordType">Tipo</label>
                        <select id="recordType" class="form-control input-rounded" v-model="record.type" :disabled="isEditing">
                            <option value="A">A</option>
                            <option value="CNAME">CNAME</option>
                            <option value="TXT">TXT</option>
                            <option value="MX">MX</option>
                            <option value="SRV">SRV</option>
                        </select>
                    </form-control>
                </div>

                <form-control :error="errors.name" v-show="record.type !== 'MX'">
                    <label class="form-label" for="recordName">Nome</label>
                    <input type="text" id="recordName" class="form-control input-rounded" v-model="record.name" :disabled="isEditing" spellcheck="false">
                </form-control>

                <form-control :error="errors.value" v-show="record.type !== 'TXT'">
                    <label class="form-label" for="recordData">
                        <span v-if="record.type === 'SRV'">Destino</span>
                        <span v-else>Valor do registro</span>
                    </label>
                    <input type="text" id="recordData" class="form-control input-rounded" v-model="record.value" spellcheck="false">
                </form-control>

                <form-control :error="errors.value" v-show="record.type === 'TXT'">
                    <label class="form-label" for="recordDataTxt">Valor do registro</label>
                    <textarea id="recordDataTxt" rows="6" class="form-control input-rounded" v-model="record.value"></textarea>
                </form-control>

                <form-control v-show="record.type === 'MX' || record.type === 'SRV'" :error="errors.priority">
                    <label class="form-label" for="recordPriority">Prioridade</label>
                    <input type="text" id="recordPriority" class="form-control input-rounded" maxlength="5" v-model="record.priority" v-only-digits>
                </form-control>

                <form-control v-show="record.type === 'SRV'" :error="errors.weight">
                    <label class="form-label" for="recordWeight">Peso</label>
                    <input type="text" id="recordWeight" class="form-control input-rounded" maxlength="5" v-model="record.weight" v-only-digits>
                </form-control>

                <form-control v-show="record.type === 'SRV'" :error="errors.port">
                    <label class="form-label" for="recordPort">Porta</label>
                    <input type="text" id="recordPort" class="form-control input-rounded" maxlength="5" v-model="record.port" v-only-digits>
                </form-control>

                <form-control :error="errors.ttl">
                    <label class="form-label" for="recordTtl">
                        TTL (Time To Live)
                        <a data-toggle="collapse" href="#recordTtlHelp" aria-expanded="false" aria-controls="recordTtlHelp" title="Clique para expandir">
                            <i class="fas fa-question-circle"></i>
                        </a>
                    </label>
                    <div id="recordTtlHelp" class="collapse">
                        <p class="form-text text-muted">
                            Determina quanto tempo levará para uma alteração nesse registro ser efetuada.
                        </p>
                    </div>
                    <select id="recordTtl" class="form-control input-rounded" v-model="record.ttl">
                        <option value="86400">24 horas</option>
                        <option value="43200">12 horas</option>
                        <option value="21600">6 horas</option>
                        <option value="14400">4 horas</option>
                        <option value="10800">3 horas</option>
                        <option value="3600">1 hora</option>
                        <option value="1800">30 minutos</option>
                        <option value="600">10 minutos</option>
                        <option value="60">1 minuto</option>
                    </select>
                </form-control>

                <div class="form-buttons">
                <button-submit ref="submit" class="btn-rounded">Salvar</button-submit>
                <button type="button" ref="cancel" class="btn btn-secondary btn-rounded ml-1" @click="cancelForm">Cancelar</button>
                </div>
            </form>
        </card-default>

        <div id="modalConfirmReset" ref="modalConfirmReset" role="dialog" aria-hidden="true" class="modal fade modal-confirm" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered">
                <div tabindex="-1" role="document" class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Restaurar configuração padrão</h5>
                    </header>
                    <div class="modal-body">
                        <p>
                            Todos os registros de DNS atuais serão <strong>excluídos</strong> e os registros padrão
                            serão recriados.
                        </p>
                        <p class="mb-0">
                            Tem certeza que deseja continuar?
                        </p>
                    </div>
                    <footer class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-rounded" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-primary btn-rounded" @click="reset">Restaurar</button>
                    </footer>
                </div>
            </div>
        </div>

        <div id="modalConfirmRemove" ref="modalConfirmRemove" role="dialog" aria-hidden="true" class="modal fade" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered">
                <div tabindex="-1" role="document" class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Remover registro DNS</h5>
                    </header>
                    <div class="modal-body">
                        <p>
                            Tem certeza que deseja remover o seguinte registro DNS?
                        </p>

                        <table class="table table-sm table-borderless mb-0" v-if="removeIndex">
                            <tr>
                                <th class="col-50">Tipo:</th><td>{{ records[removeIndex].type }}</td>
                            </tr>
                            <tr>
                                <th class="col-50">Nome:</th><td>{{ records[removeIndex].name || '@' }}</td>
                            </tr>
                            <tr>
                                <th class="col-50">Dados:</th>
                                <td>
                                    <span v-if="records[removeIndex].type == 'TXT'">
                                        {{ records[removeIndex].value.length > 30 ? records[removeIndex].value.substr(0,30) + '...' : records[removeIndex].value }}
                                    </span>
                                    <span v-else>{{ records[removeIndex].value }}</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <footer class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-rounded" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-danger btn-rounded" @click="remove">Remover</button>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CardDefault from '../../components/CardDefault'
    import FormControl from '../../components/FormControl'
    import TextCopy    from '../../components/TextCopy'

    export default {
        props: ['resource'],
        data() {
            return {
                records: [],
                record: {},
                errors: {},

                isLoading:  false,
                isCreating: false,
                isEditing:  false,

                filterText: "",
                filteredItems: [],
                removeIndex: "",
            }
        },
        components: {
            CardDefault,
            FormControl,
            TextCopy,
        },
        mounted() {
            this.fetch();
        },
        methods: {
            fetch()
            {
                this.isLoading = true;

                return axios.get(`/api/domain/${this.resource}/dns`)
                    .then ((response) => {
                        this.records = response.data.data;
                        this.filteredItems = this.records;
                    })
                    .catch((error) => HandleErrors.formError(error, this))
                    .then (() => this.isLoading = false);
            },
            save()
            {
                let vm = this;
                this.errors = {};

                this.$refs.submit.$el.focus();
                this.$refs.submit.setLoading();
                this.$refs.cancel.disabled = true;

                let postData = {
                    url: `/api/domain/${this.resource}/dns`,
                    method: "post",
                    data: this.record,
                };

                if (this.isEditing) {
                    postData.url += "/" + this.record.id;
                    postData.method = "put";
                }

                axios(postData)
                    .then ((response) => {
                        this.cancelForm();
                        this.fetch();
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, vm);
                    })
                    .then (() => {
                        this.$refs.cancel.disabled = false;
                        this.$refs.submit.setLoading(false);
                    });
            },
            remove()
            {
                let removeIdx = this.removeIndex;
                let id = this.records[removeIdx].id;

                $(this.$refs.modalConfirmRemove).modal("hide");
                Loading.show();

                axios.delete(`/api/domain/${this.resource}/dns/${id}`)
                    .then ((response) => { this.fetch(); })
                    .catch((error) => HandleErrors.ajaxErrorAlert(error))
                    .then (() => Loading.hide());
            },
            reset()
            {
                $(this.$refs.modalConfirmReset).modal("hide");
                Loading.show();

                axios.post(`/api/domain/${this.resource}/dns/reset`)
                    .then ((response) => { this.fetch(); })
                    .catch((error) => alert(`Ocorreu um erro ao tentar restaurar os registros. Por favor, atualize sua página e tente novamente.`))
                    .then (() => Loading.hide());
            },

            showCreateForm()
            {
                this.record     = { type: 'A', ttl: 3600, priority: 0 };
                this.isCreating = true;
                this.isEditing  = false;
            },
            showEditForm(index)
            {
                this.record   = $.extend({}, this.records[index]);
                this.isCreating = false;
                this.isEditing  = true;
            },
            cancelForm()
            {
                this.isCreating = false;
                this.isEditing  = false;
                this.record     = {};
            },
            confirmRemove(index)
            {
                this.removeIndex = index;
                $(this.$refs.modalConfirmRemove).modal("show");
            }
        },
        watch: {
            filterText: function(val)
            {
                if (val) {
                    let searchTerm = val.trim().toLowerCase();

                    this.filteredItems = this.records.filter((element) => {
                        return element.name.toLowerCase().indexOf(searchTerm) !== -1 ||
                               element.type.toLowerCase().indexOf(searchTerm) !== -1 ||
                               element.value.toLowerCase().indexOf(searchTerm) !== -1;
                    });
                }
                else {
                    this.filteredItems = this.records;
                }
            },
        }
    }
</script>
