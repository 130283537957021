<template>
    <div>
        <card-default :title="$route.meta.title" icon="fas fa-globe">
            <template slot="buttons">
                <button type="button" class="btn btn-primary btn-rounded" data-toggle="modal" data-target="#modalAddSubdomain" :disabled="!hosting || hosting.status !== 'Active'">
                    Adicionar subdomínio
                </button>
            </template>

            <p class="mb-4">
                Um subdomínio é uma extensão do seu domínio principal podendo funcionar como uma subseção do seu site.
                Use subdomínios para definir áreas importantes do seu site com URLs próprias sem precisar registrar um novo domínio.
                Por exemplo, você pode criar um subdomínio para seu blog que pode ser acessado por <strong>blog.meudominio.com</strong>.
            </p>

            <div class="btn-toolbar mb-3" role="toolbar">
                <div class="input-group input-group-rounded" v-input-group-focus>
                    <div class="input-group-prepend">
                        <div class="input-group-text bg-white pr-0">
                            <i class="fas fa-search" aria-hidden="true"></i>
                        </div>
                    </div>
                    <input type="search" class="form-control border-left-0" v-model="filterText" placeholder="Buscar subdomínios..." spellcheck="false">
                </div>
            </div>

            <div class="card card-table">
                <table class="table" v-bind:class="[subdomains.length ? 'table-hover' : '']">
                    <thead>
                        <tr>
                            <th scope="col">Subdomínio</th>
                            <th scope="col">Diretório</th>
                            <th scope="col" class="col-options "><span class="sr-only">Opções</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="isLoading">
                            <td colspan="3">
                                <loading-message></loading-message>
                            </td>
                        </tr>

                        <tr v-else-if="!isLoading && !subdomains.length">
                            <td colspan="3">
                                Nenhum subdomínio encontrado.
                            </td>
                        </tr>

                        <tr v-else v-for="item in filteredItems">
                            <td class="col-nowrap">
                                {{ item.fullDomain }}
                            </td>
                            <td class="col-nowrap">
                                <span class="text-monospace">{{ item.fullDir }}</span>
                            </td>
                            <td class="col-options">
                                <div class="btn-group visible-on-hover" role="group">
                                    <a class="btn btn-sm btn-outline-primary" title="Acessar" :href="`http://${item.fullDomain}`" target="_blank">
                                        <i class="fas fa-external-link-alt" aria-hidden="true"></i>
                                        <span class="sr-only">Acessar</span>
                                    </a>

                                    <button type="button" class="btn btn-sm btn-outline-primary" title="Alterar diretório" @click="openEditSubdomainModal(item)">
                                        <i class="far fa-folder-open" aria-hidden="true"></i>
                                        <span class="sr-only">Alterar diretório</span>
                                    </button>

                                    <button type="button" class="btn btn-sm btn-outline-danger" title="Excluir" @click="confirmRemove(item)">
                                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                                        <span class="sr-only">Excluir</span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </card-default>

        <div class="modal fade" id="modalAddSubdomain" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Adicionar subdomínio</h5>
                    </header>
                    <form accept-charset="UTF-8" @submit.prevent="addSubdomain">
                        <div class="modal-body">
                            <form-control :error="errors.domain">
                                <label class="form-label ml-2" for="subdomainDomain">Subdomínio</label>
                                <div class="input-group input-group-rounded" v-input-group-focus>
                                    <input type="text" id="subdomainDomain" class="form-control" maxlength="100" v-model="subdomain.domain" spellcheck="false">
                                    <div class="input-group-append">
                                        <span class="input-group-text">
                                            <template v-if="hosting">{{ '.' + hosting.domain }}</template>
                                        </span>
                                    </div>
                                </div>
                            </form-control>

                            <form-control class="mb-0" :error="errors.dir">
                                <label class="form-label ml-2" for="subdomainDir">Diretório</label>
                                <div class="input-group input-group-rounded" v-input-group-focus>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text px-4">/public_html/</span>
                                    </div>
                                    <input type="text" id="subdomainDir" class="form-control" v-model="subdomain.dir">
                                </div>
                            </form-control>
                        </div>

                        <footer class="modal-footer justify-content-start">
                            <button-submit ref="submitAddSubdomain" class="btn-rounded">Adicionar</button-submit>
                            <button type="button" ref="cancelAddSubdomain" class="btn btn-secondary btn-rounded" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" ref="modalEditSubdomain" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Alterar diretório</h5>
                    </header>
                    <form accept-charset="UTF-8" @submit.prevent="editSubdomain">
                        <div class="modal-body">
                            <div class="form-group">
                                <label class="form-label ml-2">Subdomínio</label>
                                <input type="text" class="form-control input-rounded" :value="newSubdomain.fullDomain" readonly>
                            </div>

                            <form-control class="mb-0" :error="errors.dir">
                                <label class="form-label ml-2" for="newSubdomainDir">Diretório</label>
                                <div class="input-group input-group-rounded" v-input-group-focus>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text px-4">/public_html/</span>
                                    </div>
                                    <input type="text" id="newSubdomainDir" class="form-control" v-model="newSubdomain.dir">
                                </div>
                            </form-control>
                        </div>

                        <footer class="modal-footer justify-content-start">
                            <button-submit ref="submitEditSubdomain" class="btn-rounded">Alterar</button-submit>
                            <button type="button" ref="cancelEditSubdomain" class="btn btn-secondary btn-rounded" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>

        <modal-confirm ref="modalConfirmRemove" title="Remover subdomínio" confirm-text="Remover" danger @confirm="removeSubdomain">
            <p class="mb-0">
                O subdomínio <strong v-if="itemToRemove">{{ itemToRemove.fullDomain }}</strong> será removido permanentemente.
                Tem certeza que deseja continuar?
            </p>
        </modal-confirm>
    </div>
</template>

<script>
    import CardDefault    from '../../components/CardDefault'
    import FormControl    from '../../components/FormControl'
    import LoadingMessage from '../../components/LoadingMessage'
    import ModalConfirm   from '../../components/ModalConfirm'
    import { getHosting } from '../../services/HostingService'

    export default {
        props: ['resource'],
        data() {
            return {
                hosting: null,
                subdomains: [],
                subdomain: {},
                newSubdomain: {},
                errors: {},
                isLoading: false,

                filterText: "",
                filteredItems: [],

                itemToRemove: null,
            }
        },
        components: {
            CardDefault,
            FormControl,
            LoadingMessage,
            ModalConfirm,
        },
        mounted()
        {
            getHosting(this.resource)
                .then((hosting) => this.hosting = hosting);

            this.fetchSubdomains();
        },
        methods: {
            /**
             * Listar subdomínios.
             */
            fetchSubdomains()
            {
                this.isLoading = true;

                axios.get(`/api/domain/${this.resource}/subdomain`)
                    .then ((response) => {
                        this.subdomains = response.data.data;
                        this.filteredItems = this.subdomains.slice(0);
                    })
                    .catch((error) => HandleErrors.formError(error, this))
                    .then (() => this.isLoading = false);
            },

            /**
             * Adicionar subdomínio.
             */
            addSubdomain()
            {
                let vm = this;
                this.errors = {};

                this.$refs.submitAddSubdomain.$el.focus();
                this.$refs.submitAddSubdomain.setLoading();
                this.$refs.cancelAddSubdomain.disabled = true;

                this.isLoading = true;

                axios.post(`/api/domain/${this.resource}/subdomain`, this.subdomain)
                    .then((response) => {
                        this.fetchSubdomains();

                        $("#modalAddSubdomain").modal("hide");

                        setTimeout(() => {
                            this.subdomain = {};
                            this.errors = {};
                            this.$refs.cancelAddSubdomain.disabled = false;
                            this.$refs.submitAddSubdomain.setLoading(false);
                        }, 300);
                    })
                    .catch((response) => {
                        HandleErrors.formError(response, vm);
                        this.$refs.cancelAddSubdomain.disabled = false;
                        this.$refs.submitAddSubdomain.setLoading(false);
                        this.isLoading = false;
                    });
            },

            openEditSubdomainModal(item)
            {
                this.newSubdomain = { domain: item.domain, fullDomain: item.fullDomain, dir: item.dir };
                this.errors = {};

                $(this.$refs.modalEditSubdomain).modal("show");
            },

            /**
             * Alterar diretório do subdomínio.
             */
            editSubdomain()
            {
                let vm = this;
                this.errors = {};

                this.$refs.submitEditSubdomain.$el.focus();
                this.$refs.submitEditSubdomain.setLoading();
                this.$refs.cancelEditSubdomain.disabled = true;

                this.isLoading = true;

                axios.put(`/api/domain/${this.resource}/subdomain/${this.newSubdomain.domain}`, this.newSubdomain)
                    .then((response) => {
                        this.fetchSubdomains();

                        $(this.$refs.modalEditSubdomain).modal("hide");

                        setTimeout(() => {
                            this.newSubdomain = {};
                            this.errors = {};
                            this.$refs.cancelEditSubdomain.disabled = false;
                            this.$refs.submitEditSubdomain.setLoading(false);
                        }, 300);
                    })
                    .catch((response) => {
                        HandleErrors.formError(response, vm);
                        this.$refs.cancelEditSubdomain.disabled = false;
                        this.$refs.submitEditSubdomain.setLoading(false);
                        this.isLoading = false;
                    });
            },

            /**
             * Exibir confirmação para remover subdomínio.
             */
            confirmRemove(item)
            {
                this.itemToRemove = item;
                $(this.$refs.modalConfirmRemove.$el).modal("show");
            },

            /**
             * Remover subdomínio.
             */
            removeSubdomain()
            {
                let domain = this.itemToRemove.domain;

                const idx  = this.subdomains.findIndex(el => el.domain === domain);
                const idxF = this.filteredItems.findIndex(el => el.domain === domain);

                $(this.$refs.modalConfirmRemove.$el).modal("hide");
                Loading.show();

                setTimeout(() => {
                    this.subdomains.splice(idx, 1);
                    if (idxF !== -1) this.filteredItems.splice(idxF, 1);
                    Loading.hide();
                }, 2000);
                
                axios.delete(`/api/domain/${this.resource}/subdomain/${domain}`)
                    .then ((response) => {})
                    .catch((error) => alert(`Ocorreu um erro ao tentar excluir o subdomínio. Por favor, atualize sua página e tente novamente.`));
            },
        },
        watch: {
            filterText: function(val)
            {
                if (val) {
                    let searchTerm = val.trim().toLowerCase();

                    this.filteredItems = this.subdomains.filter((element) => {
                        return element.domain.toLowerCase().indexOf(searchTerm) !== -1;
                    });
                }
                else {
                    this.filteredItems = this.subdomains.slice(0);
                }
            },
        }
    }
</script>
