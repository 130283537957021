<template>
    <div>
        <card-default :title="$route.meta.title" icon="fab fa-php">

            <template slot="buttons">
                <button type="button" class="btn btn-secondary btn-rounded" @click="restore">Restaurar padrão</button>
            </template>

            <div v-if="isLoading" class="text-center py-4">
                <loading-message></loading-message>
            </div>

            <table v-if="php && php.versions" class="table table-borderless table-hover mb-0">
                <tbody>
                    <tr>
                        <td class="py-2 align-middle">
                            <label class="form-label mb-0">Versão do PHP</label>
                        </td>
                        <td class="py-2 align-middle text-right">
                            <b-form-radio-group
                                buttons
                                button-variant="outline-primary"
                                :options="php.versions"
                                v-model="php.version"></b-form-radio-group>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table v-if="php" class="table table-borderless table-hover">
                <tbody>
                    <!--<tr>
                        <td class="py-2 align-middle">
                            <label class="form-label">PHP-FPM</label>
                            <span class="form-text text-muted">
                                Alternativa ao PHP FastCGI com funcionalidades voltadas a sites com grande número de acessos.
                            </span>
                        </td>
                        <td class="py-0 align-middle text-right">
                            <span class="switch switch-sm">
                                <input type="checkbox" class="switch" id="switch-php_fpm" v-model="php.php_fpm">
                                <label for="switch-php_fpm" class="mb-0"></label>
                            </span>
                        </td>
                    </tr>-->

                    <tr>
                        <td class="py-2 align-middle">
                            <label class="form-label">display_errors</label>
                            <span class="form-text text-muted">
                                Exibir as mensagens de erro direto no website.
                            </span>
                        </td>
                        <td class="py-0 align-middle text-right">
                            <span class="switch switch-sm">
                                <input type="checkbox" class="switch" id="switch-display_errors" v-model="php.display_errors">
                                <label for="switch-display_errors" class="mb-0"></label>
                            </span>
                        </td>
                    </tr>

                    <!--<tr>
                        <td class="py-2 align-middle">
                            display_startup_errors
                            <span class="form-text text-muted">
                                Exibir erros que ocorrerem durante a inicialização do PHP.
                            </span>
                        </td>
                        <td class="py-0 align-middle text-right">
                            <span class="switch switch-sm">
                                <input type="checkbox" class="switch" id="switch-display_startup_errors" v-model="php.display_startup_errors">
                                <label for="switch-display_startup_errors" class="mb-0"></label>
                            </span>
                        </td>
                    </tr>-->

                    <tr>
                        <td class="py-2 align-middle">
                            <label class="form-label">post_max_size</label>
                            <span class="form-text text-muted">
                                Tamanho máximo dos dados enviados via POST.
                            </span>
                        </td>
                        <td class="py-0 align-middle text-right">
                            <span class="d-inline-block align-middle mr-1">
                                {{ php.post_max_size + ' MB' }}
                            </span>
                            <vue-slider
                                v-if="php.post_max_size"
                                class="d-inline-block align-middle pr-0"
                                :value="php.post_max_size"
                                :min="2"
                                :max="256"
                                :interval="2"
                                :width="200"
                                :height="8"
                                :dotSize="22"
                                :tooltip="false"
                                :speed="0.1"
                                :use-keyboard="true"
                                @callback="val => php.post_max_size = val">
                            </vue-slider>
                        </td>
                    </tr>

                    <tr>
                        <td class="py-2 align-middle">
                            <label class="form-label">upload_max_filesize</label>
                            <span class="form-text text-muted">
                                Tamanho máximo de um arquivo enviado.
                            </span>
                        </td>
                        <td class="py-0 align-middle text-right">
                            <span class="d-inline-block align-middle mr-1">
                                {{ php.upload_max_filesize + ' MB' }}
                            </span>
                            <vue-slider
                                v-if="php.upload_max_filesize"
                                class="d-inline-block align-middle pr-0"
                                :value="php.upload_max_filesize"
                                :min="2"
                                :max="256"
                                :interval="2"
                                :width="200"
                                :height="8"
                                :dotSize="22"
                                :tooltip="false"
                                :speed="0.1"
                                :use-keyboard="true"
                                @callback="val => php.upload_max_filesize = val">
                            </vue-slider>
                        </td>
                    </tr>

                    <tr>
                        <td class="py-2 align-middle">
                            <label class="form-label">memory_limit</label>
                            <span class="form-text text-muted">
                                Quantidade máxima de memória que um script pode alocar.
                            </span>
                        </td>
                        <td class="py-0 align-middle text-right">
                            <span class="d-inline-block align-middle mr-1">
                                {{ php.memory_limit + ' MB' }}
                            </span>
                            <vue-slider
                                v-if="php.memory_limit"
                                class="d-inline-block align-middle pr-0"
                                :value="php.memory_limit"
                                :min="16"
                                :max="512"
                                :interval="16"
                                :width="200"
                                :height="8"
                                :dotSize="22"
                                :tooltip="false"
                                :speed="0.1"
                                :use-keyboard="true"
                                @callback="val => php.memory_limit = val">
                            </vue-slider>
                        </td>
                    </tr>

                    <tr>
                        <td class="py-2 align-middle">
                            <label class="form-label">max_execution_time</label>
                            <span class="form-text text-muted">
                                Tempo de execução máxima de um script.
                            </span>
                        </td>
                        <td class="py-0 align-middle text-right">
                            <span class="d-inline-block align-middle mr-1">
                                {{ php.max_execution_time + ' s' }}
                            </span>
                            <vue-slider
                                v-if="php.max_execution_time"
                                class="d-inline-block align-middle pr-0"
                                :value="php.max_execution_time"
                                :min="30"
                                :max="300"
                                :interval="10"
                                :width="200"
                                :height="8"
                                :dotSize="22"
                                :tooltip="false"
                                :speed="0.1"
                                :use-keyboard="true"
                                @callback="val => php.max_execution_time = val">
                            </vue-slider>
                        </td>
                    </tr>

                    <tr>
                        <td class="py-2 align-middle">
                            <label class="form-label">max_input_time</label>
                            <span class="form-text text-muted">
                                Tempo máximo permitido para interpretar dados de entrada em um script.
                            </span>
                        </td>
                        <td class="py-0 align-middle text-right">
                            <span class="d-inline-block align-middle mr-1">
                                {{ php.max_input_time + ' s' }}
                            </span>
                            <vue-slider
                                v-if="php.max_input_time"
                                class="d-inline-block align-middle pr-0"
                                :value="php.max_input_time"
                                :min="30"
                                :max="300"
                                :interval="10"
                                :width="200"
                                :height="8"
                                :dotSize="22"
                                :tooltip="false"
                                :speed="0.1"
                                :use-keyboard="true"
                                @callback="val => php.max_input_time = val">
                            </vue-slider>
                        </td>
                    </tr>

                    <tr>
                        <td class="py-2 align-middle">
                            <label class="form-label">max_input_vars</label>
                            <span class="form-text text-muted">
                                Quantidade máxima de variáveis de entrada aceitas.
                            </span>
                        </td>
                        <td class="py-0 align-middle text-right">
                            <span class="d-inline-block align-middle mr-1">
                                {{ php.max_input_vars }}
                            </span>
                            <vue-slider
                                v-if="php.max_input_vars"
                                class="d-inline-block align-middle pr-0"
                                :value="php.max_input_vars"
                                :min="1000"
                                :max="10000"
                                :interval="1000"
                                :width="200"
                                :height="8"
                                :dotSize="22"
                                :tooltip="false"
                                :speed="0.1"
                                :use-keyboard="true"
                                @callback="val => php.max_input_vars = val">
                            </vue-slider>
                        </td>
                    </tr>

                    <tr>
                        <td class="py-2 align-middle">
                            <label class="form-label">zlib.output_compression</label>
                            <span class="form-text text-muted">
                                Permitir a compressão de páginas do website de modo transparente.
                            </span>
                        </td>
                        <td class="py-0 align-middle text-right">
                            <span class="switch switch-sm">
                                <input type="checkbox" class="switch" id="switch-output_compression" v-model="php.output_compression">
                                <label for="switch-output_compression" class="mb-0"></label>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <button type="button" v-show="php" ref="btnSubmit" class="btn btn-primary btn-rounded btn-block btn-submit mb-0 mt-4" @click="update" disabled>Salvar</button>

        </card-default>
    </div>
</template>

<script>
    import CardDefault from '../../components/CardDefault'
    import LoadingMessage from '../../components/LoadingMessage'
    import vueSlider from 'vue-slider-component'
    import GlobalAlert from '../../services/GlobalAlertService'

    export default {
        props: ['resource'],
        data() {
            return {
                php: null,
                currentPhpVersion: "",
                isLoading: false,
            }
        },
        components: {
            CardDefault,
            LoadingMessage,
            vueSlider,
        },
        mounted() {
            this.fetch();
        },
        methods: {
            /**
             * Carregar dados.
             */
            fetch()
            {
                this.isLoading = true;

                axios.get(`/api/hosting/${this.resource}/php?directives=1`)
                    .then ((response) => {
                        let php = response.data.data;

                        // Fix min and max values
                        php.post_max_size       = Math.min(Math.max(php.post_max_size, 2), 256);
                        php.upload_max_filesize = Math.min(Math.max(php.upload_max_filesize, 2), 256);
                        php.memory_limit        = Math.min(Math.max(php.memory_limit, 16), 512);
                        php.max_execution_time  = Math.min(Math.max(php.max_execution_time, 30), 300);
                        php.max_input_time      = Math.min(Math.max(php.max_input_time, 30), 300);
                        php.max_input_vars      = Math.min(Math.max(php.max_input_vars, 1000), 10000);

                        this.php = php;
                        this.currentPhpVersion = this.php.version;
                        this.$refs.btnSubmit.disabled = false;
                    })
                    .catch((error) => HandleErrors.formError(error))
                    .then (() => this.isLoading = false);
            },

            /**
             * Atualizar dados.
             */
            update()
            {
                this.$refs.btnSubmit.disabled = true;
                this.$refs.btnSubmit.classList.add("btn-loading");

                let data = $.extend({}, this.php);

                if (this.currentPhpVersion === this.php.version) {
                    data.version = null;
                }

                axios.post(`/api/hosting/${this.resource}/php`, data)
                    .then ((response) => {
                        this.currentPhpVersion = this.php.version;
                        GlobalAlert.success("Configurações atualizadas com sucesso!");

                        this.$eventBus.$emit('php-changed');
                    })
                    .catch((error) => HandleErrors.formError(error))
                    .then (() => {
                        this.$refs.btnSubmit.classList.remove("btn-loading");
                        this.$refs.btnSubmit.disabled = false;
                    });
            },

            /**
             * Restaurar valores padrão para as diretivas do PHP.
             */
            restore()
            {
                if (!confirm('Tem certeza que deseja restaurar os valores padrão?')) return;

                Loading.show();

                axios.post(`/api/hosting/${this.resource}/php/restore`)
                    .then ((response) => {
                        window.location.reload();
                    })
                    .catch((error) => {
                        HandleErrors.formError(error);
                        Loading.hide();
                    });
            }
        }
    }
</script>
