<template>
    <div>
        <card-default v-show="!isEditing" :title="$route.meta.title" icon="far fa-envelope">
            <template slot="buttons">
                <button type="button" class="btn btn-primary btn-rounded ml-1" @click="openAddListModal">Adicionar lista de e-mails</button>
            </template>

            <div class="btn-toolbar mb-3" role="toolbar">
                <div class="input-group input-group-rounded" v-input-group-focus>
                    <div class="input-group-prepend">
                        <div class="input-group-text bg-white pr-0">
                            <i class="fas fa-search" aria-hidden="true"></i>
                        </div>
                    </div>
                    <input type="search" class="form-control border-left-0" v-model="filterText" placeholder="Buscar listas..." spellcheck="false">
                </div>
            </div>

            <div class="card card-table mb-0">
                <table class="table" v-bind:class="[lists.length ? 'table-hover' : '']">
                    <thead>
                        <tr>
                            <th scope="col">Lista</th>
                            <th scope="col" class="col-100 text-right">Membros</th>
                            <th scope="col" class="col-options"><span class="sr-only">Opções</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="isLoading">
                            <td colspan="3">
                                <loading-message>Carregando suas listas de e-mails&hellip;</loading-message>
                            </td>
                        </tr>
                        <tr v-else-if="!isLoading && !lists.length">
                            <td colspan="3">
                                Nenhuma lista de e-mails encontrada.
                            </td>
                        </tr>
                        <template v-else v-for="(item, index) in filteredItems">
                            <tr :key="`list-`+index">
                                <td>
                                    <span>{{ item.list }}</span>
                                </td>
                                <td class="col-100 col-nowrap text-right">
                                    <span>{{ item.members }}</span>
                                </td>
                                <td class="col-options">
                                    <div class="btn-group visible-on-hover" role="group">
                                        <button type="button" class="btn btn-sm btn-outline-primary" title="Configurações" @click="showEditForm(item)">
                                            <i class="fas fa-cog" aria-hidden="true"></i>
                                            <span class="sr-only">Configurações</span>
                                        </button>

                                        <button type="button" class="btn btn-sm btn-outline-danger" title="Excluir" @click="removeList(item)">
                                            <i class="far fa-trash-alt" aria-hidden="true"></i>
                                            <span class="sr-only">Excluir</span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </card-default>

        <card-default v-if="isEditing" title="Configurações da lista de e-mails" icon="far fa-envelope">
            <template slot="buttons">
                <button type="button" class="btn btn-secondary btn-rounded" @click="cancelEditForm">Voltar</button>
            </template>

            <div class="form-group">
                <label class="form-label ml-2">Lista de e-mails</label>
                <input 
                    type="text"
                    class="form-control input-rounded"
                    readonly
                    :value="(!listData.list && this.isLoadingList) ? 'Carregando...' : listData.list"
                />

                <div class="mt-3">
                    <button type="button" class="btn btn-danger btn-rounded ml-1" @click="removeList(list)" :disabled="isLoadingList">
                        Excluir
                    </button>
                </div>
            </div>
        </card-default>

        <card-default v-if="isEditing">
            <h5 class="card-subtitle">Membros</h5>
            <p class="mb-4">
                Lista dos endereços de e-mail pertencentes a esta lista de e-mails.
            </p>

            <div v-show="!listData.members && isLoadingList" class="mb-3">
                <i class="fas fa-circle-notch fa-spin mr-1"></i> Carregando...
            </div>

            <div v-if="listData && listData.members">
                <div 
                    v-for="(item, index) in listData.members" 
                    :key="'member_'+index" 
                    class="input-group input-group-rounded d-md-inline-flex w-auto mb-3 mr-md-2"
                >
                    <input type="text" class="form-control" v-bind:value="item" readonly disabled>
                    <div class="input-group-append">
                        <button class="btn btn-danger btn-rounded-right" type="button" title="Excluir" @click="removeMember(item)" :disabled="isLoadingList">
                            <i class="fas fa-times pr-1" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>

            <button type="button" class="btn btn-primary btn-rounded" @click="openAddMemberModal">Adicionar</button>
        </card-default>

        <div class="modal fade" ref="modalAddList" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Adicionar lista de e-mails</h5>
                    </header>
                    <form accept-charset="UTF-8" @submit.prevent="saveList">
                        <div class="modal-body">
                            <form-control class="mb-0" :error="errors.name">
                                <label class="form-label ml-2" for="listName">Nome da lista</label>
                                <div class="input-group input-group-rounded" v-input-group-focus>
                                    <input type="text" id="listName" class="form-control" maxlength="40" v-model="formData.name">
                                    <div class="input-group-append">
                                        <span class="input-group-text">{{ '@' + resource }}</span>
                                    </div>
                                </div>
                            </form-control>
                        </div>

                        <footer class="modal-footer justify-content-start">
                            <button-submit ref="submit" class="btn-rounded">Criar</button-submit>
                            <button type="button" ref="cancel" class="btn btn-secondary btn-rounded ml-1" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" ref="modalAddMember" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Adicionar membro</h5>
                    </header>
                    <form accept-charset="UTF-8" @submit.prevent="addMember">
                        <div class="modal-body">
                            <form-control class="mb-0" :error="errors.email">
                                <label class="form-label ml-2" for="memberEmail">E-mail</label>
                                <input type="email" id="memberEmail" class="form-control input-rounded" v-model="formData.email">
                            </form-control>
                        </div>

                        <footer class="modal-footer justify-content-start">
                            <button-submit ref="submitAddMember" class="btn-rounded">Enviar</button-submit>
                            <button type="button" ref="cancelAddMember" class="btn btn-secondary btn-rounded ml-1" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CardDefault       from '../../components/CardDefault'
    import FormControl       from '../../components/FormControl'
    import PasswordGenerator from '../../components/PasswordGenerator'
    import LoadingMessage    from '../../components/LoadingMessage'
    import TextCopy          from '../../components/TextCopy'
    import GlobalAlert       from '../../services/GlobalAlertService'

    export default {
        props: ['resource'],
        data() {
            return {
                // NEW
                lists: [],
                formData: {},
                
                isLoadingList: false,
                list: {},
                listData: {},

                // OLD
                emails: [], //x
                email: {}, //x
                password: {},
                alias: {},
                forward: {},
                autoresponder: {},
                quota: {},
                errors: {},

                isLoading: false,
                isLoadingAutoresponder: false,
                isEditing: false,

                filterText: "",
                filteredItems: [],

                // Antispam
                isLoadingAntispam: false,
                antispam: {},
            }
        },
        components: {
            CardDefault,
            FormControl,
            PasswordGenerator,
            LoadingMessage,
            TextCopy,
        },
        mounted()
        {
            $([this.$refs.modalAddList, this.$refs.modalAddMember])
                .on("shown.bs.modal", function() {
                    $(this).find("input:not([readonly])").get(0).focus();
                });

            this.fetchLists();
        },
        methods: {

            fetchLists()
            {
                this.isLoading = true;

                return axios.get(`/api/hosting/${this.resource}/email/lists`)
                    .then ((response) => {
                        this.lists = response.data.data;
                        this.filteredItems = this.lists.slice(0);
                    })
                    .catch((error) => HandleErrors.formError(error, this))
                    .then (() => this.isLoading = false);
            },

            openAddListModal()
            {
                this.formData = {};
                this.errors = {};

                $(this.$refs.modalAddList).modal("show");
            },

            openAddMemberModal(item)
            {
                this.formData = {};
                this.errors = {};

                $(this.$refs.modalAddMember).modal("show");
            },

            /**
             * Adicionar lista de e-mails.
             */
            saveList()
            {
                let vm = this;
                this.errors = {};

                this.$refs.submit.$el.focus();
                this.$refs.submit.setLoading();
                this.$refs.cancel.disabled = true;

                let postData = {
                    url: `/api/hosting/${this.resource}/email/lists`,
                    method: "post",
                    data: this.formData,
                };

                axios(postData)
                    .then ((response) => {
                        this.fetchLists();

                        $(this.$refs.modalAddList).modal("hide");

                        setTimeout(() => {
                            this.$refs.cancel.disabled = false;
                            this.$refs.submit.setLoading(false);
                        }, 300);
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, vm);
                        this.$refs.cancel.disabled = false;
                        this.$refs.submit.setLoading(false);
                    });
            },

            /**
             * Excluir lista de e-mails.
             */
            removeList(item)
            {
                if (!confirm('Aviso: Todas as suas mensagens serão excluídas permanentemente! Tem certeza que deseja excluir esta lista de e-mails?')) return;

                const list = item.list;
                const idx  = this.lists.findIndex(el => el.list === list);
                const idxF = this.filteredItems.findIndex(el => el.list === list);

                this.isLoading = true;
                Loading.show();

                setTimeout(() => {
                    if (idxF !== -1) this.filteredItems.splice(idxF, 1);
                    this.emails.splice(idx, 1);
                    if (this.isEditing) this.cancelEditForm();
                    Loading.hide();
                    this.isLoading = false;
                }, 3000);

                axios.delete(`/api/hosting/${this.resource}/email/lists/${list}`)
                    .then ((response) => {})
                    .catch((error) => {
                        HandleErrors.ajaxErrorAlert(error);
                    });
            },

            _fetchList(list)
            {
                if (typeof list === "undefined") {
                    if (this.list) {
                        list = this.list.list;
                    }
                    else {
                        return;
                    }
                }

                return axios.get(`/api/hosting/${this.resource}/email/lists/${list}`);
            },

            /**
             * Exibir tela de edição de uma lista de e-mails.
             */
            showEditForm(item)
            {
                this.list = item;
                this.isEditing = true;
                this.isLoadingList = true;

                this._fetchList(item.list)
                    .then ((response) => { this.listData = response.data.data })
                    .catch((error) => { HandleErrors.ajaxErrorAlert(error) })
                    .then (() => { this.isLoadingList = false });
            },

            /**
             * Fechar tela de edição de um endereço de e-mail (cancelar edição).
             */
            cancelEditForm()
            {
                this.isEditing = false;
                this.isLoadingList = false;
                this.list = {};
                this.listData = {};
            },

            addMember()
            {
                let vm = this;
                this.errors = {};

                this.$refs.submitAddMember.$el.focus();
                this.$refs.submitAddMember.setLoading();
                this.$refs.cancelAddMember.disabled = true;

                axios.post(`/api/hosting/${this.resource}/email/lists/${this.list.list}/member`, this.formData)
                    .then ((response) => {
                        this.isLoadingList = true;

                        this._fetchList()
                            .then ((response) => { this.listData = response.data.data })
                            .then (() => { this.isLoadingList = false });

                        if (this.isEditing) {
                            this.listData.members.push(response.data.data.email);
                        }

                        $(this.$refs.modalAddMember).modal("hide");

                        setTimeout(() => {
                            this.$refs.cancelAddMember.disabled = false;
                            this.$refs.submitAddMember.setLoading(false);
                        }, 300);
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, vm);
                        this.$refs.cancelAddMember.disabled = false;
                        this.$refs.submitAddMember.setLoading(false);
                    });
            },

            removeMember(email)
            {
                if (!confirm('Tem certeza que deseja excluir este membro da lista?')) return;

                let data  = { 'email': email };
                const idx = this.listData.members.findIndex(el => el === email);

                this.isLoadingList = true;
                Loading.show();

                setTimeout(() => {
                    this.listData.members.splice(idx, 1);
                    this.isLoadingList = false;
                    Loading.hide();
                }, 3000);

                axios.post(`/api/hosting/${this.resource}/email/lists/${this.list.list}/remove-member`, data)
                    .then ((response) => {})
                    .catch((error) => { HandleErrors.ajaxErrorAlert(error) });
            },
        },
        watch: {
            filterText: function(val)
            {
                if (val) {
                    let searchTerm = val.trim().toLowerCase();

                    this.filteredItems = this.lists.filter((element) => {
                        return element.list.toLowerCase().indexOf(searchTerm) !== -1;
                    });
                }
                else {
                    this.filteredItems = this.lists.slice(0);
                }
            },
        }
    }
</script>
