<template>
    <span class="copy-text">
        <span class="copy-text-value" v-bind:class="valueElmClasses" @click="selectText($event)">{{ textValue }}</span>
        <button type="button" ref="btnCopy" class="btn btn-link btn-copy ml-3" @click="copyToClipboard($event)">
            {{ labelCopy }}
        </button>
    </span>
</template>

<script>
    export default {
        props: {
            text: {
                type: [String, Number],
                default: "",
            },
            copy: {
                type: [String, Number],
                default: "",
            },
            valueClasses: {
                type: [String],
                default: "",
            },
        },
        data() {
            return {
                textValue: this.text,
                textToCopy: this.copy,
                labelCopy: "Copiar",
                labelCopied: "Copiado",
                labelChangeDelay: 3000,
            }
        },
        computed: {
            valueElmClasses: function () {
                let classes = this.valueClasses.split(" ");
                let objClasses = {};

                for (let i = 0; i < classes.length; i++) {
                    objClasses[classes[i]] = true;
                }

                return objClasses;
            }
        },
        methods: {
            selectText(event)
            {
                let copyTextElm = event.target.parentElement.querySelector(".copy-text-value");

                if (copyTextElm) {
                    let selection = window.getSelection();
                    let range = document.createRange();

                    range.selectNodeContents(copyTextElm);
                    selection.removeAllRanges();
                    selection.addRange(range);
                }
            },
            copyToClipboard(event)
            {
                if (this.textToCopy) {
                    this._copyHiddenElement(event);
                }
                else {
                    this._copyTextElement(event);
                }
            },
            _copyTextElement(event)
            {
                let copyTextElm = event.target.parentElement.querySelector(".copy-text-value");

                if (copyTextElm) {
                    let selection = window.getSelection();
                    let range = document.createRange();

                    range.selectNodeContents(copyTextElm);
                    selection.removeAllRanges();
                    selection.addRange(range);
                    document.execCommand("copy");

                    this._changeLabel(event);
                }
            },
            _copyHiddenElement(event)
            {
                let inputWrapper = document.createElement("div");
                let auxInput = document.createElement("input");

                auxInput.type = "text";
                auxInput.value = this.textToCopy;
                auxInput.style.opacity = "0";

                inputWrapper.appendChild(auxInput);
                document.body.appendChild(inputWrapper);

                let selection = window.getSelection();
                selection.removeAllRanges();

                auxInput.focus();
                auxInput.setSelectionRange(0, auxInput.value.length);
                document.execCommand("copy");

                document.body.removeChild(inputWrapper);

                this._changeLabel(event);
            },
            _changeLabel()
            {
                this.$refs.btnCopy.innerHTML = this.labelCopied;
                this.$refs.btnCopy.classList.add("text-success");

                setTimeout(
                    () => {
                        this.$refs.btnCopy.innerHTML = this.labelCopy;
                        this.$refs.btnCopy.classList.remove("text-success");
                    },
                    this.labelChangeDelay
                );
            }
        }
    }
</script>
