<template>
    <div>
        <card-default :title="$route.meta.title" icon="fas fa-lock">
            <div class="mb-4">
                <p>
                    O certificado SSL da <a href="https://letsencrypt.org/" target="_blank" rel="nofollow">Let’s Encrypt</a>
                    emitido tem a validade de 90 dias. Após esse período seu certificado será renovado automaticamente.
                </p>
            </div>

            <div v-if="isLoading" class="text-center py-4">
                <loading-message>Carregando as informações sobre o certificado SSL&hellip;</loading-message>
            </div>

            <div v-if="ssl">
                <div v-if="ssl.checking" class="alert alert-warning">
                    O certificado Let's Encrypt está sendo instalado em sua hospedagem e estará ativo em alguns minutos.
                </div>

                <div v-else>
                    <div v-if="ssl.active">
                        <div class="alert alert-success">
                            O certificado Let's Encrypt está habilitado em sua hospedagem.
                        </div>
                    </div>

                    <div v-else>
                        <div class="alert alert-danger">
                            Não foi possível ativar o certificado Let's Encrypt.<br>
                            Certifique-se de que o domínio <strong>{{ resource }}</strong> está registrado e
                            configurado corretamente.
                        </div>
                        <button type="button" ref="btnEnable" class="btn btn-success btn-rounded" @click="enable">
                            Ativar certificado SSL
                        </button>
                    </div>
                </div>
            </div>
        </card-default>

        <card-default v-if="ssl">
            <div class="d-flex justify-content-between align-items-center">
                <div class="flex-grow-1">
                    <h6 class="card-subtitle mt-0">Sempre usar HTTPS</h6>
                    <p class="mb-0">
                        Redirecionar todas as requisições do seu domínio com o protocolo <strong>http</strong> para
                        <strong>https</strong>.
                    </p>
                </div>

                <div v-show="isLoadingRedirect" class="pl-3">
                    <i class="d-block fas fa-circle-notch fa-spin"></i>
                </div>

                <div class="pl-3">
                    <span class="switch switch-sm">
                        <input
                            type="checkbox"
                            class="switch"
                            id="sslRedirect"
                            v-model="ssl.redirect"
                            @change="updateRedirect"
                            :disabled="isLoadingRedirect">
                        <label for="sslRedirect" class="mb-0"></label>
                    </span>
                </div>
            </div>
        </card-default>
    </div>
</template>

<script>
    import CardDefault    from '../../components/CardDefault'
    import LoadingMessage from '../../components/LoadingMessage'
    import GlobalAlert    from '../../services/GlobalAlertService'

    export default {
        props: ['resource'],
        data() {
            return {
                ssl: null,
                isLoading: false,
                isLoadingRedirect: false,
            }
        },
        components: {
            CardDefault,
            LoadingMessage,
        },
        mounted() {
            this.fetch();
        },
        methods: {
            fetch()
            {
                this.isLoading = true;

                return axios.get(`/api/hosting/${this.resource}/ssl`)
                    .then ((response) => this.ssl = response.data.data)
                    .catch((error) => HandleErrors.formError(error, this))
                    .then (() => this.isLoading = false);
            },
            enable()
            {
                this.$refs.btnEnable.disabled = true;
                Loading.show();

                return axios.post(`/api/hosting/${this.resource}/ssl/enable`)
                    .then ((response) => window.location.reload())
                    .catch((error) => {
                        HandleErrors.formError(error, this);
                        this.$refs.btnEnable.disabled = false;
                    })
                    .then (() => Loading.hide());
            },

            /**
             * Atualizar opção de redirecionamento para HTTPS.
             */
            updateRedirect()
            {
                const oldValue = this.ssl.redirect;

                this.isLoadingRedirect = true;

                axios.patch(`/api/hosting/${this.resource}/ssl/redirect`, { value: this.ssl.redirect })
                    .then ((response) => {
                        this.ssl.redirect = response.data.data.value;
                        GlobalAlert.success("Configuração atualizada com sucesso.");
                    })
                    .catch((error) => {
                        this.ssl.redirect = !oldValue;
                        HandleErrors.ajaxErrorAlert(error, 0);
                    })
                    .then (() => this.isLoadingRedirect = false);
            },
        }
    }
</script>
