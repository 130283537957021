<template>
    <div>
        <card-default v-show="!creating && !created" class="card-hosting-apps" :title="$route.meta.title" icon="fas fa-th">
            <p class="mb-4">
                Para instalar um novo aplicativo em sua hospedagem, escolha o item desejado na lista abaixo e clique no botão
                <strong>Instalar</strong>.
            </p>

            <div class="apps-wrapper">
                <div class="row">
                    <template v-for="(item, index) in apps">
                        <div class="col">
                            <div class="card">
                                <div class="card-body">
                                    <img class="app-logo" :src="'/images/apps/' + item.logo">

                                    <h5 class="card-title">{{ item.title }}</h5>

                                    <p class="card-text app-category">{{ item.category }}</p>

                                    <p v-if="!availableApps" class="card-text text-muted">
                                        <i class="fas fa-circle-notch fa-spin"></i>
                                    </p>
                                    <p v-else v-bind:class="{'invisible': (!availableApps[item.id])}" class="card-text app-version">
                                        Versão {{ availableApps && availableApps[item.id] ? availableApps[item.id].version : '' }}
                                    </p>

                                    <button type="button"
                                            class="btn btn-primary btn-rounded btn-install"
                                            @click="showInstallForm(index)">
                                        <span>Instalar</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </card-default>

        <card-default v-if="creating" class="card-hosting-apps" :title="$route.meta.title" icon="fas fa-th">
            <p class="mb-4">
                Insira as informações básicas para configuração inicial do site. O sistema vai gerar um login e senha
                para o aplicativo e o banco de dados será criado automaticamente (quando necessário).
                Você também pode usar a opção <strong>Editar informações avançadas</strong> para informar senhas e
                outros dados sensíveis manualmente.
            </p>

            <form accept-charset="UTF-8" @submit.prevent="save">
                <form-control>
                    <label class="form-label ml-2" for="appName">Aplicativo</label>
                    <input type="text" id="appName" class="form-control input-rounded" v-model="app.name" disabled>
                </form-control>

                <form-control :error="errors.title">
                    <label class="form-label ml-2" for="appTitle">Nome ou título do site *</label>
                    <input type="text" id="appTitle" class="form-control input-rounded" v-model="app.title">
                </form-control>

                <form-control :error="errors.description">
                    <label class="form-label ml-2" for="appDescription">Subtítulo ou descrição curta do site</label>
                    <input type="text" id="appDescription" class="form-control input-rounded" v-model="app.description">
                </form-control>

                <form-control v-show="app.app === 144" :error="errors.license">
                    <label class="form-label ml-2" for="appLicense">Licença do WHMCS *</label>
                    <input type="text" id="appLicense" class="form-control input-rounded" v-model="app.license" spellcheck="false">
                </form-control>

                <form-control v-if="appHasAdmin" :error="errors.adminEmail">
                    <label class="form-label ml-2" for="appAdminEmail">E-mail do administrador</label>
                    <input type="email" id="appAdminEmail" class="form-control input-rounded" v-model="app.adminEmail" spellcheck="false">
                </form-control>

                <form-control v-if="appHasAdmin" :error="errors.adminUsername">
                    <label class="form-label ml-2" for="appAdminUsername">Login do administrador</label>
                    <input type="text" id="appAdminUsername" class="form-control input-rounded" v-model="app.adminUsername" spellcheck="false">
                </form-control>

                <form-control v-if="appHasAdmin" :error="errors.adminPassword">
                    <label class="form-label ml-2" for="appAdminPassword">Senha do administrador</label>
                    <password-generator id="appAdminPassword" rounded v-model="app.adminPassword" autocomplete="new-password" />
                </form-control>

                <div class="mb-3">
                    <div class="form-group mb-0 d-flex justify-content-between align-items-center" v-bind:class="{ 'is-invalid': errors.subdomain }">
                        <div class="flex-grow-1 pl-2">
                            <label class="form-label" for="appSubdomainSwitch">Instalar em um subdomínio</label>
                            <p class="form-text text-muted mb-0">
                                Ao usar um subdomínio você pode instalar o seu aplicativo em uma extensão do seu
                                domínio como http://blog.meudominio.com/.
                            </p>
                        </div>
                        <div class="pl-3">
                            <span class="switch switch-sm">
                                <input type="checkbox" class="switch" id="appSubdomainSwitch" v-model="app.hasSubdomain">
                                <label for="appSubdomainSwitch" class="mb-0"></label>
                            </span>
                        </div>
                    </div>

                    <b-collapse id="collapseAppSubdomain" v-model="app.hasSubdomain">
                        <form-control class="mt-2 mb-0" v-bind:class="{ 'is-invalid': errors.subdomain }">
                            <label class="sr-only" for="appDir">Subdomínio</label>
                            <div class="input-group input-group-rounded">
                                <input type="text" id="appSubdomain" class="form-control" v-bind:class="{ 'is-invalid': errors.subdomain }" v-model="app.subdomain" spellcheck="false">
                                <div class="input-group-append">
                                    <span class="input-group-text px-4">.{{ resource }}</span>
                                </div>
                            </div>
                        </form-control>
                    </b-collapse>

                    <div v-if="errors.subdomain" class="invalid-feedback d-block pl-2">
                        {{ Object.prototype.toString.call(errors.subdomain) === '[object Array]' ? errors.subdomain[0] : errors.subdomain }}
                    </div>
                </div>

                <div class="mb-3">
                    <div class="form-group mb-0 d-flex justify-content-between align-items-center" v-bind:class="{ 'is-invalid': errors.dir }">
                        <div class="flex-grow-1 pl-2">
                            <label class="form-label" for="appDirSwitch">Instalar em um subdiretório</label>
                            <p class="form-text text-muted mb-0">
                                O subdiretório é relativo ao seu domínio. Por exemplo, para instalar o aplicativo em
                                http://meudominio.com/novo/, insira o subdiretório "novo".
                            </p>
                        </div>
                        <div class="pl-3">
                            <span class="switch switch-sm">
                                <input type="checkbox" class="switch" id="appDirSwitch" v-model="app.hasDir">
                                <label for="appDirSwitch" class="mb-0"></label>
                            </span>
                        </div>
                    </div>

                    <b-collapse id="collapseAppDir" v-model="app.hasDir">
                        <form-control class="mt-2 mb-0" v-bind:class="{ 'is-invalid': errors.dir }">
                            <label class="sr-only" for="appDir">Subdiretório</label>
                            <div class="input-group input-group-rounded" v-input-group-focus>
                                <div class="input-group-prepend">
                                    <span class="input-group-text px-4">http://{{ resource }}/</span>
                                </div>
                                <input type="text" id="appDir" class="form-control" v-bind:class="{ 'is-invalid': errors.dir }" v-model="app.dir" spellcheck="false">
                            </div>
                        </form-control>
                    </b-collapse>

                    <div v-if="errors.dir" class="invalid-feedback d-block pl-2">
                        {{ Object.prototype.toString.call(errors.dir) === '[object Array]' ? errors.dir[0] : errors.dir }}
                    </div>
                </div>

                <div class="form-buttons">
                    <div ref="installAlert" class="alert alert-warning" style="display:none">
                        <i class="far fa-clock mr-1"></i>
                        A instalação do aplicativo pode demorar alguns minutos...
                    </div>

                    <button-submit ref="submit" class="btn-rounded">Instalar</button-submit>
                    <button type="button" ref="cancel" class="btn btn-secondary btn-rounded" @click="cancelForm">Cancelar</button>
                </div>
            </form>
        </card-default>

        <card-default v-if="created" class="card-hosting-apps" title="Aplicativo instalado" icon="fas fa-th">
            <p v-if="!appInfo || !appInfo.url" class="mb-4">
                O aplicativo foi instalado com sucesso!
            </p>
            <p v-else class="mb-4">
                O aplicativo foi instalado com sucesso! Segue abaixo as informações de acesso.
                <strong>Copie e armazene as informações em um local seguro</strong>.
            </p>

            <ul class="list-unstyled mb-0">
                <li v-if="appInfo.url">
                    <strong class="mr-1">URL:</strong> {{ appInfo.url }}
                    <a :href="appInfo.url" target="_blank"><i aria-hidden="true" class="fas fa-external-link-alt ml-1"></i></a>
                </li>
                <li v-if="appInfo.urlAlt">
                    <strong class="mr-1">URL alternativa:</strong> {{ appInfo.urlAlt }}
                    <a :href="appInfo.urlAlt" target="_blank"><i aria-hidden="true" class="fas fa-external-link-alt ml-1"></i></a>
                </li>

                <template v-if="appInfo.adminUrl">
                    <li class="mt-3 mb-2">
                        <strong>Administrador</strong>
                    </li>
                    <li v-show="appInfo.adminUrl">
                        <strong class="mr-1">URL:</strong> {{ appInfo.adminUrl }}
                        <a :href="appInfo.adminUrl" target="_blank"><i aria-hidden="true" class="fas fa-external-link-alt ml-1"></i></a>
                    </li>
                    <li class="copy-text-wrapper">
                        <strong class="mr-1">Login:</strong> <text-copy :text="appInfo.adminUsername" />
                    </li>
                    <li class="copy-text-wrapper">
                        <strong class="mr-1">Senha:</strong> <text-copy :text="appInfo.adminPassword" />
                    </li>
                    <li class="copy-text-wrapper">
                        <strong class="mr-1">E-mail:</strong> <text-copy :text="appInfo.adminEmail" />
                    </li>
                </template>

                <template v-if="appInfo.dbName">
                    <li class="mt-3 mb-2">
                        <strong>Banco de Dados</strong>
                    </li>
                    <li class="copy-text-wrapper">
                        <strong class="mr-1">Nome:</strong> <text-copy :text="appInfo.dbName" />
                    </li>
                    <li class="copy-text-wrapper">
                        <strong class="mr-1">Usuário:</strong> <text-copy :text="appInfo.dbUser" />
                    </li>
                    <li class="copy-text-wrapper">
                        <strong class="mr-1">Senha:</strong> <text-copy :text="appInfo.dbPassword" />
                    </li>
                    <li class="copy-text-wrapper" v-show="appInfo.dbHost">
                        <strong class="mr-1">Host:</strong> <text-copy :text="appInfo.dbHost" />
                    </li>
                </template>
            </ul>

            <div class="form-buttons">
                <a v-if="appInfo.signOnUrl" class="btn btn-success btn-rounded mr-1" :href="appInfo.signOnUrl" target="_blank">Acessar painel</a>
                <button type="button" class="btn btn-primary btn-rounded" @click="cancelForm">Nova instalação</button>
                <router-link class="btn btn-secondary btn-rounded ml-1" :to="'/hosting/' + resource + '/apps'" exact>Listar aplicativos instalados</router-link>
            </div>
        </card-default>
    </div>
</template>

<script>
    import CardDefault       from '../../components/CardDefault'
    import FormControl       from '../../components/FormControl'
    import PasswordGenerator from '../../components/PasswordGenerator'
    import LoadingMessage    from '../../components/LoadingMessage'
    import TextCopy          from '../../components/TextCopy'
    import GlobalAlert       from '../../services/GlobalAlertService'

    export default {
        props: ['resource'],
        data() {
            return {
                app: {},
                errors: {},

                apps: [
                    { id: 26,  title: "Wordpress",   category: "CMS",         logo: "wordpress.png" },
                    { id: 413, title: "Joomla",      category: "CMS",         logo: "joomla.png" },
                    { id: 543, title: "Drupal",      category: "CMS",         logo: "drupal.png" },
                    { id: 545, title: "Magento",     category: "E-commerce",  logo: "magento.png" },
                    { id: 72,  title: "Prestashop",  category: "E-commerce",  logo: "prestashop.png" },
                    { id: 499, title: "Opencart",    category: "E-commerce",  logo: "opencart.png" },
                    { id: 144, title: "WHMCS",       category: "E-commerce",  logo: "whmcs.png" },
                    { id: 514, title: "Mautic",      category: "Marketing",   logo: "mautic.png" },
                    { id: 421, title: "MediaWiki",   category: "Wiki",        logo: "mediawiki.png" },
                    { id: 2,   title: "phpBB",       category: "Fórum",       logo: "phpbb.png" },
                    { id: 542, title: "Moodle",      category: "Educacional", logo: "moodle.png" },
                    { id: 419, title: "Laravel",     category: "Framework",   logo: "laravel.png" },
                    { id: 123, title: "CodeIgniter", category: "Framework",   logo: "codeigniter.png" },
                ],
                availableApps: null,
                isLoadingApps: false,

                creating: false,
                created: false,
                appInfo: {},
            }
        },
        computed: {
            appHasAdmin: function() {
                return this.app && this.app.app && this.app.app !== 419 && this.app.app !== 123;
            }
        },
        components: {
            CardDefault,
            FormControl,
            PasswordGenerator,
            LoadingMessage,
            TextCopy,
        },
        mounted()
        {
            this.fetchApps();
        },
        methods: {
            fetchApps()
            {
                this.isLoadingApps = true;

                axios.get(`/api/hosting/${this.resource}/apps/list`)
                    .then ((response) => {
                        let apps = response.data.data;
                        this.availableApps = {};

                        for (let i = 0; i < apps.length; i++) {
                            this.availableApps[apps[i].id] = apps[i];
                        }
                    })
                    .catch((error) => HandleErrors.formError(error, this))
                    .then (() => this.isLoadingApps = false);
            },
            showInstallForm(index)
            {
                this.app = {
                    app: this.apps[index].id,
                    name: this.apps[index].title,
                    adminEmail: "",
                    adminUsername: "admin",
                };

                this.creating = true;
            },
            cancelForm()
            {
                this.creating = false;
                this.created = false;
                this.app = {};
                this.appInfo = {};
                this.errors = {};
            },
            save()
            {
                let vm = this;
                this.errors = {};

                let alertTimeout = setTimeout(() => { $(this.$refs.installAlert).show(); }, 5000);

                this.$refs.submit.$el.focus();
                this.$refs.submit.setLoading();
                this.$refs.cancel.disabled = true;

                axios.post(`/api/hosting/${this.resource}/apps`, this.app)
                    .then ((response) => {
                        this.appInfo = response.data.data;
                        this.creating = false;
                        this.created = true;

                        GlobalAlert.success("Aplicativo instalado com sucesso!");
                    })
                    .catch((response) => {
                        HandleErrors.formError(response, vm);
                        vm.$refs.cancel.disabled = false;
                        vm.$refs.submit.setLoading(false);
                    })
                    .then(() => {
                        clearTimeout(alertTimeout);
                        $(this.$refs.installAlert).hide();
                    });
            }
        },
        watch: {
            'app.hasSubdomain': function (val) {
                if (val) {
                    this.app.hasDir = false;
                    this.app.dir = "";
                }
                else {
                    if (typeof this.errors.subdomain !== "undefined") {
                        this.errors.subdomain = null;
                    }
                }
            },
            'app.hasDir': function (val) {
                if (val) {
                    this.app.hasSubdomain = false;
                    this.app.subdomain = "";
                }
                else {
                    if (typeof this.errors.dir !== "undefined") {
                        this.errors.dir = null;
                    }
                }
            }
        }
    }
</script>
