<template>
    <div class="card card-default">

        <div class="card-header" v-if="$slots.header">
            <slot name="header"></slot>
        </div>

        <div class="card-body" v-bind:class="bodyClass">
            <div v-if="title || icon || $slots.buttons"
                 class="card-title-wrapper"
                 v-bind:class="{ 'd-flex justify-content-md-between align-items-md-center': $slots.buttons }" >
                <div>
                    <h3 class="card-title-main" v-if="(title || icon)">
                        <i v-if="icon" v-bind:class="icon" aria-hidden="true"></i>
                        <span v-if="title">{{ title }}</span>
                    </h3>
                </div>
                <div class="card-actions">
                    <slot name="buttons"></slot>
                </div>
            </div>
            <slot></slot>
        </div>

        <div class="card-footer" v-if="$slots.footer">
            <slot name="footer"></slot>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: null
            },
            icon: {
                type: String,
                default: null
            },
            bodyClass: {
                type: String,
                default: ""
            },
        },
    }
</script>