<template>
    <div class="modal fade" role="dialog" aria-hidden="true" v-modal-animated>
        <div class="modal-dialog modal-dialog-centered">
            <div tabindex="-1" role="document" class="modal-content">
                <header v-if="title" class="modal-header bg-light border-bottom-0">
                    <h5 class="modal-title">{{ title }}</h5>
                </header>

                <div class="modal-body">
                    <slot></slot>
                </div>

                <footer class="modal-footer" v-bind:class="{ 'justify-content-start': !danger }">
                    <button v-if="!danger" type="button" ref="btnConfirm" class="btn btn-primary btn-rounded" @click="$emit('confirm')">{{ confirmText }}</button>
                    <button type="button" ref="btnCancel" class="btn btn-secondary btn-rounded" data-dismiss="modal">Cancelar</button>
                    <button v-if="danger" type="button" ref="btnConfirmDanger" class="btn btn-danger btn-rounded" @click="$emit('confirm')">{{ confirmText }}</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: ""
            },
            danger: {
                type: Boolean,
                default: false
            },
            confirmText: {
                type: String,
                default: "Confirmar"
            },
        },
    }
</script>