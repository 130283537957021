
import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'

import AppMessage           from './pages/app/AppMessage'

import DomainAlias          from './pages/domain/DomainAlias'
import DomainDns            from './pages/domain/DomainDns'
import DomainSubdomain      from './pages/domain/DomainSubdomain'

import HostingApps          from './pages/hosting/HostingApps'
import HostingAppsInstall   from './pages/hosting/HostingAppsInstall'
import HostingBackup        from './pages/hosting/HostingBackup'
import HostingCron          from './pages/hosting/HostingCron'
import HostingDatabase      from './pages/hosting/HostingDatabase'
import HostingDomain        from './pages/hosting/HostingDomain'
import HostingEmail         from './pages/hosting/HostingEmail'
import HostingMailingLists  from './pages/hosting/HostingMailingLists'
import HostingSpam          from './pages/hosting/HostingSpam'
import HostingFiles         from './pages/hosting/HostingFiles'
import HostingFtp           from './pages/hosting/HostingFtp'
import HostingLogsError     from './pages/hosting/HostingLogsError'
import HostingPanel         from './pages/hosting/HostingPanel'
import HostingPhp           from './pages/hosting/HostingPhp'
import HostingSsh           from './pages/hosting/HostingSsh'
import HostingSsl           from './pages/hosting/HostingSsl'
import HostingSummary       from './pages/hosting/HostingSummary'
import HostingCharts        from './pages/hosting/HostingCharts'

Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    routes: [
        {
            path: '/',
            name: 'home',
            beforeEnter: (to, from, next) =>
            {
                Loading.show();

                // Carregar domínio associado ao token de acesso
                // ========================================
                axios.get("/api/hosting")
                    .then ((response) => {
                        next("/hosting/" + response.data.data.domain);
                    })
                    .catch((error) => {
                        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                            next("/" + error.response.status);
                        }
                        else if (error.response && error.response.status === 404) {
                            next({ name: 'notFound', params: [to.path] });
                        }
                        else {
                            HandleErrors.formError(error);
                        }
                    })
                    .then (() => Loading.hide());
            }
        },
        {
            path: '/login',
            meta: {
                guest: true,
            },
            beforeEnter: (to, from, next) =>
            {
                if (to.query.token && to.query.expiration)
                {
                    let accessToken = "";

                    try { accessToken = Vue.cookie.get("access_token"); }
                    catch(e) {}

                    if (accessToken) {
                        Vue.cookie.delete("access_token");
                    }

                    window.Store = {};
                    sessionStorage.clear();

                    let expiration = to.query.expiration || 0;

                    Vue.cookie.set("access_token", to.query.token, {
                        expires: new Date(expiration * 1000)
                    });
                }

                delete to.query.token;
                delete to.query.expiration;

                next({ path: "/", replace: true, query: to.query });
            }
        },
        {
            path: '/logout',
            component: AppMessage,
            meta: {
                title: 'Sessão encerrada',
                code: '<i class="fas fa-power-off"></i>',
                message: 'Sessão encerrada',
            },
            beforeEnter: (to, from, next) =>
            {
                let accessToken = "";

                try { accessToken = Vue.cookie.get('access_token'); }
                catch(e) {}

                if (accessToken) {
                    Vue.cookie.delete('access_token');
                }

                window.Store = {};
                sessionStorage.clear();

                next();
            }
        },
        {
            path: '/hosting/:resource([a-zA-Z0-9-_\\.]+\\.[a-zA-Z0-9][a-zA-Z0-9-_]*[a-zA-Z0-9][\\/]?)',
            component: HostingPanel,
            props: true,
            children: [
                {
                    path: '/',
                    component: HostingSummary,
                    props: true,
                    meta: {
                        title: 'Dados da Hospedagem',
                    }
                },
                {
                    path: 'charts',
                    component: HostingCharts,
                    props: true,
                    meta: {
                        title: 'Gráficos de Consumo',
                    }
                },
                {
                    path: 'ftp',
                    component: HostingFtp,
                    props: true,
                    meta: {
                        title: 'Acesso FTP',
                    }
                },
                {
                    path: 'files',
                    component: HostingFiles,
                    props: true,
                    meta: {
                        title: 'Gerenciador de Arquivos',
                    }
                },
                {
                    path: 'backup',
                    component: HostingBackup,
                    props: true,
                    meta: {
                        title: 'Restauração de Backup',
                    }
                },
                {
                    path: 'database',
                    component: HostingDatabase,
                    props: true,
                    meta: {
                        title: 'Bancos de Dados',
                    }
                },
                // {
                //     path: 'domain',
                //     component: HostingDomain,
                //     props: true,
                //     meta: {
                //         title: 'Domínio',
                //     }
                // },
                {
                    path: 'dns',
                    component: DomainDns,
                    props: true,
                    meta: {
                        title: 'Editor de DNS',
                    }
                },
                {
                    path: 'subdomain',
                    component: DomainSubdomain,
                    props: true,
                    meta: {
                        title: 'Subdomínios',
                    }
                },
                {
                    path: 'alias',
                    component: DomainAlias,
                    props: true,
                    meta: {
                        title: 'Domínios Adicionais',
                    }
                },
                {
                    path: 'email',
                    component: HostingEmail,
                    props: true,
                    meta: {
                        title: 'Contas de E-mail',
                    }
                },
                {
                    path: 'email/lists',
                    component: HostingMailingLists,
                    props: true,
                    meta: {
                        title: 'Listas de E-mails',
                    }
                },
                {
                    path: 'spam',
                    component: HostingSpam,
                    props: true,
                    meta: {
                        title: 'Antispam',
                    }
                },
                {
                    path: 'php',
                    component: HostingPhp,
                    props: true,
                    meta: {
                        title: 'Configurações do PHP',
                    }
                },
                {
                    path: 'ssh',
                    component: HostingSsh,
                    props: true,
                    meta: {
                        title: 'SSH',
                    }
                },
                {
                    path: 'ssl',
                    component: HostingSsl,
                    props: true,
                    meta: {
                        title: 'Certificado SSL',
                    }
                },
                {
                    path: 'cron',
                    component: HostingCron,
                    props: true,
                    meta: {
                        title: 'Tarefas Cron',
                    }
                },
                {
                    path: 'apps',
                    component: HostingApps,
                    props: true,
                    meta: {
                        title: 'Aplicativos',
                    }
                },
                {
                    path: 'apps/install',
                    component: HostingAppsInstall,
                    props: true,
                    meta: {
                        title: 'Instalar Aplicativo',
                    }
                },
                {
                    path: 'logs/error',
                    component: HostingLogsError,
                    props: true,
                    meta: {
                        title: 'Logs de Erros',
                    }
                },
            ],
        },
        {
            path: '/401',
            component: AppMessage,
            meta: {
                title: '401 | Não autorizado',
                code: 401,
                message: 'Não autorizado',
                guest: true,
            }
        },
        {
            path: '/403',
            component: AppMessage,
            meta: {
                title: '403 | Acesso negado',
                code: 403,
                message: 'Acesso negado',
                guest: true,
            }
        },
        {
            path: '*',
            name: 'notFound',
            component: AppMessage,
            meta: {
                title: '404 | Página não encontrada',
                code: 404,
                message: 'Página não encontrada',
                guest: true,
            }
        }
    ],
})