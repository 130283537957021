<template>
    <div>
        <card-default :title="$route.meta.title" icon="fas fa-chart-area" class="card-hosting-charts">
            <div class="btn-toolbar mb-3" role="toolbar">
                <div class="form-group mb-0">
                    <label for="chartsPeriod" class="sr-only">Período</label>
                    <select id="chartsPeriod" class="form-control input-rounded custom-select pl-3" v-model="period" @change="fetch">
                        <option value="1">1 hora</option>
                        <option value="6">6 horas</option>
                        <option value="D">24 horas</option>
                        <option value="W">7 dias</option>
                        <option value="M">30 dias</option>
                    </select>
                </div>
            </div>

            <div class="card card-chart">
                <h5 class="card-header">CPU / Processamento</h5>
                <div class="card-body">
                    <div v-if="isLoading" class="text-center">
                        <loading-message></loading-message>
                    </div>
                    <div v-show="!isLoading" class="chart-container">
                        <canvas ref="chartCpu"></canvas>
                    </div>
                </div>
            </div>

            <div class="card card-chart mb-0">
                <h5 class="card-header">Memória RAM</h5>
                <div class="card-body">
                    <div v-if="isLoading" class="text-center">
                        <loading-message></loading-message>
                    </div>
                    <div v-show="!isLoading" class="chart-container">
                        <canvas ref="chartRam"></canvas>
                    </div>
                </div>
            </div>
        </card-default>
    </div>
</template>

<script>
    import CardDefault          from '../../components/CardDefault'
    import LoadingMessage       from '../../components/LoadingMessage'
    import Chart                from 'chart.js'
    import * as ChartAnnotation from 'chartjs-plugin-annotation'

    export default {
        props: ['resource'],
        data() {
            return {
                period: '1',
                dataCpu: [],
                dataRam: [],
                chartCpu: null,
                chartRam: null,

                isChartsInitiated: false,
                isLoading: false,

                fetchCancelToken: null,
            }
        },
        components: {
            CardDefault,
            LoadingMessage,
        },
        mounted()
        {
            this.fetch();
        },
        methods:
        {
            /**
             * Carregar dados.
             */
            fetch()
            {
                this.isLoading = true;
                this.dataCpu = [];
                this.dataRam = [];

                // Cancelar verificação anterior
                if (this.fetchCancelToken) {
                    this.fetchCancelToken();
                }

                const CancelToken = axios.CancelToken;
                let vm = this;

                let promise = axios({
                    method: 'get',
                    url: `/api/hosting/${this.resource}/statistics`,
                    params: { period: this.period },
                    cancelToken: new CancelToken(function executor(c) {
                        vm.fetchCancelToken = c;
                    })
                });

                promise
                    .then ((response) => {
                        for (let i in response.data.data) {
                            this.dataCpu.push({ t: i, y: response.data.data[i][0] });
                            this.dataRam.push({ t: i, y: response.data.data[i][1] });
                        }

                        this.updateCharts();
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        if (!axios.isCancel(error)) {
                            HandleErrors.formError(error, this);
                            this.isLoading = false;
                        }
                    });
            },

            /**
             * Inicializar gráficos.
             */
            initCharts()
            {
                let vm = this;
                let bodyStyle = getComputedStyle(document.body);

                Chart.defaults.global.defaultFontFamily = bodyStyle.fontFamily;
                Chart.defaults.global.defaultFontStyle  = "600";
                Chart.defaults.global.defaultFontColor  = bodyStyle.color;

                Chart.plugins.register(ChartAnnotation);

                let ctxChartCpu = this.$refs.chartCpu.getContext('2d');
                let ctxChartRam = this.$refs.chartRam.getContext('2d');

                let chartConfig = {
                    type: 'line',
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                            display: false
                        },
                        title: {
                            display: false
                        },
                        animation: {
                            duration: 0,
                        },
                        tooltips: {
                            mode: 'index',
                            intersect: false,
                            displayColors: false,
                            xPadding: 12,
                            yPadding: 12,
                            cornerRadius: 4,
                            callbacks: {
                                title: function(tooltipItem, data) {
                                    let title = data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index].t || '';
                                    let titleDate = vm.$moment.unix(parseInt(title));
                                    return titleDate.format("DD/MM/YYYY - HH:mm");
                                },
                                label: function(tooltipItem, data) {
                                    let label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y || '0';
                                    label = label.toString().replace('.', ',') + '%';
                                    return label;
                                }
                            }
                        },
                        hover: {
                            mode: 'nearest',
                            intersect: true,
                            // animationDuration: 0,
                        },
                        responsiveAnimationDuration: 0,
                    },
                };

                // Gráfico CPU
                // ==============================
                this.chartCpu = new Chart(ctxChartCpu, $.extend(true, {}, chartConfig, {
                    data: {
                        datasets: [{
                            data: this.dataCpu,
                            // borderWidth: [0,0,0],
                            // backgroundColor: ['#e1e1e1','#54bfeb','#54bfeb'],
                            borderColor: '#23f694',
                            borderWidth: 1,
                            lineTension: 0,
                            pointRadius: 0,
                            fill: false,
                        }],
                        // labels: [
                        //     'CPU usage',
                        // ]
                    },
                    options: {
                        scales: {
                            xAxes: [{
                                type: 'time',
                                time: {
                                    parser: (data) => this.$moment.unix(data),
                                    unit: 'hour',
                                    displayFormats: {
                                        hour: 'HH[h]',
                                    }
                                },
                            }],
                            yAxes: [{
                                ticks: {
                                    min: 0,
                                    max: 100,
                                    suggestedMax: 110,
                                    stepSize: 20,
                                    maxTicksLimit: 5,
                                    callback: function(value) {
                                        return value ? value + '%' : '';
                                    }
                                },
                                gridLines: {
                                    borderDash: [2, 2],
                                }
                            }]
                        },
                        annotation: {
                            annotations: [
                                {
                                    type: 'box',
                                    drawTime: 'beforeDatasetsDraw',
                                    id: 'box-danger-area-cpu',
                                    xScaleID: 'x-axis-0',
                                    yScaleID: 'y-axis-0',
                                    yMin: 80,
                                    yMax: 100,
                                    borderWidth: 0,
                                    backgroundColor: 'rgba(255,0,0,0.075)',
                                },
                            ]
                        }
                    },

                }));

                // Gráfico RAM
                // ==============================
                this.chartRam = new Chart(ctxChartRam, $.extend(true, {}, chartConfig, {
                    data: {
                        datasets: [{
                            data: this.dataRam,
                            borderColor: '#54bfeb',
                            borderWidth: 1,
                            fill: false,
                            lineTension: 0,
                            pointRadius: 0,
                        }],
                    },
                    options: {
                        scales: {
                            xAxes: [{
                                type: 'time',
                                time: {
                                    parser: (data) => this.$moment.unix(data),
                                    unit: 'hour',
                                    displayFormats: {
                                        hour: 'HH[h]',
                                    }
                                },
                            }],
                            yAxes: [{
                                ticks: {
                                    min: 0,
                                    max: 100,
                                    suggestedMax: 110,
                                    stepSize: 20,
                                    maxTicksLimit: 5,
                                    callback: function(value) {
                                        return value ? value + '%' : '';
                                    }
                                },
                                gridLines: {
                                    borderDash: [2, 2],
                                }
                            }]
                        },
                        annotation: {
                            annotations: [
                                    {
                                    type: 'box',
                                    drawTime: 'beforeDatasetsDraw',
                                    id: 'box-danger-area-ram',
                                    xScaleID: 'x-axis-0',
                                    yScaleID: 'y-axis-0',
                                    yMin: 80,
                                    yMax: 100,
                                    borderWidth: 0,
                                    backgroundColor: 'rgba(255,0,0,0.075)',
                                },
                            ]
                        },
                    }
                }));

                this.isChartsInitiated = true;
            },

            /**
             * Atualizar dados dos gráficos.
             */
            updateCharts()
            {
                if (!this.isChartsInitiated) {
                    return this.initCharts();
                }

                this.chartCpu.data.datasets[0].data = this.dataCpu;
                this.chartRam.data.datasets[0].data = this.dataRam;

                const scaleDefaults = {
                    type: 'time',
                    time: {
                        parser: (data) => this.$moment.unix(data),
                        displayFormats: {
                            hour: 'HH[h]',
                        }
                    },
                };

                let scaleHour = $.extend(true, {}, scaleDefaults, {time: { unit: 'hour' }});
                let scaleDay  = $.extend(true, {}, scaleDefaults, {time: { unit: 'day' }});

                if (this.period === "D") {
                    this.chartCpu.options.scales.xAxes[0] = scaleHour;
                    this.chartRam.options.scales.xAxes[0] = scaleHour;
                }
                else {
                    this.chartCpu.options.scales.xAxes[0] = scaleDay;
                    this.chartRam.options.scales.xAxes[0] = scaleDay;
                }

                this.chartCpu.update();
                this.chartRam.update();
            },
        }
    }
</script>