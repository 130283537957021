<template>
    <div>
        <card-default v-show="!creating" :title="$route.meta.title" icon="fas fa-window-maximize">
            <template slot="buttons">
                <button type="button" class="btn btn-primary btn-rounded" @click="showCreateForm">Importar chave pública</button>
            </template>

            <div class="mb-4">
                <p>
                    O SSH (Secure SHell) é um protocolo que permite a você acessar e transferir arquivos em sua hospedagem
                    de forma segura.
                </p>
                <p class="mb-0">
                    Para conectar-se via SSH você precisa de uma <strong>chave pública</strong> que deve
                    ser gerada em seu computador e importada aqui. Para saber como gerar sua chave pública
                    <a href="https://git-scm.com/book/pt-br/v1/Git-no-Servidor-Gerando-Sua-Chave-P%C3%BAblica-SSH" target="_blank">clique aqui <i class="fas fa-external-link-alt" aria-hidden="true"></i></a>.
                </p>
            </div>

            <h5 class="card-subtitle mb-3">Chaves públicas autorizadas</h5>

            <div class="card card-table mb-0">
                <table class="table" v-bind:class="[keys.length ? 'table-hover' : '']">
                    <thead>
                        <tr>
                            <th scope="col">Título</th>
                            <th scope="col" class="col-datetime">Criada em</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="isLoading">
                            <td colspan="3">
                                <loading-message></loading-message>
                            </td>
                        </tr>

                        <tr v-else-if="!isLoading && !keys.length">
                            <td colspan="3">Nenhuma chave encontrada.</td>
                        </tr>

                        <template v-else v-for="(item, index) in keys">
                            <tr>
                                <td>{{ item.title }}</td>
                                <td class="col-datetime">
                                    {{ item.createdAt | moment("DD/MM/YYYY HH:mm") }}
                                </td>
                                <td class="col-100 col-nowrap text-right py-0 align-middle">
                                    <button type="button" class="btn btn-sm btn-link" title="Visualizar chave" @click="viewKey(index)">
                                        <i class="fas fa-key" aria-hidden="true"></i>
                                        <span class="sr-only">Visualizar chave</span>
                                    </button>

                                    <button type="button" class="btn btn-sm btn-link text-danger" title="Excluir" @click="deleteKey(index)">
                                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                                        <span class="sr-only">Excluir</span>
                                    </button>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </card-default>

        <card-default v-if="connection" v-show="!creating">
            <h5 class="card-subtitle">Conexão SSH</h5>

            <p class="mb-4">
                Use o comando abaixo para iniciar uma conexão SSH para sua hospedagem. Utilize a senha informada ao criar
                a sua chave pública.
            </p>

            <div class="card border-0 bg-dark text-white">
                <div class="card-body py-3">
                    <samp><text-copy :text="connection" /></samp>
                </div>
            </div>

            <button type="button" class="btn btn-danger btn-rounded mt-3" @click="disableSsh">Desabilitar SSH</button>
        </card-default>

        <card-default v-if="creating" title="Adicionar uma chave SSH">
            <p class="mb-4">
                Importe uma chave pública para dar permissão de acesso à sua máquina.
            </p>

            <form accept-charset="UTF-8" @submit.prevent="save">
                <form-control :error="errors.key">
                    <label class="form-label pl-2" for="key">Chave</label>
                    <p class="form-text text-muted pl-2 mt-0">
                        Cole sua chave SSH pública, que geralmente se encontra no arquivo <strong>~/.ssh/id_rsa.pub</strong>
                        e começa com "ssh-rsa". Não use sua chave privada.
                    </p>
                    <textarea id="key" class="form-control input-rounded" rows="8" placeholder="ssh-rsa ..." v-model="key.key"></textarea>
                </form-control>

                <form-control :error="errors.title">
                    <label class="form-label pl-2" for="title">Título</label>
                    <p class="form-text text-muted pl-2 mt-0">Use o título para identificar sua chave.</p>
                    <input type="text" id="title" class="form-control input-rounded" v-model="key.title" placeholder="Exemplo: minha_chave">
                </form-control>

                <div class="form-buttons">
                    <button-submit ref="submit" class="btn-rounded">Salvar</button-submit>
                    <button type="button" ref="cancel" class="btn btn-secondary btn-rounded" @click="cancelForm">Cancelar</button>
                </div>
            </form>
        </card-default>

        <div class="modal fade" ref="modalViewKey" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-body"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CardDefault    from '../../components/CardDefault'
    import FormControl    from '../../components/FormControl'
    import TextCopy       from '../../components/TextCopy'
    import LoadingMessage from '../../components/LoadingMessage'

    export default {
        props: ['resource'],
        data() {
            return {
                keys: [],
                key: {},
                connection: "",
                isLoading: false,
                creating: false,
                errors: {},
            }
        },
        components: {
            CardDefault,
            FormControl,
            TextCopy,
            LoadingMessage,
        },
        mounted() {
            this.fetch();
        },
        methods: {
            fetch()
            {
                this.isLoading = true;

                return axios.get(`/api/hosting/${this.resource}/ssh`)
                    .then ((response) => {
                        this.keys = response.data.data.keys;
                        this.connection = response.data.data.connection;
                    })
                    .catch((error) => HandleErrors.formError(error, this))
                    .then (() => this.isLoading = false);
            },
            save()
            {
                let vm = this;
                this.errors = {};

                this.$refs.submit.setLoading();
                this.$refs.cancel.disabled = true;

                axios.post(`/api/hosting/${this.resource}/ssh`, this.key)
                    .then ((response) => {
                        this.cancelForm();
                        this.fetch();
                    })
                    .catch((error) => HandleErrors.formError(error, vm))
                    .then (() => {
                        this.$refs.cancel.disabled = false;
                        this.$refs.submit.setLoading(false)
                    });
            },
            showCreateForm()
            {
                if (!this.keys.length) {
                    this.key = { title: 'default' };
                }

                this.creating = true;
            },
            cancelForm()
            {
                this.creating = false;
                this.key = {};
            },
            viewKey(index)
            {
                Loading.show();

                axios.get(`/api/hosting/${this.resource}/ssh/${this.keys[index].title}`)
                    .then ((response) => {
                        let data = response.data.data;

                        $(this.$refs.modalViewKey).find(".modal-body").html(`<h6>${this.keys[index].title}</h6><samp style="word-break:break-all;">${data.key}</samp>`);
                        $(this.$refs.modalViewKey).modal("show");
                    })
                    .catch((error) => HandleErrors.formError(error, this))
                    .then (() => Loading.hide());
            },
            deleteKey(index)
            {
                if (!confirm('Tem certeza que deseja excluir esta chave?'))
                    return;

                let title = this.keys[index].title;

                // Remover linha da tabela
                Loading.show();

                setTimeout(() => {
                    this.keys.splice(index, 1);
                    Loading.hide();
                }, 2000);

                // Excluir chave
                axios.delete(`/api/hosting/${this.resource}/ssh/${title}`)
                    .then((response) => {})
                    .catch((response) => {
                        alert(`Ocorreu um erro ao tentar excluir a chave.
                               Por favor, atualize sua página e tente novamente.`);
                    });
            },
            disableSsh()
            {
                if (!confirm(`Ao desabilitar o SSH todas as suas chaves serão excluídas. Tem certeza que deseja remover a conexão via SSH?`))
                    return;

                Loading.show();

                axios.post(`/api/hosting/${this.resource}/ssh/disable`)
                    .then ((response) => this.fetch())
                    .catch((error) => HandleErrors.formError(error, this))
                    .then (() => Loading.hide());
            }
        }
    }
</script>
