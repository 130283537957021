<template>
    <div class="form-group" ref="group" v-bind:class="{ 'is-invalid': error }">
        <slot></slot>
        <div class="invalid-feedback" v-show="error" v-html="errorMsg"></div>
    </div>
</template>

<script>
    export default {
        props: ["error"],
        computed: {
            errorMsg: function() {
                // Caso o erro venha em formato de array, usa-se a primeira posição
                return Object.prototype.toString.call(this.error) === '[object Array]' ? this.error[0] : this.error;
            }
        },
        watch: {
            error: function(value) {
                if (value) {
                    $(this.$refs.group).find(".input-group, .form-control, .btn-group").addClass("is-invalid");
                }
                else {
                    $(this.$refs.group).find(".input-group, .form-control, .btn-group").removeClass("is-invalid");
                }
            }
        }
    }   
</script>