<template>
    <div>
        <card-default v-show="!isEditing" :title="$route.meta.title" icon="far fa-envelope">
            <template slot="buttons">
                <a v-show="webmail" :href="webmail" target="_blank" class="btn btn-secondary btn-rounded">Acessar Webmail</a>
                <button type="button" class="btn btn-primary btn-rounded ml-1" @click="openAddEmailModal">Adicionar conta de e-mail</button>
            </template>

            <div class="btn-toolbar mb-3" role="toolbar">
                <div class="input-group input-group-rounded" v-input-group-focus>
                    <div class="input-group-prepend">
                        <div class="input-group-text bg-white pr-0">
                            <i class="fas fa-search" aria-hidden="true"></i>
                        </div>
                    </div>
                    <input type="search" class="form-control border-left-0" v-model="filterText" placeholder="Buscar e-mails..." spellcheck="false">
                </div>
            </div>

            <div class="card card-table mb-0">
                <table class="table" v-bind:class="[emails.length ? 'table-hover' : '']">
                    <thead>
                        <tr>
                            <th scope="col">E-mail</th>
                            <th scope="col" class="col-100 col-nowrap text-right">Uso de Disco</th>
                            <th scope="col" class="col-100 text-right">Limite</th>
                            <th scope="col" class="col-options"><span class="sr-only">Opções</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="isLoading">
                            <td colspan="4">
                                <loading-message>Carregando suas contas de e-mail&hellip;</loading-message>
                            </td>
                        </tr>
                        <tr v-else-if="!isLoading && !emails.length">
                            <td colspan="4">
                                Nenhuma conta de e-mail encontrada.
                                <a href="#" @click.prevent="openAddEmailModal">Cadastre seu primeiro e-mail</a>.
                            </td>
                        </tr>
                        <template v-else v-for="(item, index) in filteredItems">
                            <tr>
                                <td>
                                    <ul class="list-unstyled mb-0">
                                        <li>
                                            <span v-bind:class="{'font-italic': item.disabled}">{{ item.email }}</span>
                                            <span v-if="item.disabled" class="badge badge-warning ml-1">Desabilitado</span>
                                        </li>
                                        <!--<template v-if="item.alias && item.alias.length">
                                            <li v-for="alias in item.alias">{{ alias }}</li>
                                        </template>-->
                                    </ul>
                                </td>
                                <td class="col-100 col-nowrap text-right">
                                    {{ item.size | formatSize }}
                                </td>
                                <td class="col-100 col-nowrap text-right">
                                    <span v-if="item.quota">{{ item.quota | formatSize }}</span>
                                    <span v-else>Ilimitado</span>
                                </td>
                                <td class="col-options">
                                    <div class="btn-group visible-on-hover" role="group">
                                        <button type="button" class="btn btn-sm btn-outline-primary" title="Alterar senha" @click="openChangePasswordModal(item)">
                                            <i class="fas fa-key" aria-hidden="true"></i>
                                            <span class="sr-only">Alterar senha</span>
                                        </button>

                                        <button type="button" class="btn btn-sm btn-outline-primary" title="Configurações" @click="showEditForm(item)">
                                            <i class="fas fa-cog" aria-hidden="true"></i>
                                            <span class="sr-only">Configurações</span>
                                        </button>

                                        <button type="button" class="btn btn-sm btn-outline-danger" title="Excluir" @click="remove(item)">
                                            <i class="far fa-trash-alt" aria-hidden="true"></i>
                                            <span class="sr-only">Excluir</span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </card-default>

        <card-default v-show="!isEditing">
            <h5 class="card-subtitle">Configurações de acesso aos e-mails</h5>

            <p class="mb-4">
                Você pode configurar o acesso às suas contas de e-mail em uma aplicação desktop ou para celular usando
                as configurações fornecidas abaixo.
            </p>

            <div v-if="isLoading">
                <loading-message></loading-message>
            </div>

            <div v-else class="row">
                <div class="col-md-6">
                    <div class="card card-table mb-0">
                        <table v-if="config && config.secure" class="table table-sm table-hover">
                            <thead>
                                <tr>
                                    <th colspan="2">SSL ativado (recomendado)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="copy-text-wrapper">
                                    <th class="col-50 col-nowrap border-top-0">Servidor:</th>
                                    <td class="border-top-0"><text-copy :text="config.secure.host" /></td>
                                </tr>
                                <tr class="copy-text-wrapper">
                                    <th class="col-50 col-nowrap">Porta IMAP:</th>
                                    <td><text-copy :text="config.secure.imap" /></td>
                                </tr>
                                <tr class="copy-text-wrapper">
                                    <th class="col-50 col-nowrap">Porta POP3:</th>
                                    <td><text-copy :text="config.secure.pop3" /></td>
                                </tr>
                                <tr class="copy-text-wrapper">
                                    <th class="col-50 col-nowrap">Porta SMTP:</th>
                                    <td><text-copy :text="config.secure.smtp" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="card card-table mb-0">
                        <table v-if="config && config.insecure" class="table table-sm table-hover">
                            <thead>
                                <tr>
                                    <th colspan="2">SSL desativado</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="copy-text-wrapper">
                                    <th class="col-50 col-nowrap border-top-0">Servidor:</th>
                                    <td class="border-top-0"><text-copy :text="config.insecure.host" /></td>
                                </tr>
                                <tr class="copy-text-wrapper">
                                    <th class="col-50 col-nowrap">Porta IMAP:</th>
                                    <td><text-copy :text="config.insecure.imap" /></td>
                                </tr>
                                <tr class="copy-text-wrapper">
                                    <th class="col-50 col-nowrap">Porta POP3:</th>
                                    <td><text-copy :text="config.insecure.pop3" /></td>
                                </tr>
                                <tr class="copy-text-wrapper">
                                    <th class="col-50 col-nowrap">Porta SMTP:</th>
                                    <td><text-copy :text="config.insecure.smtp" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </card-default>

        <card-default v-if="isEditing" title="Configurações da conta de e-mail" icon="far fa-envelope">
            <template slot="buttons">
                <button type="button" class="btn btn-secondary btn-rounded" @click="cancelEditForm">Voltar</button>
            </template>

            <div class="row">
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="form-label ml-2">E-mail</label>
                        <input type="text" class="form-control input-rounded" :value="email.email" readonly>

                        <div class="mt-3">
                            <button type="button" class="btn btn-primary btn-rounded" @click="openChangePasswordModal(email)" :disabled="isLoading">
                                Alterar senha
                            </button>
                            <button type="button" class="btn btn-secondary btn-rounded ml-1" v-if="!email.disabled" @click="disable(email)" :disabled="isLoading">
                                Desabilitar
                            </button>
                            <button type="button" class="btn btn-success btn-rounded ml-1" v-if="email.disabled"  @click="enable(email)" :disabled="isLoading">
                                Habilitar
                            </button>
                            <button type="button" class="btn btn-danger btn-rounded ml-1" @click="remove(email)" :disabled="isLoading">
                                Excluir
                            </button>
                        </div>
                    </div>
                </div>

                <div class="col-xl-6 mt-3 mt-xl-0">
                    <div class="form-group">
                        <label class="form-label ml-2">Limite de espaço</label>
                        <input type="text" class="form-control input-rounded" :value="getFormattedQuota(email.quota)" readonly>

                        <div class="mt-3">
                            <button type="button" class="btn btn-secondary btn-rounded" @click="openChangeQuotaModal(email)" :disabled="isLoading">
                                Alterar limite
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </card-default>

        <card-default v-if="isEditing">
            <h5 class="card-subtitle">Endereços alternativos (alias)</h5>
            <p class="mb-4">
                Um endereço alternativo é uma conta de e-mail com o seu domínio, mas que <strong>apenas recebe mensagens</strong>
                e encaminha para a caixa de e-mail atual. Por exemplo, você pode ter um e-mail <strong>contato@meudominio.com.br</strong>
                e criar um endereço alternativo <strong>atendimento@meudominio.com.br</strong> apenas para receber mensagens.
                O endereço alternativo não consegue enviar mensagens nem pode ser acessado por um cliente de e-mail.
            </p>

            <div v-if="email.alias && email.alias.length">
                <div v-for="item in email.alias" class="input-group input-group-rounded d-md-inline-flex w-auto mb-3 mr-md-2">
                    <input type="text" class="form-control" v-bind:value="item" readonly disabled>
                    <div class="input-group-append">
                        <button class="btn btn-danger btn-rounded-right" type="button" title="Excluir" @click="removeAlias(item)" :disabled="isLoading">
                            <i class="fas fa-times pr-1" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>

            <button type="button" class="btn btn-primary btn-rounded" @click="openAddAliasModal(email)">Adicionar</button>
        </card-default>

        <card-default v-if="isEditing">
            <h5 class="card-subtitle">Redirecionamentos</h5>
            <p class="mb-4">
                Envie uma cópia de todo e-mail recebido nesta caixa de e-mail para outro endereço existente. Por exemplo,
                você pode encaminhar todas as mensagens que chegarem neste endereço para <strong>seuemail@gmail.com</strong>
                ou <strong>seuemail@hotmail.com</strong>.
            </p>

            <div v-if="email.forwards && email.forwards.length">
                <div v-for="item in email.forwards" class="input-group input-group-rounded d-md-inline-flex w-auto mb-3 mr-md-2">
                    <input type="text" class="form-control" v-bind:value="item" readonly disabled>
                    <div class="input-group-append">
                        <button class="btn btn-danger btn-rounded-right" type="button" title="Excluir" @click="removeForward(item)" :disabled="isLoading">
                            <i class="fas fa-times pr-1" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>

            <button type="button" class="btn btn-primary btn-rounded" @click="openAddForwardModal(email)" :disabled="isLoading">Adicionar</button>
        </card-default>

        <card-default v-if="isEditing">
            <h5 class="card-subtitle">Resposta automática</h5>
            <p class="mb-4">
                Uma resposta automática é uma mensagem que sempre será enviada para o contato que enviou um e-mail para você.
                Ela pode ser usada quando você vai sair de férias ou vai estar indisponível em um determinado momento.
            </p>

            <loading-message v-show="isLoadingAutoresponder">Carregando resposta automática&hellip;</loading-message>

            <div v-show="!isLoadingAutoresponder">
                <div v-if="!email.autoresponder">
                    <button type="button" class="btn btn-success btn-rounded" @click="openAutoresponderModal(email)" :disabled="isLoading">Habilitar</button>
                </div>
                <div v-else>
                    <table class="table">
                        <tr v-if="email.autoresponder.startDate">
                            <th class="col-100">Início:</th>
                            <td>
                                {{ email.autoresponder.startDate | moment("DD/MM/YYYY") }}
                                <span v-if="email.autoresponder.startTime">- {{ email.autoresponder.startTime }}</span>
                            </td>
                        </tr>
                        <tr v-if="email.autoresponder.stopDate">
                            <th class="col-100">Término:</th>
                            <td>
                                {{ email.autoresponder.stopDate | moment("DD/MM/YYYY") }}
                                <span v-if="email.autoresponder.stopTime">- {{ email.autoresponder.stopTime }}</span>
                            </td>
                        </tr>
                        <tr>
                            <th class="col-100">Assunto:</th>
                            <td>{{ email.autoresponder.subject }}</td>
                        </tr>
                        <tr>
                            <th class="col-100 border-bottom">Mensagem:</th>
                            <td class="border-bottom" v-html="email.autoresponder.bodyHtml"></td>
                        </tr>
                    </table>

                    <button type="button" class="btn btn-primary btn-rounded" @click="openAutoresponderModal(email)" :disabled="isLoading">Editar</button>
                    <button type="button" class="btn btn-danger btn-rounded ml-1" @click="removeAutoresponder(email)" :disabled="isLoading">Remover</button>
                </div>
            </div>
        </card-default>

        <card-default v-if="isEditing">
            <div class="d-flex justify-content-between align-items-start">
                <div class="flex-grow-1 pt-1">
                    <h6 class="card-subtitle mt-0">Habilitar antispam nesta conta de e-mail</h6>
                    <p class="mb-0">
                        Alterar o status do filtro antispam para esta conta de e-mail específica. Se o filtro antispam
                        estiver desabilitado globalmente não será possível habilitá-lo somente nesta conta.
                    </p>
                </div>

                <div class="d-flex align-items-center">
                    <div v-show="isLoadingAntispam" class="pl-3">
                        <i class="d-block fas fa-circle-notch fa-spin"></i>
                    </div>

                    <div class="pl-3">
                        <span class="switch switch-sm">
                            <input
                                type="checkbox"
                                class="switch"
                                id="antispamStatus"
                                v-model="antispam.status"
                                @change="updateAntispam"
                                :disabled="isLoadingAntispam || !antispam || !antispam.global">
                            <label for="antispamStatus" class="mb-0"></label>
                        </span>
                    </div>
                </div>
            </div>

            <div v-show="antispam.global === false" class="alert alert-warning mt-3">
                O filtro antispam está desativado globalmente. Para alterar o status acesse a aba <strong>Antispam</strong>
                <router-link :to="`/hosting/${resource}/spam`">
                    <i class="fas fa-external-link-alt ml-1" aria-hidden="true"></i>
                </router-link>.
            </div>
        </card-default>

        <div class="modal fade" ref="modalAddEmail" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Adicionar conta de e-mail</h5>
                    </header>
                    <form accept-charset="UTF-8" @submit.prevent="save">
                        <div class="modal-body">
                            <form-control :error="errors.username">
                                <label class="form-label ml-2" for="username">E-mail</label>
                                <div class="input-group input-group-rounded" v-input-group-focus>
                                    <input type="text" id="username" class="form-control" maxlength="40" v-model="email.username">
                                    <div class="input-group-append">
                                        <span class="input-group-text">{{ '@' + resource }}</span>
                                    </div>
                                </div>
                            </form-control>

                            <form-control :error="errors.password">
                                <label class="form-label ml-2" for="password">Senha</label>
                                <password-generator id="password" rounded v-model="email.password" autocomplete="new-password" />
                            </form-control>

                            <form-control class="mb-0" :error="errors.quota">
                                <label class="form-label ml-2" for="emailQuota">Limite de espaço (em megabytes)</label>
                                <p class="form-text text-muted ml-2">
                                    Deixe em branco ou com valor 0 para uma conta sem limite de espaço.
                                </p>
                                <div class="input-group input-group-rounded" v-input-group-focus>
                                    <input type="number" id="emailQuota" class="form-control input-rounded" min="0" step="50" v-model="email.quota">
                                    <div class="input-group-append">
                                        <span class="input-group-text">MB</span>
                                    </div>
                                </div>
                            </form-control>
                        </div>

                        <footer class="modal-footer justify-content-start">
                            <button-submit ref="submit" class="btn-rounded">Criar</button-submit>
                            <button type="button" ref="cancel" class="btn btn-secondary btn-rounded ml-1" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" ref="modalNewPassword" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Alterar senha</h5>
                    </header>
                    <form accept-charset="UTF-8" autocomplete="off" @submit.prevent="changePassword">
                        <div class="modal-body">
                            <div class="form-group">
                                <label class="form-label ml-2" for="newPasswordEmail">E-mail</label>
                                <input type="text" id="newPasswordEmail" class="form-control input-rounded" :value="password.email" readonly>
                            </div>

                            <form-control class="mb-0" :error="errors.password">
                                <label class="form-label ml-2" for="newPassword">Nova senha</label>
                                <password-generator id="newPassword" rounded v-model="password.password" autocomplete="new-password" />
                            </form-control>
                        </div>

                        <footer class="modal-footer justify-content-start">
                            <button-submit ref="submitNewPassword" class="btn-rounded">Salvar</button-submit>
                            <button type="button" ref="cancelNewPassword" class="btn btn-secondary btn-rounded ml-1" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" ref="modalChangeQuota" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Alterar limite de espaço</h5>
                    </header>
                    <form accept-charset="UTF-8" autocomplete="off" @submit.prevent="changeQuota">
                        <div class="modal-body">
                            <div class="form-group">
                                <label class="form-label ml-2" for="changeQuotaEmail">E-mail</label>
                                <input type="text" id="changeQuotaEmail" class="form-control input-rounded" :value="quota.email" readonly>
                            </div>

                            <form-control class="mb-0" :error="errors.quota">
                                <label class="form-label ml-2" for="newQuota">Limite de espaço (em megabytes)</label>
                                <p class="form-text text-muted ml-2">
                                    Deixe em branco ou com valor 0 para uma conta sem limite de espaço.
                                </p>
                                <div class="input-group input-group-rounded" v-input-group-focus>
                                    <input type="number" id="newQuota" class="form-control input-rounded" min="0" step="50" v-model="quota.quota">
                                    <div class="input-group-append">
                                        <span class="input-group-text">MB</span>
                                    </div>
                                </div>
                            </form-control>
                        </div>

                        <footer class="modal-footer justify-content-start">
                            <button-submit ref="submitChangeQuota" class="btn-rounded">Salvar</button-submit>
                            <button type="button" ref="cancelChangeQuota" class="btn btn-secondary btn-rounded ml-1" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" ref="modalAddAlias" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Adicionar endereço alternativo</h5>
                    </header>
                    <form accept-charset="UTF-8" @submit.prevent="addAlias">
                        <div class="modal-body">
                            <div class="form-group">
                                <label class="form-label ml-2" for="aliasForward">E-mail principal</label>
                                <input type="text" id="aliasForward" class="form-control input-rounded" :value="alias.email" readonly>
                            </div>

                            <form-control class="mb-0" :error="errors.username">
                                <label class="form-label ml-2" for="aliasUsername">Endereço alternativo</label>
                                <div class="input-group input-group-rounded" v-input-group-focus>
                                    <input type="text" id="aliasUsername" class="form-control" maxlength="40" v-model="alias.username">
                                    <div class="input-group-append">
                                        <span class="input-group-text">{{ '@' + resource }}</span>
                                    </div>
                                </div>
                            </form-control>
                        </div>

                        <footer class="modal-footer justify-content-start">
                            <button-submit ref="submitAddAlias" class="btn-rounded">Salvar</button-submit>
                            <button type="button" ref="cancelAddAlias" class="btn btn-secondary btn-rounded ml-1" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" ref="modalAddForward" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Adicionar redirecionamento</h5>
                    </header>
                    <form accept-charset="UTF-8" @submit.prevent="addForward">
                        <div class="modal-body">
                            <div class="form-group">
                                <label class="form-label ml-2">E-mail principal</label>
                                <input type="email" class="form-control input-rounded" :value="forward.email" readonly>
                            </div>

                            <form-control class="mb-0" :error="errors.forward">
                                <label class="form-label ml-2" for="forwardEmail">E-mail de redirecionamento</label>
                                <input type="email" class="form-control input-rounded" v-model="forward.forward">
                            </form-control>
                        </div>

                        <footer class="modal-footer justify-content-start">
                            <button-submit ref="submitAddForward" class="btn-rounded">Salvar</button-submit>
                            <button type="button" ref="cancelAddForward" class="btn btn-secondary btn-rounded ml-1" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" ref="modalAutoresponder" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Configurar resposta automática</h5>
                    </header>
                    <form accept-charset="UTF-8" @submit.prevent="saveAutoresponder">
                        <div class="modal-body">
                            <form-control :error="errors.subject">
                                <label class="form-label ml-2" for="autoresponderSubject">Assunto do e-mail</label>
                                <input type="text" id="autoresponderSubject" class="form-control input-rounded" v-model="autoresponder.subject">
                            </form-control>

                            <form-control :error="errors.body">
                                <label class="form-label ml-2" for="autoresponderBody">Corpo da mensagem</label>
                                <textarea id="autoresponderBody" class="form-control input-rounded" rows="6" v-model="autoresponder.body"></textarea>
                            </form-control>

                            <div>
                                <b-form-checkbox class="ml-2" v-model="autoresponder.hasStart">
                                    Definir data de início
                                </b-form-checkbox>

                                <div class="row" v-show="autoresponder.hasStart">
                                    <div class="col-8">
                                        <form-control class="mb-0 mt-2" :error="errors.startDate">
                                            <label class="form-label ml-2" for="autoresponderStartDate">Data</label>
                                            <input type="date" id="autoresponderStartDate" class="form-control input-rounded" v-model="autoresponder.startDate" placeholder="Data de início" aria-label="Data de início">
                                        </form-control>
                                    </div>
                                    <div class="col-4">
                                        <form-control class="mb-0 mt-2" :error="errors.startTime">
                                            <label class="form-label ml-2" for="autoresponderStartTime">Horário</label>
                                            <input type="time" id="autoresponderStartTime" class="form-control input-rounded" v-model="autoresponder.startTime" placeholder="Horário de início" aria-label="Horário de início">
                                        </form-control>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-3">
                                <b-form-checkbox class="ml-2" v-model="autoresponder.hasStop">
                                    Definir data de término
                                </b-form-checkbox>

                                <div class="row" v-show="autoresponder.hasStop">
                                    <div class="col-8">
                                        <form-control class="mb-0 mt-2" :error="errors.stopDate">
                                            <label class="form-label ml-2" for="autoresponderStopDate">Data</label>
                                            <input type="date" id="autoresponderStopDate" class="form-control input-rounded" v-model="autoresponder.stopDate" placeholder="Data de término" aria-label="Data de término">
                                        </form-control>
                                    </div>
                                    <div class="col-4">
                                        <form-control class="mb-0 mt-2" :error="errors.stopTime">
                                            <label class="form-label ml-2" for="autoresponderStopTime">Horário</label>
                                            <input type="time" id="autoresponderStopTime" class="form-control input-rounded" v-model="autoresponder.stopTime" placeholder="Horário de término" aria-label="Horário de término">
                                        </form-control>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <footer class="modal-footer justify-content-start">
                            <button-submit ref="submitAutoresponder" class="btn-rounded">Salvar</button-submit>
                            <button type="button" ref="cancelAutoresponder" class="btn btn-secondary btn-rounded ml-1" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CardDefault       from '../../components/CardDefault'
    import FormControl       from '../../components/FormControl'
    import PasswordGenerator from '../../components/PasswordGenerator'
    import LoadingMessage    from '../../components/LoadingMessage'
    import TextCopy          from '../../components/TextCopy'
    import GlobalAlert       from '../../services/GlobalAlertService'

    export default {
        props: ['resource'],
        data() {
            return {
                emails: [],
                email: {},
                webmail: "",
                config: null,
                password: {},
                alias: {},
                forward: {},
                autoresponder: {},
                quota: {},
                errors: {},

                isLoading: false,
                isLoadingAutoresponder: false,
                isEditing: false,

                filterText: "",
                filteredItems: [],

                // Antispam
                isLoadingAntispam: false,
                antispam: {},
            }
        },
        components: {
            CardDefault,
            FormControl,
            PasswordGenerator,
            LoadingMessage,
            TextCopy,
        },
        mounted()
        {
            $([this.$refs.modalAddEmail, this.$refs.modalAddAlias, this.$refs.modalAddForward])
                .on("shown.bs.modal", function() {
                    console.log('shown');
                    $(this).find("input:not([readonly])").get(0).focus();
                });

            this.fetch();
        },
        methods: {
            fetch()
            {
                this.isLoading = true;

                return axios.get(`/api/hosting/${this.resource}/email`)
                    .then ((response) => {
                        this.emails = response.data.data.emails;
                        this.filteredItems = this.emails.slice(0);

                        this.webmail = response.data.data.webmail;
                        this.config = response.data.data.config;
                    })
                    .catch((error) => HandleErrors.formError(error, this))
                    .then (() => this.isLoading = false);
            },

            openAddEmailModal()
            {
                this.email = {};
                this.errors = {};

                $(this.$refs.modalAddEmail).modal("show");
            },

            openChangePasswordModal(item)
            {
                this.password = { 'email': item.email };
                this.errors = {};

                $(this.$refs.modalNewPassword).modal("show");
            },

            openAddAliasModal(item)
            {
                this.alias = { 'email': item.email };
                this.errors = {};

                $(this.$refs.modalAddAlias).modal("show");
            },

            /**
             * Adicionar conta de e-mail.
             */
            save()
            {
                let vm = this;
                this.errors = {};

                this.$refs.submit.$el.focus();
                this.$refs.submit.setLoading();
                this.$refs.cancel.disabled = true;

                let postData = {
                    url: `/api/hosting/${this.resource}/email`,
                    method: "post",
                    data: this.email,
                };

                // if (this.editing) {
                //     postData.url += "/" + this.email.id;
                //     postData.method = "put";
                // }

                axios(postData)
                    .then ((response) => {
                        this.fetch();

                        $(this.$refs.modalAddEmail).modal("hide");

                        setTimeout(() => {
                            this.$refs.cancel.disabled = false;
                            this.$refs.submit.setLoading(false);
                        }, 300);
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, vm);
                        this.$refs.cancel.disabled = false;
                        this.$refs.submit.setLoading(false);
                    });
            },

            /**
             * Alterar senha de uma conta de e-mail.
             */
            changePassword()
            {
                let vm = this;
                this.errors = {};

                this.$refs.submitNewPassword.$el.focus();
                this.$refs.submitNewPassword.setLoading();
                this.$refs.cancelNewPassword.disabled = true;

                axios.post(`/api/hosting/${this.resource}/email/password`, this.password)
                    .then ((response) => {
                        $(this.$refs.modalNewPassword).modal("hide");

                        GlobalAlert.success("Senha alterada com sucesso!");

                        setTimeout(() => {
                            this.$refs.cancelNewPassword.disabled = false;
                            this.$refs.submitNewPassword.setLoading(false);
                        }, 300);
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, vm);
                        this.$refs.cancelNewPassword.disabled = false;
                        this.$refs.submitNewPassword.setLoading(false);
                    });
            },

            /**
             * Desabilitar uma conta de e-mail.
             */
            disable(email)
            {
                this.isLoading = true;
                Loading.show();

                let data   = { 'email': email.email };
                const idx  = this.emails.findIndex(el => el.email === email.email);
                const idxF = this.filteredItems.findIndex(el => el.email === email.email);

                axios.post(`/api/hosting/${this.resource}/email/disable`, data)
                    .then ((response) => {
                        email.disabled = true;
                        this.emails[idx].disabled = true;
                        if (idxF !== -1) this.filteredItems[idxF].disabled = true;
                    })
                    .catch((error) => HandleErrors.formError(error, this))
                    .then (() => {
                        Loading.hide();
                        this.isLoading = false;
                    });
            },

            /**
             * Habilitar uma conta de e-mail.
             */
            enable(email)
            {
                this.isLoading = true;
                Loading.show();

                let data   = { 'email': email.email };
                const idx  = this.emails.findIndex(el => el.email === email.email);
                const idxF = this.filteredItems.findIndex(el => el.email === email.email);

                axios.post(`/api/hosting/${this.resource}/email/enable`, data)
                    .then ((response) => {
                        email.disabled = false;
                        this.emails[idx].disabled = false;
                        if (idxF !== -1) this.filteredItems[idxF].disabled = false;
                    })
                    .catch((error) => HandleErrors.formError(error, this))
                    .then (() => {
                        Loading.hide();
                        this.isLoading = false;
                    });
            },

            /**
             * Excluir conta de e-mail.
             */
            remove(item)
            {
                if (!confirm('Aviso: Todas as suas mensagens serão excluídas permanentemente. Deseja excluir este e-mail assim mesmo?')) return;

                const email = item.email;
                const idx   = this.emails.findIndex(el => el.email === email);
                const idxF  = this.filteredItems.findIndex(el => el.email === email);

                this.isLoading = true;
                Loading.show();

                setTimeout(() => {
                    if (idxF !== -1) this.filteredItems.splice(idxF, 1);
                    this.emails.splice(idx, 1);
                    if (this.isEditing) this.cancelEditForm();
                    Loading.hide();
                    this.isLoading = false;
                }, 2000);

                axios.delete(`/api/hosting/${this.resource}/email/${email}`)
                    .then ((response) => {})
                    .catch((error) => {
                        alert("Ocorreu um erro ao tentar excluir o e-mail. Por favor, atualize sua página e tente novamente.");
                    });
            },

            /**
             * Exibir tela de edição de um endereço de e-mail.
             */
            showEditForm(item)
            {
                this.email = item;
                this.isEditing = true;
                this.isLoadingAutoresponder = true;

                axios.get(`/api/hosting/${this.resource}/email/autoresponder`, {params: { email: this.email.email }})
                    .then ((response) => {
                        let data = response.data.data;

                        if (data) {
                            data.hasStart = (data.startDate !== "");
                            data.hasStop  = (data.stopDate !== "");
                        }

                        this.$set(this.email, 'autoresponder', data);
                    })
                    .catch((error) => HandleErrors.formError(error, this))
                    .then (() => this.isLoadingAutoresponder = false);

                // Antispam
                this.fetchAntispam();
            },

            /**
             * Fechar tela de edição de um endereço de e-mail (cancelar edição).
             */
            cancelEditForm()
            {
                this.isEditing = false;
                this.email = {};
            },

            /**
             * Adicionar alias a um e-mail.
             */
            addAlias()
            {
                let vm = this;
                this.errors = {};

                this.$refs.submitAddAlias.$el.focus();
                this.$refs.submitAddAlias.setLoading();
                this.$refs.cancelAddAlias.disabled = true;

                axios.post(`/api/hosting/${this.resource}/email/alias`, this.alias)
                    .then ((response) => {
                        this.fetch();

                        if (this.isEditing) {
                            this.email.alias.push(response.data.data.alias);
                        }

                        $(this.$refs.modalAddAlias).modal("hide");

                        setTimeout(() => {
                            this.$refs.cancelAddAlias.disabled = false;
                            this.$refs.submitAddAlias.setLoading(false);
                        }, 300);
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, vm);
                        this.$refs.cancelAddAlias.disabled = false;
                        this.$refs.submitAddAlias.setLoading(false);
                    });
            },

            /**
             * Excluir alias de uma conta de e-mail.
             */
            removeAlias(alias)
            {
                if (!confirm('Tem certeza que deseja excluir este endereço alternativo?')) return;

                let data   = { 'email': this.email.email, 'alias': alias };
                const idx  = this.email.alias.findIndex(el => el === alias);

                this.isLoading = true;
                Loading.show();

                setTimeout(() => {
                    this.email.alias.splice(idx, 1);
                    this.isLoading = false;
                    Loading.hide();
                }, 1000);

                axios.post(`/api/hosting/${this.resource}/email/remove-alias`, data)
                    .then ((response) => {})
                    .catch((error) => alert(`Ocorreu um erro ao tentar excluir o endereço alternativo. Por favor, atualize sua página e tente novamente.`));
            },

            /**
             * Abrir modal de adicionar redirecionamento.
             */
            openAddForwardModal(item)
            {
                this.forward = { 'email': item.email };
                this.errors = {};

                jQuery(this.$refs.modalAddForward).modal("show");
            },

            /**
             * Adicionar redirecionamento a um e-mail.
             */
            addForward()
            {
                let vm = this;
                this.errors = {};

                this.$refs.submitAddForward.$el.focus();
                this.$refs.submitAddForward.setLoading();
                this.$refs.cancelAddForward.disabled = true;

                axios.post(`/api/hosting/${this.resource}/email/forward`, this.forward)
                    .then ((response) => {
                        this.fetch();

                        if (this.isEditing) {
                            this.email.forwards.push(response.data.data.forward);
                        }

                        jQuery(this.$refs.modalAddForward).modal("hide");

                        setTimeout(() => {
                            this.$refs.cancelAddForward.disabled = false;
                            this.$refs.submitAddForward.setLoading(false);
                        }, 300);
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, vm);
                        this.$refs.cancelAddForward.disabled = false;
                        this.$refs.submitAddForward.setLoading(false);
                    });
            },

            /**
             * Excluir redirecionamento de um e-mail.
             */
            removeForward(forward)
            {
                if (!confirm('Tem certeza que deseja excluir este redirecionamento?')) return;

                let data   = { 'email': this.email.email, 'forward': forward };
                const idx  = this.email.forwards.findIndex(el => el === forward);

                this.isLoading = true;
                Loading.show();

                setTimeout(() => {
                    this.email.forwards.splice(idx, 1);
                    this.isLoading = false;
                    Loading.hide();
                }, 1000);

                axios.post(`/api/hosting/${this.resource}/email/remove-forward`, data)
                    .then ((response) => {})
                    .catch((error) => alert(`Ocorreu um erro ao tentar excluir o redirecionamento. Por favor, atualize sua página e tente novamente.`));
            },

            /**
             * Abrir modal de editar resposta automática.
             */
            openAutoresponderModal(item)
            {
                if (item.autoresponder) {
                    this.autoresponder = item.autoresponder;
                    this.autoresponder.email = item.email;
                }
                else {
                    this.autoresponder = { 'email': item.email };
                }

                this.errors = {};

                jQuery(this.$refs.modalAutoresponder).modal("show");
            },

            saveAutoresponder()
            {
                this.$refs.submitAutoresponder.$el.focus();
                this.$refs.submitAutoresponder.setLoading();
                this.$refs.cancelAutoresponder.disabled = true;

                this.isLoading = true;

                axios.post(`/api/hosting/${this.resource}/email/autoresponder`, this.autoresponder)
                    .then ((response) => {
                        let data = response.data.data;

                        data.hasStart = (data.startDate && data.startDate !== "");
                        data.hasStop  = (data.stopDate && data.stopDate !== "");

                        jQuery(this.$refs.modalAutoresponder).modal("hide");

                        GlobalAlert.success("Resposta automática salva com sucesso!");

                        setTimeout(() => {
                            this.$set(this.email, 'autoresponder', data);
                            this.$refs.cancelAutoresponder.disabled = false;
                            this.$refs.submitAutoresponder.setLoading(false);
                            this.isLoading = false;
                        }, 300);
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, this);
                        this.$refs.cancelAutoresponder.disabled = false;
                        this.$refs.submitAutoresponder.setLoading(false);
                        this.isLoading = false;
                    });
            },

            removeAutoresponder(item)
            {
                if (!confirm('Tem certeza que deseja remover a resposta automática?')) return;

                this.isLoading = true;
                Loading.show();

                setTimeout(() => {
                    this.$set(item, 'autoresponder', null);
                    this.isLoading = false;
                    Loading.hide();
                }, 1000);

                axios.delete(`/api/hosting/${this.resource}/email/autoresponder/${item.email}`)
                    .then ((response) => {})
                    .catch((error) => alert(`Ocorreu um erro ao tentar remover a resposta automática. Por favor, atualize sua página e tente novamente.`));
            },

            /**
             * Obter texto formatado para o limite de espaço.
             */
            getFormattedQuota(quota)
            {
                if (!quota) return "Ilimitado";

                return this.$options.filters.formatSize(quota);
            },

            /**
             * Abrir modal para editar o limite de espaço.
             */
            openChangeQuotaModal(item)
            {
                let _quota = "";

                if (item.quota) {
                    _quota = parseInt(item.quota) / (1024 * 1024);
                }

                this.quota = { 'email': item.email, 'username': item.username, 'quota': _quota };
                this.errors = {};

                $(this.$refs.modalChangeQuota).modal("show");
            },

            /**
             * Alterar limite de espaço de uma conta de e-mail.
             */
            changeQuota()
            {
                let vm = this;
                this.errors = {};

                this.$refs.submitChangeQuota.$el.focus();
                this.$refs.submitChangeQuota.setLoading();
                this.$refs.cancelChangeQuota.disabled = true;

                axios.post(`/api/hosting/${this.resource}/email/quota`, this.quota)
                    .then ((response) => {
                        $(this.$refs.modalChangeQuota).modal("hide");

                        this.$set(this.email, 'quota', response.data.data.quota);
                        this.fetch();

                        GlobalAlert.success("Limite de espaço alterado com sucesso!");

                        setTimeout(() => {
                            this.$refs.cancelChangeQuota.disabled = false;
                            this.$refs.submitChangeQuota.setLoading(false);
                        }, 300);
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, vm);
                        this.$refs.cancelChangeQuota.disabled = false;
                        this.$refs.submitChangeQuota.setLoading(false);
                    });
            },

            /**
             * Obter status do antispam para a conta de e-mail.
             */
            fetchAntispam()
            {
                this.antispam = {};
                this.isLoadingAntispam = true;

                axios.get(`/api/hosting/${this.resource}/spam/` + encodeURIComponent(this.email.email))
                    .then ((response) => this.antispam = response.data.data)
                    .catch((error) => HandleErrors.ajaxErrorAlert(error))
                    .then (() => this.isLoadingAntispam = false);
            },

            /**
             * Atualizar o status do antispam para a conta de e-mail.
             */
            updateAntispam()
            {
                // Ignorar se estiver desativado globalmente
                if (this.antispam.global !== true)
                    return;

                const newValue = this.antispam.status;

                this.isLoadingAntispam = true;

                axios.post(`/api/hosting/${this.resource}/spam/` + encodeURIComponent(this.email.email), { status: newValue })
                    .then ((response) => {
                        this.antispam.status = response.data.data.status;
                        GlobalAlert.success("Status do antispam atualizado com sucesso.");
                    })
                    .catch((error) => {
                        this.antispam.status = !newValue;
                        HandleErrors.ajaxErrorAlert(error, 0);
                    })
                    .then (() => this.isLoadingAntispam = false);
            },
        },
        watch: {
            filterText: function(val)
            {
                if (val) {
                    let searchTerm = val.trim().toLowerCase();

                    this.filteredItems = this.emails.filter((element) => {
                        return element.email.toLowerCase().indexOf(searchTerm) !== -1 ||
                               element.alias.join().toLowerCase().indexOf(searchTerm) !== -1;
                    });
                }
                else {
                    this.filteredItems = this.emails.slice(0);
                }
            },
        }
    }
</script>
