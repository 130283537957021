// services/HandleErrors.js

import axios from 'axios'
import GlobalAlert from './GlobalAlertService'

export default {

    formError(error, vm, errorObj, errorMsg)
    {
        let handled = false;
        errorObj = typeof errorObj !== "undefined" && errorObj ? errorObj : "errors";
        errorMsg = typeof errorMsg !== "undefined" && errorMsg ? errorMsg : "errorMsg";

        if (axios.isCancel(error)) return;

		if (error && error.response)
		{
		    if (error.response.status === 401 || error.response.status === 403) {
		        handled = true;
            }
            else if (vm) {
                if (error.response.status === 422 || error.response.data.status === "fail") {
                    vm[errorObj] = error.response.data.data;
                    handled = true;
                }
                else if (error.response.data.message) {
                    if (typeof vm[errorMsg] !== "undefined") {
                        vm[errorMsg] = error.response.data.message;
                    }
                    else {
                        alert(error.response.data.message);
                    }

                    handled = true;
                }
            }
        }

        if (!handled) {
            alert("Ocorreu um erro ao tentar processar sua solicitação.");
        }
	},

    ajaxErrorAlert(error, timeout)
    {
        let errorMsg = "Ocorreu um erro ao tentar processar sua solicitação.";
        let _timeout = typeof timeout !== "undefined" ? timeout : 5000;

        if (axios.isCancel(error)) return;

        if (error && error.response) {
            if (error.response.status >= 400) {
                if (error.response.data.message) {
                    errorMsg = error.response.data.message;
                }
                else if (error.response.message) {
                    errorMsg = error.response.message;
                }

                GlobalAlert.add(errorMsg, "danger", _timeout);
            }
        }
    },

}