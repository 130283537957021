<template>
    <div>
        <card-default :title="$route.meta.title" icon="fas fa-server" class="card-hosting-summary">
            <template slot="buttons">
                <router-link class="btn btn-secondary btn-rounded" :to="resource + '/charts'">Gráficos de Consumo</router-link>
            </template>

            <div v-if="isLoading" class="text-center py-4">
                <loading-message></loading-message>
            </div>

            <table v-else class="table table-sm table-hover mb-0">
                <tbody>
                    <template v-if="hosting">
                        <tr>
                            <th class="col-50 col-nowrap border-top-0">Endereço principal:</th>
                            <td class="border-top-0">
                                {{ hosting.domain }}
                                <a :href="'http://' + hosting.domain" target="_blank">
                                    <i class="fas fa-external-link-alt ml-1" aria-hidden="true"></i>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <th class="col-50 col-nowrap">Endereço alternativo:</th>
                            <td>
                                <span v-if="hosting.domainAlt" v-html="hosting.domainAlt"></span>
                                <a v-if="hosting.domainAlt" :href="'http://' + hosting.domainAlt" target="_blank">
                                    <i class="fas fa-external-link-alt ml-1" aria-hidden="true"></i>
                                </a>
                                <button type="button" v-if="!hosting.domainAlt" ref="btnEnableDomainAlt" class="btn btn-link p-0" @click="enableDomainAlt">
                                    Ativar
                                </button>
                            </td>
                        </tr>
                        <!--<tr class="copy-text-wrapper">
                            <th class="col-50 col-nowrap">Servidor:</th>
                            <td>
                                <text-copy :text="hosting.server"/>
                            </td>
                        </tr>-->
                        <tr class="copy-text-wrapper">
                            <th class="col-50 col-nowrap">IP do servidor:</th>
                            <td>
                                <text-copy :text="hosting.serverIp"/>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </card-default>
    </div>
</template>

<script>
    import CardDefault    from '../../components/CardDefault'
    import TextCopy       from '../../components/TextCopy'
    import LoadingMessage from '../../components/LoadingMessage'
    import GlobalAlert    from '../../services/GlobalAlertService'
    import { getHosting, updateHosting } from '../../services/HostingService'

    export default {
        props: ['resource'],
        data() {
            return {
                domain: "",
                hosting: null,
                isLoading: false,
            }
        },
        components: {
            CardDefault,
            TextCopy,
            LoadingMessage,
        },
        beforeRouteEnter(to, from, next)
        {
            next(vm => {
                vm.domain = to.params.resource;
                vm.fetch();
            });
        },
        beforeRouteUpdate(to, from, next)
        {
            if (from.params.resource !== to.params.resource) {
                this.domain = to.params.resource;
                this.fetch();
            }

            next();
        },
        methods:
        {
            /**
             * Carregar dados da hospedagem
             */
            fetch()
            {
                this.isLoading = true;
                this.hosting = null;

                getHosting(this.domain)
                    .then ((hosting) => this.hosting = hosting)
                    .catch((error) => HandleErrors.ajaxErrorAlert(error))
                    .then (() => this.isLoading = false);
            },

            /**
             * Ativar o domínio aleatório.
             */
            enableDomainAlt()
            {
                this.$refs.btnEnableDomainAlt.disabled = true;
                Loading.show();

                axios.post(`/api/hosting/${this.domain}/enable-alternative`)
                    .then ((response) => {
                        if (response.data.message) {
                            GlobalAlert.success(response.data.message);
                        }
                        else {
                            GlobalAlert.success('O domínio alternativo foi ativado com sucesso!');
                        }

                        // Atualizar dados da hospedagem
                        this.isLoading = true;
                        this.hosting = null;

                        updateHosting(this.domain)
                            .then ((hosting) => this.hosting = hosting)
                            .catch((error) => HandleErrors.formError(error, this))
                            .then (() => this.isLoading = false);
                    })
                    .catch((error) => {
                        HandleErrors.ajaxErrorAlert(error);
                        this.$refs.btnEnableDomainAlt.disabled = false;
                    })
                    .then (() => {
                        Loading.hide();
                    });
            },
        }
    }
</script>
