<template>
    <nav class="navbar navbar-expand-md navbar-light">
        <a class="navbar-brand" href="#">
            <img v-if="logo" :src="logo" alt="">
        </a>
        <ul class="navbar-nav ml-auto">
            <li class="nav-item">
                <router-link class="nav-link" to="/logout">
                    <i class="fas fa-sign-out-alt fa-fw mr-1" aria-hidden="true"></i> Sair
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        data() {
            return {
                logo: "",
            }
        },
        mounted()
        {
            axios.get("/api/user/hosting-panel-config")
                .then ((response) => {
                    let data = response.data.data;

                    if (data.logo) {
                        this.$set(this, "logo", data.logo);
                    }
                });
        },
        methods:
        {

        }
    }
</script>