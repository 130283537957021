// services/GlobalAlertService.js

export default {

    add(message, type, timeout)
    {
        let $alertBoard = $("#alert-board");
        let alertType = typeof type !== "undefined" ? type : "info";

        $alertBoard.append(`
            <div class="alert alert-${alertType} alert-dismissible fade show animated flipInX" role="alert">
                ${message}
                <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>        
        `);

        let $alert = $alertBoard.find(".alert").last();

        $alert.alert();

        let alertTimeout = typeof timeout !== "undefined" ? timeout : 3000;

        if (alertTimeout) {
            setTimeout(() => { $alert.alert('close'); }, alertTimeout);
        }

        return $alert;
    },

    success(message)
    {
        return this.add(message, "success");
    }

}