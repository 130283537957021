<template xmlns="">
    <div>
        <card-default :title="$route.meta.title" icon="fas fa-file-alt" class="card-hosting-logs">

            <p class="mb-4">
                Exibe os registros mais recentes nos logs de erro da hospedagem. Utilize as informações disponíveis nos
                logs para encontrar e resolver problemas em sua aplicação.
                Se o registro no log possuir um ícone de lâmpada ( <i class="far fa-lightbulb"></i> ), você pode clicar
                ou passar o mouse sobre ele para ver uma dica de como resolver o problema informado.
            </p>

            <div class="btn-toolbar mb-3" role="toolbar">
                <button type="button" class="btn btn-rounded mr-2" v-bind:class="getLogGroupClasses('server')" @click="fetchLogs('server')">
                    Servidor Web
                </button>
                <button type="button" class="btn btn-rounded mr-2" v-bind:class="getLogGroupClasses('php')" @click="fetchLogs('php')">
                    PHP
                </button>
                <button type="button" v-if="logsInfo.isWordpress" class="btn btn-rounded mr-2" v-bind:class="getLogGroupClasses('wordpress')" @click="fetchLogs('wordpress')">
                    Wordpress
                </button>
            </div>

            <div v-show="showAlertLargeSize" class="alert alert-danger">
                O arquivo de log solicitado é muito grande. Para poder ter acesso a esse recurso o arquivo de log não
                pode ter mais de 1 MB.
            </div>

            <div v-show="!showAlertLargeSize" class="card card-table">
                <table class="table" v-bind:class="[logs.length ? 'table-hover' : '']">
                    <thead>
                        <tr>
                            <th scope="col" class="col-datetime">Data</th>
                            <th scope="col" class="col-50 col-nowrap">
                                Tipo
                                <button type="button" class="btn btn-link p-0" data-toggle="modal" data-target="#modalLogTypeHelp" title="Ajuda">
                                    <i class="fas fa-question-circle" aria-hidden="true"></i>
                                </button>
                            </th>
                            <th scope="col">Mensagem</th>
                            <th scope="col" class="col-options"><span class="sr-only">Opções</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="isLoading">
                            <td colspan="4">
                                <loading-message></loading-message>
                            </td>
                        </tr>

                        <tr v-else-if="!isLoading && !logs.length">
                            <td colspan="4">
                                Nenhum log de erro encontrado.
                            </td>
                        </tr>
                        <tr v-else v-for="(item, index) in logs">
                            <td class="col-datetime">{{ $moment(item.date).format('DD/MM/YYYY HH:mm:ss') }}</td>
                            <td class="col-50">
                                <span v-bind:class="getLogTypeClasses(item.type)">{{ item.typeText }}</span>
                            </td>
                            <td class="col-log-message">
                                <p class="text-collapse mb-0" v-bind:class="{'show':item.show}">
                                    <span v-if="item.hint" v-bind:id="'hint-${index}'" class="log-hint mr-1"><i class="far fa-lightbulb"></i></span>
                                    {{ item.message }}
                                </p>
                                <b-popover v-if="item.hint" :target="'hint-${index}'" placement="topright" triggers="hover focus">
                                    <p class="mb-0"><strong>Dica:</strong> <span v-html="item.hint"></span></p>
                                </b-popover>
                            </td>
                            <td class="col-options">
                                <div class="btn-group visible-on-hover" role="group">
                                    <button type="button" class="btn btn-sm btn-outline-primary" title="Expandir" @click="toggleLogMessage(item, index)">
                                        <i class="fas fa-plus" aria-hidden="true"></i>
                                        <span class="sr-only">Expandir</span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </card-default>

        <div class="modal fade" id="modalLogTypeHelp" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <h4>Status da indicação</h4>

                        <table class="table mb-0">
                            <tr>
                                <td class="col-100 text-center border-top-0">
                                    <span class="badge badge-info">Informação</span>
                                </td>
                                <td class="border-top-0">Mensagem informativa. Não compromete o funcionamento da aplicação.</td>
                            </tr>
                            <tr>
                                <td class="col-100 text-center">
                                    <span class="badge badge-warning">Notícia</span>
                                </td>
                                <td>Condição normal que pode ou não ser considerada um problema.</td>
                            </tr>
                            <tr>
                                <td class="col-100 text-center">
                                    <span class="badge badge-warning">Alerta</span>
                                </td>
                                <td>Condição de alerta que pode levar a um erro.</td>
                            </tr>
                            <tr>
                                <td class="col-100 text-center">
                                    <span class="badge badge-danger">Erro</span>
                                </td>
                                <td>Condição de erro impedindo o correto funcionamento da aplicação.</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CardDefault    from '../../components/CardDefault'
    import FormControl    from '../../components/FormControl'
    import LoadingMessage from '../../components/LoadingMessage'

    export default {
        props: ['resource'],
        data() {
            return {
                logGroup: "server",
                logsInfo: {},
                logs: [],
                isLoading: false,
                showAlertLargeSize: false,
                cancelFetchLogs: null,
            }
        },
        components: {
            CardDefault,
            FormControl,
            LoadingMessage,
        },
        mounted()
        {
            this.getLogsInfo();
            this.fetchLogs();
        },
        methods:
        {
            getLogGroupClasses(group)
            {
                return {
                    "btn-primary": group === this.logGroup,
                    "btn-outline-primary": group !== this.logGroup,
                }
            },

            getLogTypeClasses(type)
            {
                return {
                    "badge":        typeof type != "undefined" && type,
                    "badge-danger":  type === "error",
                    "badge-warning": type === "warning" || type === "notice",
                    "badge-info":    type === "info",
                }
            },

            /**
             * Obter informações sobre os logs de erro.
             */
            getLogsInfo()
            {
                axios.get(`/api/hosting/${this.resource}/logs/info`)
                    .then((response) => this.logsInfo = response.data.data)
            },

            /**
             * Carregar logs.
             */
            fetchLogs(group)
            {
                this.logGroup = group || "server";

                this.isLoading = true;
                this.logs = [];

                this.showAlertLargeSize = false;

                const CancelToken = axios.CancelToken;
                let vm = this;

                let _params = {
                    group: this.logGroup,
                };

                if (this.cancelFetchLogs) {
                    this.cancelFetchLogs();
                }

                let promise = axios({
                    method: 'get',
                    url: `/api/hosting/${this.resource}/logs`,
                    params: _params,
                    cancelToken: new CancelToken(function executor(c) {
                        vm.cancelFetchLogs = c;
                    })
                });

                promise
                    .then ((response) => {
                        const data = response.data.data;

                        if (data.logs) {
                            this.logs = data.logs;
                        }
                        else if (typeof data.isLargeFile !== "undefined" && data.isLargeFile) {
                            this.showAlertLargeSize = true;
                        }

                        this.isLoading = false;
                    })
                    .catch((error) => {
                        if (axios.isCancel(error)) return;
                        HandleErrors.formError(error, this);
                        this.isLoading = false;
                    });
            },

            toggleLogMessage(item, index)
            {
                let newValue = !(item.show || false);
                this.$set(this.logs[index], 'show', newValue);
            },
        }
    }
</script>
