<template>
    <div>
        <card-default :title="$route.meta.title" icon="fas fa-globe">
            <div v-if="isLoading" class="text-center py-4">
                <loading-message>
                    Carregando as informações do seu domínio&hellip;
                </loading-message>
            </div>

            <div v-if="!isLoading && domain">
                <div v-if="domain.available" class="alert alert-warning" role="alert">
                    O domínio <strong>{{ resource.toLowerCase() }}</strong> ainda não foi registrado.
                </div>

                <div v-if="domain.ns_current">
                    <div class="card card-table">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>DNS atual</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="n in Math.max(domain.ns_current.length)">
                                    <tr class="copy-text-wrapper">
                                        <td>
                                            <span v-if="domain.ns_current[n-1] != 'undefined'">
                                                {{ domain.ns_current[n-1] }}
                                            </span>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>

                    <p class="text-muted">
                        Atenção: Modificações de DNS podem demorar de alguns minutos a até 24 horas.
                    </p>
                </div>
            </div>
        </card-default>
    </div>
</template>

<script>
    import CardDefault    from '../../components/CardDefault'
    import TextCopy       from '../../components/TextCopy'
    import LoadingMessage from '../../components/LoadingMessage'
    import { getHosting } from '../../services/HostingService'

    export default {
        props: ['resource'],
        data() {
            return {
                hosting: null,
                domain: null,
                isLoading: false,
            }
        },
        computed: {
            isHostingActive: function() {
                return this.hosting && this.hosting.status === 'Active';
            },
        },
        components: {
            CardDefault,
            TextCopy,
            LoadingMessage,
        },
        async mounted()
        {
            this.hosting = await this.getHosting();
            this.fetchDomain();
        },
        methods: {
            getHosting()
            {
                return new Promise(resolve => {
                    getHosting(this.resource).then((hosting) => resolve(hosting))
                });
            },
            fetchDomain()
            {
                this.isLoading = true;

                axios.get(`/api/hosting/${this.resource}/domain`)
                    .then ((response) => this.domain = response.data.data)
                    .catch((error) => HandleErrors.formError(error, this))
                    .then (() => this.isLoading = false);
            }
        }
    }
</script>
