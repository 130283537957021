<template>
    <app-dashboard>
        <div class="container main-container">

            <div class="card card-rounded card-hosting-top border-0 p-2 mb-4" v-if="hosting.status !== 'Pending'">
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-7">
                            <div class="widget-performance">
                                <span class="hosting-server php"></span>

                                <div class="chart-wrapper chart-cpu">
                                    <div class="chart-container">
                                        <canvas ref="chartCpu"></canvas>
                                    </div>
                                </div>

                                <div class="chart-wrapper chart-ram">
                                    <div class="chart-container">
                                        <canvas ref="chartRam"></canvas>
                                    </div>
                                </div>

                                <b-dropdown variant="primary" toggle-class="custom-toggle btn-php-version">
                                    <template slot="button-content">
                                        <span v-if="!isHostingActive" class="px-3">&nbsp;</span>
                                        <i v-else-if="!php.version" class="fas fa-circle-notch fa-spin px-2"></i>
                                        <span v-else>PHP {{ php.versionText }}</span>
                                    </template>
                                    <b-dropdown-item v-for="item in php.versions" :key="item.value" :disabled="php.version === item.value" @click="changePhpVersion(item.value)">
                                        PHP {{ item.text }}
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>

                            <div class="hosting-summary">
                                <h5 class="text-nowrap text-truncate hosting-title mt-2 mb-1" :title="hosting.domain">
                                    {{ hosting.domain }}
                                </h5>

                                <div v-show="hosting && hosting.status" class="hosting-status">
                                    <i class="fas fa-circle mr-1" v-bind:class="hostingStatusClass"></i> {{ hosting.statusText }}
                                </div>

                                <div class="widget-usage mt-4">
                                    <div class="d-flex flex-nowrap">
                                        <div class="usage-info-wrapper" ref="usageCpu">
                                            <h6 class="mb-2">CPU</h6>
                                            <div class="usage-info usage-cpu">
                                                <i class="fas fa-circle" aria-hidden="true"></i> {{ cpuUsage }}<span class="percent-sign">%</span>
                                            </div>
                                        </div>
                                        <div class="usage-info-wrapper" ref="usageRam">
                                            <h6 class="mb-2">RAM</h6>
                                            <div class="usage-info usage-ram">
                                                <i class="fas fa-circle" aria-hidden="true"></i> {{ ramUsage }}<span class="percent-sign">%</span>
                                            </div>
                                        </div>
                                        <div id="usageDiskWrapper" class="usage-info-wrapper flex-grow-1 pr-3">
                                            <h6 class="mb-2 pl-1">Armazenamento <span v-show="diskUsage !== null">({{ diskUsage + '%' }})</span></h6>

                                            <b-progress ref="barUsageDisk" :max="100" height="16px">
                                                <b-progress-bar class="progress-emails" :value="emailsUsage"></b-progress-bar>
                                                <b-progress-bar class="progress-databases" :value="databasesUsage"></b-progress-bar>
                                                <b-progress-bar :value="diskUsage - (emailsUsage + databasesUsage)"></b-progress-bar>
                                            </b-progress>

                                            <b-tooltip :target="() => $refs.barUsageDisk" placement="bottom" container="usageDiskWrapper">
                                                <div class="text-left text-nowrap p-2">
                                                    <p class="usage-emails mb-0">
                                                        <i class="fas fa-circle" aria-hidden="true"></i> E-mails: {{ emailsUsage }}<span class="percent-sign">%</span>
                                                    </p>
                                                    <p class="usage-databases mb-0">
                                                        <i class="fas fa-circle" aria-hidden="true"></i> Bancos de dados: {{ databasesUsage }}<span class="percent-sign">%</span><br>
                                                    </p>
                                                    <p class="usage-disk mb-0">
                                                        <i class="fas fa-circle" aria-hidden="true"></i> Total: {{ diskUsage }}<span class="percent-sign">%</span>
                                                    </p>
                                                </div>
                                            </b-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="menu-wrapper">
                        <b-navbar type="light" class="navbar-expand p-0">
                            <b-navbar-nav>
                                <b-nav-item :to="getHostingUrl()" exact>
                                    Resumo
                                </b-nav-item>

                                <b-nav-item-dropdown
                                    text="Domínio"
                                    :extra-toggle-classes="getToggleClasses(['domain','dns','subdomain','alias'])"
                                >
                                    <!--<b-dropdown-item :to="getHostingUrl('domain')">Status</b-dropdown-item>-->
                                    <b-dropdown-item :to="getHostingUrl('dns')" v-if="isHostingActive">Editor de DNS</b-dropdown-item>
                                    <b-dropdown-item :to="getHostingUrl('subdomain')" v-if="isHostingActive">Subdomínios</b-dropdown-item>
                                    <b-dropdown-item :to="getHostingUrl('alias')" v-if="isHostingActive">Domínios adicionais</b-dropdown-item>
                                </b-nav-item-dropdown>

                                <b-nav-item-dropdown
                                    text="Arquivos"
                                    :extra-toggle-classes="getToggleClasses(['files','ftp','deploy','backup'])"
                                    v-if="isHostingActive"
                                >
                                    <b-dropdown-item :to="getHostingUrl('files')">Gerenciador</b-dropdown-item>
                                    <b-dropdown-item :to="getHostingUrl('ftp')">FTP</b-dropdown-item>
                                    <!--<b-dropdown-item :to="getHostingUrl('deploy')">Deploy</b-dropdown-item>-->
                                    <b-dropdown-item :to="getHostingUrl('backup')">Backup</b-dropdown-item>
                                </b-nav-item-dropdown>

                                <b-nav-item :to="getHostingUrl('database')" v-if="isHostingActive">
                                    Banco de Dados
                                </b-nav-item>

                                <b-nav-item-dropdown
                                    text="E-mail"
                                    :extra-toggle-classes="getToggleClasses(['email','email/lists','spam'])"
                                    v-if="isHostingActive"
                                >
                                    <b-dropdown-item :to="getHostingUrl('email')" exact>Contas de e-mail</b-dropdown-item>
                                    <b-dropdown-item :to="getHostingUrl('email/lists')">Listas de e-mails</b-dropdown-item>
                                    <b-dropdown-item :to="getHostingUrl('spam')">Antispam</b-dropdown-item>
                                </b-nav-item-dropdown>

                                <b-nav-item :to="getHostingUrl('apps')" v-if="isHostingActive" exact>
                                    Aplicativos
                                </b-nav-item>

                                <b-nav-item-dropdown
                                    text="Configurações"
                                    v-if="isHostingActive"
                                    :extra-toggle-classes="getToggleClasses(['php','ssh','ssl','cron','logs/error'])"
                                >
                                    <b-dropdown-item :to="getHostingUrl('php')">PHP</b-dropdown-item>
                                    <b-dropdown-item :to="getHostingUrl('ssh')">SSH</b-dropdown-item>
                                    <b-dropdown-item :to="getHostingUrl('ssl')">Certificado SSL</b-dropdown-item>
                                    <b-dropdown-item :to="getHostingUrl('cron')">Tarefas Cron</b-dropdown-item>
                                    <b-dropdown-item :to="getHostingUrl('logs/error')">Logs</b-dropdown-item>
                                </b-nav-item-dropdown>
                            </b-navbar-nav>
                        </b-navbar>
                    </div>
                </div>
            </div>

            <card-default v-if="hosting.status === 'Pending'">
                <div class="alert alert-info mb-0" role="alert">
                    <p class="mb-0">
                        <i class="fas fa-cog fa-spin mr-1" aria-hidden="true"></i>
                        Aguarde um pouco enquanto seu site está sendo criado...
                    </p>
                </div>
            </card-default>

            <div v-else>
                <router-view></router-view>
            </div>
        </div>
    </app-dashboard>
</template>

<script>
    import CardDefault    from '../../components/CardDefault'
    import FormControl    from '../../components/FormControl.vue'
    import Chart          from 'chart.js'
    import vueSlider      from 'vue-slider-component'
    import { getHosting } from '../../services/HostingService'

    export default {
        props: ['resource'],
        data() {
            return {
                domain: "",
                hosting: {},
                needsRecharge: false,
                timeoutCheckStatus: null,
                intervalGetUsage: null,
                errors: {},
                chartCpu: null,

                getUsageCancel: null,
                getUsageTimestamp: null,
                chartRam: null,
                cpuUsage: "-",
                ramUsage: "-",
                diskUsage: null,
                emailsUsage: 0,
                databasesUsage: 0,

                php: {},
            }
        },
        computed: {
            isHostingActive: function() {
                return this.hosting.status === 'Active';
            },
            hostingStatusClass: function() {
                return {
                    'text-success': this.hosting.status === 'Active',
                    'text-warning': this.hosting.status === 'Suspended' || this.hosting.status === 'Paused' || this.hosting.status === 'Restoring',
                    'text-danger':  this.hosting.status === 'Terminated',
                }
            }
        },
        components: {
            CardDefault,
            FormControl,
            vueSlider,
        },
        beforeRouteEnter(to, from, next)
        {
            Loading.show();

            getHosting(to.params.resource)
                .then ((hosting) => next(vm => {
                    vm.hosting = hosting;
                    vm.domain  = vm.hosting.domain;
                    vm.initPanel();
                }))
                .catch((error) => {
                    if (error.response && error.response.status === 402) {
                        next(vm => vm.needsRecharge = true);
                    }
                    else if (error.response && error.response.status === 404) {
                        next({ name: 'notFound', params: [to.path] });
                    }
                    else {
                        next(vm => HandleErrors.formError(error, vm));
                    }
                })
                .then (() => Loading.hide());
        },
        beforeRouteUpdate(to, from, next)
        {
            if (from.params.resource !== to.params.resource) {
                Loading.show();

                // Reiniciar dados
                this.getUsageCancel = null;
                this.getUsageTimestamp = null;
                this.chartRam = null;
                this.cpuUsage = "-";
                this.ramUsage = "-";
                this.diskUsage = null;
                this.emailsUsage = 0;
                this.databasesUsage = 0;

                getHosting(to.params.resource)
                    .then ((hosting) => {
                        this.hosting = hosting;
                        this.domain  = this.hosting.domain;
                        this.needsRecharge = false;
                        this.initPanel();
                        next();
                    })
                    .catch((error) => {
                        if (error.response.status === 402) {
                            this.hosting = null;
                            this.needsRecharge = true;
                            next();
                        }
                        else if (error.response && error.response.status === 404) {
                            next({ name: 'notFound', params: [to.path] });
                        }
                        else {
                            HandleErrors.formError(error, this);
                            next();
                        }
                    })
                    .then (() => Loading.hide());
            }
            else {
                next();
            }
        },
        beforeRouteLeave(to, from, next)
        {
            if (this.timeoutCheckStatus) {
                clearTimeout(this.timeoutCheckStatus);
            }

            if (this.intervalGetUsage) {
                clearInterval(this.intervalGetUsage);
            }

            next();
        },
        mounted()
        {
            this.intervalGetUsage = setInterval(() => { this.getResourceUsage() }, 10000);

            this.$eventBus.$on('php-changed', this.fetchPhpVersions);
        },
        methods:
        {
            /**
             *
             */
            getHostingUrl(dest)
            {
                let url = '/hosting/'+ this.domain;
                url += dest ? '/' + dest : '';
                return url;
            },

            /**
             *
             */
            getToggleClasses(subItems)
            {
                let classes = "custom-toggle";

                if (typeof subItems !== "undefined") {
                    if (!Array.isArray(subItems)) {
                        subItems = [subItems];
                    }

                    for (let i = 0; i < subItems.length; i++) {
                        if (this.$route.path === this.getHostingUrl(subItems[i])) {
                            classes += " active";
                            break;
                        }
                    }
                }

                return classes;
            },

            /**
             *
             */
            getFormattedPriceHtml(price, decimals, smallCents)
            {
                const _price = parseFloat(price);
                const _decimals = typeof decimals !== "undefined" ? parseInt(decimals) : 2;
                const _smallCents = !(typeof smallCents !== "undefined" && smallCents === false);

                let _html  = Math.floor(_price).toString();

                if (_smallCents) {
                    const _cents = Math.round((_price - Math.floor(_price)) * 100);

                    if (_cents) {
                        _html += '<small>,'+ _cents +'</small>';
                    }
                }
                else {
                    let _cents = Math.round((_price - Math.floor(_price)) * Math.pow(10, _decimals));
                    _cents = ("0".repeat(_decimals) + _cents.toString()).slice(-1 * _decimals);
                    _html += "," + _cents;
                }

                return _html;
            },

            /**
             * Inicializar funções do painel de hospedagem.
             */
            initPanel()
            {
                if (this.hosting.status === "Pending") {
                    this.refreshStatus();
                }
                else if (this.hosting.status === "Active") {
                    this.fetchPhpVersions();
                    this.loadCharts();
                }

                this.getResourceUsage(true);
            },

            /**
             * Atualizar status da hospedagem.
             */
            refreshStatus()
            {
                axios.get(`/api/hosting/${this.domain}`)
                    .then ((response) => {
                        let status = response.data.data.status;

                        if (status === "Pending") {
                            this.timeoutCheckStatus = setTimeout(() => this.refreshStatus(), 15000);
                        }
                        else if (status === "Active") {
                            window.location.href = `/hosting/${this.domain}`;
                        }
                        else {
                            alert(`Ocorreu um problema durante a configuração do site.
                            Por favor, atualize sua página e tente novamente.`);
                        }
                    });
            },

            /**
             * Obter uso de recursos para a hospedagem.
             */
            getResourceUsage(getDiskUsage)
            {
                if (this.hosting.status !== "Active") return;

                const CancelToken = axios.CancelToken;
                const addDiskUsage = typeof getDiskUsage !== "undefined" && getDiskUsage === true ? 1 : 0;
                let vm = this;
                let _params = {};

                if (addDiskUsage) {
                    _params['disk'] = addDiskUsage;
                }

                if (this.getUsageTimestamp) {
                    _params['t'] = this.getUsageTimestamp;
                }

                // Cancelar verificação anterior
                if (this.getUsageCancel) {
                    this.getUsageCancel();
                }


                let promise = axios({
                    method: 'get',
                    url: `/api/hosting/${this.domain}/usage`,
                    params: _params,
                    cancelToken: new CancelToken(function executor(c) {
                        if (!addDiskUsage) {
                            vm.getUsageCancel = c;
                        }
                    })
                });

                promise
                    .then((response) => {
                        if (response.status === 200) {
                            const cpu  = Math.round(response.data.data.cpu);
                            const ram  = Math.round(response.data.data.ram);

                            this._updateChartData(cpu, ram);

                            this.cpuUsage = cpu;
                            this.ramUsage = ram;

                            if (response.data.data.disk) {
                                this.diskUsage = Math.round(response.data.data.disk);
                                this.emailsUsage = Math.round(response.data.data.emails);
                                this.databasesUsage = Math.round(response.data.data.databases);
                            }

                            if (response.data.data.timestamp) {
                                this.getUsageTimestamp = response.data.data.timestamp;
                            }
                        }
                    });
            },

            loadCharts()
            {
                let vm = this;
                let ctxChartCpu = this.$refs.chartCpu.getContext('2d');
                let ctxChartRam = this.$refs.chartRam.getContext('2d');

                let chartConfig = {
                    type: 'doughnut',
                    options: {
                        responsive: true,
                        legend: {
                            display: false
                        },
                        title: {
                            display: false
                        },
                        animation: {
                            animateRotate: true
                        },
                        cutoutPercentage: 0,
                        circumference: Math.PI,
                    },
                };

                this.chartCpu = new Chart(ctxChartCpu, $.extend(true, {}, chartConfig, {
                    data: {
                        datasets: [{
                            data: [0,0,100],
                            backgroundColor: ['#23F694','#23F694','#e1e1e1'],
                            borderWidth: [0,0,0]
                        }],
                        labels: [
                            '',
                            'CPU',
                            'Disponível',
                        ]
                    },
                    options: {
                        rotation: Math.PI/2,
                        tooltips: {
                            enabled: false,
                            custom: function(model) {
                                if (model.opacity === 0) {
                                    vm.$refs.usageCpu.querySelector(".usage-info").classList.remove("font-weight-bold");
                                }
                                else {
                                    vm.$refs.usageCpu.querySelector(".usage-info").classList.add("font-weight-bold");
                                }
                            }
                        },
                    }
                }));

                this.chartRam = new Chart(ctxChartRam, $.extend(true, {}, chartConfig, {
                    data: {
                        datasets: [{
                            data: [100,0,0],
                            backgroundColor: ['#e1e1e1','#54bfeb','#54bfeb'],
                            borderWidth: [0,0,0]
                        }],
                        labels: [
                            'Disponível',
                            'RAM',
                            '',
                        ]
                    },
                    options: {
                        tooltips: {
                            enabled: false,
                            custom: function(model) {
                                if (model.opacity === 0) {
                                    vm.$refs.usageRam.querySelector(".usage-info").classList.remove("font-weight-bold");
                                }
                                else {
                                    vm.$refs.usageRam.querySelector(".usage-info").classList.add("font-weight-bold");
                                }
                            }
                        },
                    }
                }));
            },
            _updateChartData(cpu, ram)
            {
                const shift = 20;

                let _cpu = cpu || 0;
                let _ram = ram || 0;

                _cpu = cpu * ((100 - shift) / 100);
                _ram = ram * ((100 - shift) / 100);

                this.chartCpu.data.datasets[0].data = [ shift, _cpu, (100 - shift - _cpu) ];
                this.chartRam.data.datasets[0].data = [ (100 - shift - _ram), _ram, shift ];

                this.chartCpu.update();
                this.chartRam.update();
            },

            fetchPhpVersions()
            {
                this.php = {};

                axios.get(`/api/hosting/${this.domain}/php`)
                    .then ((response) => this.php = response.data.data)
                    .catch((error) => HandleErrors.formError(error));
            },

            changePhpVersion(version)
            {
                Loading.show();

                axios.post(`/api/hosting/${this.domain}/php`, { 'version': version })
                    .then ((response) => {
                        this.php.version = "";
                        this.fetchPhpVersions();
                    })
                    .catch((error) => HandleErrors.formError(error))
                    .then (() => Loading.hide());
            },
        }
    }
</script>
