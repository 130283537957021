<template>
    <div>
        <card-default :title="$route.meta.title" icon="fas fa-globe">
            <template slot="buttons">
                <button type="button" class="btn btn-primary btn-rounded" data-toggle="modal" data-target="#modalAddAlias" :disabled="!hosting || hosting.status !== 'Active'">
                    Adicionar domínio
                </button>
            </template>

            <p class="mb-4">
                Domínios adicionais permitem que o seu site possa ser acessado utilizando outros domínios.
                Por exemplo, você pode fazer com que <strong>meudominio.net</strong> e <strong>meudominio.org</strong>
                exibam o conteúdo de <strong>meudominio.com</strong>.
            </p>

            <div class="btn-toolbar mb-3" role="toolbar">
                <div class="input-group input-group-rounded" v-input-group-focus>
                    <div class="input-group-prepend">
                        <div class="input-group-text bg-white pr-0">
                            <i class="fas fa-search" aria-hidden="true"></i>
                        </div>
                    </div>
                    <input type="search" class="form-control border-left-0" v-model="filterText" placeholder="Buscar domínios..." spellcheck="false">
                </div>
            </div>

            <div class="card card-table">
                <table class="table" v-bind:class="[domains.length ? 'table-hover' : '']">
                    <thead>
                        <tr>
                            <th scope="col">Domínio</th>
                            <th scope="col" class="col-options "><span class="sr-only">Opções</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="isLoading">
                            <td colspan="2">
                                <loading-message></loading-message>
                            </td>
                        </tr>

                        <tr v-else-if="!isLoading && !domains.length">
                            <td colspan="2">
                                Nenhum domínio adicional encontrado.
                            </td>
                        </tr>

                        <tr v-else v-for="(item, index) in filteredItems">
                            <td class="col-nowrap">
                                {{ item }}
                            </td>
                            <td class="col-options">
                                <div class="btn-group visible-on-hover" role="group">
                                    <a class="btn btn-sm btn-outline-primary" title="Acessar" :href="`http://${item}`" target="_blank">
                                        <i class="fas fa-external-link-alt" aria-hidden="true"></i>
                                        <span class="sr-only">Acessar</span>
                                    </a>

                                    <button type="button" class="btn btn-sm btn-outline-danger" title="Excluir" @click="confirmRemove(index)">
                                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                                        <span class="sr-only">Excluir</span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </card-default>

        <div class="modal fade" id="modalAddAlias" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Cadastrar domínio adicional</h5>
                    </header>
                    <form accept-charset="UTF-8" @submit.prevent="addAlias">
                        <div class="modal-body">
                            <div class="alert alert-warning">
                                <p>
                                    <strong>Nota:</strong> Domínios devem estar registrados e configurados para apontar para os
                                    seus servidores DNS antes de poderem ser utilizados como domínio adicional.
                                </p>
                            </div>

                            <form-control class="mb-0" :error="errors.domain">
                                <label class="form-label ml-2" for="domainAlias">Domínio</label>
                                <input type="text" id="domainAlias" class="form-control input-rounded" v-model="alias.domain" spellcheck="false">
                            </form-control>
                        </div>

                        <footer class="modal-footer justify-content-start">
                            <button-submit ref="submitAddAlias" class="btn-rounded">Adicionar</button-submit>
                            <button type="button" ref="cancelAddAlias" class="btn btn-secondary btn-rounded" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modalConfirmRemove" ref="modalConfirmRemove" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div tabindex="-1" role="document" class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Remover domínio adicional</h5>
                    </header>
                    <div class="modal-body">
                        <p class="mb-0">
                            Tem certeza que deseja remover o domínio adicional <strong>{{ domains[removeIndex] }}</strong>?
                        </p>
                    </div>
                    <footer class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-rounded" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-danger btn-rounded" @click="removeAlias">Remover</button>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CardDefault    from '../../components/CardDefault'
    import FormControl    from '../../components/FormControl'
    import LoadingMessage from '../../components/LoadingMessage'
    import { getHosting } from '../../services/HostingService'

    export default {
        props: ['resource'],
        data() {
            return {
                hosting: null,
                domains: [],
                alias: {},
                errors: {},
                isLoading: false,

                filterText: "",
                filteredItems: [],

                removeIndex: null,
            }
        },
        components: {
            CardDefault,
            FormControl,
            LoadingMessage,
        },
        mounted()
        {
            getHosting(this.resource)
                .then((hosting) => this.hosting = hosting);

            this.fetchDomains();
        },
        methods: {
            /**
             * Listar domínios adicionais.
             */
            fetchDomains()
            {
                this.isLoading = true;

                axios.get(`/api/domain/${this.resource}/alias`)
                    .then ((response) => {
                        this.domains = response.data.data;
                        this.filteredItems = this.domains;
                    })
                    .catch((error) => HandleErrors.formError(error, this))
                    .then (() => this.isLoading = false);
            },

            /**
             * Adicionar domínio adicional.
             */
            addAlias()
            {
                let vm = this;
                this.errors = {};

                this.$refs.submitAddAlias.$el.focus();
                this.$refs.submitAddAlias.setLoading();
                this.$refs.cancelAddAlias.disabled = true;

                axios.post(`/api/domain/${this.resource}/alias`, this.alias)
                    .then((response) => {
                        this.fetchDomains();

                        $("#modalAddAlias").modal("hide");

                        setTimeout(() => {
                            this.alias = {};
                            this.errors = {};
                            this.$refs.cancelAddAlias.disabled = false;
                            this.$refs.submitAddAlias.setLoading(false);
                        }, 300);
                    })
                    .catch((response) => {
                        HandleErrors.formError(response, vm);
                        this.$refs.cancelAddAlias.disabled = false;
                        this.$refs.submitAddAlias.setLoading(false);
                    });
            },

            /**
             * Exibir confirmação para remover domínio adicional.
             */
            confirmRemove(index)
            {
                this.removeIndex = index;
                $(this.$refs.modalConfirmRemove).modal("show");
            },

            /**
             * Remover domínio adicional.
             */
            removeAlias()
            {
                let domain = this.domains[this.removeIndex];

                $(this.$refs.modalConfirmRemove).modal("hide");
                Loading.show();

                setTimeout(() => {
                    this.domains.splice(this.removeIndex, 1);
                    Loading.hide();
                }, 2000);

                axios.delete(`/api/domain/${this.resource}/alias/${domain}`)
                    .then ((response) => {})
                    .catch((error) => alert(`Ocorreu um erro ao tentar excluir o domínio adicional. Por favor, atualize sua página e tente novamente.`));
            },
        },
        watch: {
            filterText: function(val)
            {
                if (val) {
                    let searchTerm = val.trim().toLowerCase();

                    this.filteredItems = this.domains.filter((element) => {
                        return element.toLowerCase().indexOf(searchTerm) !== -1;
                    });
                }
                else {
                    this.filteredItems = this.domains;
                }
            },
        }
    }
</script>
