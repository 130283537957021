<template>
    <button type="submit" class="btn" v-bind:class="[styleClass, isLoading ? 'btn-loading' : '']" v-bind:disabled="isDisabled || isLoading">
        <slot ref="text"></slot>
    </button>
</template>

<script>
    export default {
        props: {
            theme: {
                type: String,
                default: "primary",
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isDisabled: this.disabled,
                isLoading: this.loading,
            }
        },
        computed: {
            styleClass() {
                return (this.theme ? "btn-" + this.theme : "");
            },
        },
        methods: {
            setDisabled(isDisabled) {
                this.isDisabled = (typeof isDisabled == "undefined" || isDisabled !== false);
            },
            setLoading(isLoading) {
                this.isLoading = (typeof isLoading == "undefined" || isLoading !== false);
            },
            setText(text) {
                this.$refs.text.innerHTML = text;
            }
        }
    }
</script>
