<template>
    <div>
        <card-default v-show="!isEditing" :title="$route.meta.title" icon="far fa-copy">
            <template slot="buttons">
                <button type="button" class="btn btn-success btn-rounded" data-toggle="modal" data-target="#modalTips">
                    <i class="fas fa-question" aria-hidden="true"></i>
                    Ajuda
                </button>
            </template>

            <file-manager
                ref="filemanager"
                :files="files"
                :path="currentPath"
                :loading="isLoadingFiles"
                v-on:home="fetchFiles"
                v-on:refresh="fetchFiles(currentPath)"
                v-on:download="fileDownload"
                v-on:upload="fileUpload"
                v-on:folder-changed="changeFolder"
                v-on:file-entered="fileEnter"
                v-on:file-renamed="fileRename"
                v-on:file-moved="fileMove"
                v-on:create-folder="openCreateFolderModal"
                v-on:create-file="openCreateFileModal"
                v-on:rename="openRenameModal"
                v-on:compress="openCompressModal"
                v-on:extract="openExtractModal"
                v-on:remove="confirmRemove"
                v-on:change-permissions="openChangePermissionsModal">
            </file-manager>
        </card-default>

        <card-default v-show="isEditing" class="card-editor">
            <div class="d-flex justify-content-md-between align-items-md-start mb-4">
                <h6 class="mb-0">
                    <i class="far fa-file-alt mr-1"></i>
                    <span v-html="getFormattedFilePath(editor.file)"></span>
                </h6>

                <div class="card-actions d-flex flex-nowrap">
                    <button type="button" ref="btnSaveFile" class="btn btn-success btn-rounded" @click="saveFile">Salvar</button>
                    <button type="button" ref="btnCancelFile" class="btn btn-secondary btn-rounded" @click="cancelEdit">Voltar</button>
                </div>
            </div>

            <loading-message v-show="isLoadingContent"></loading-message>

            <div v-show="!isLoadingContent" class="editor-wrapper">
                <editor
                    ref="editor"
                    theme="monokai"
                    width="100%"
                    height="500"
                    v-model="editor.content"
                    :options="editorOptions"
                    :lang="editor.lang"
                    @init="initEditor">
                </editor>
            </div>
        </card-default>

        <!--<div class="card" id="card-files-1">
            <div class="card-header d-flex justify-content-between">
                <h5 class="mb-0">Preparando o download</h5>
                <div>
                    <a data-toggle="collapse" href="#card-files-body-1" role="button" aria-expanded="true" aria-controls="card-files-body-1">
                        <i class="fas fa-chevron-up"></i>
                    </a>
                    <a class="ml-3" href="#">
                        <i class="fas fa-times"></i>
                    </a>
                </div>
            </div>
            <div class="collapse show" id="card-files-body-1">
                <div class="card-body">
                    <p class="card-text">
                        <i class="fas fa-file-archive"></i> Compactando 2 arquivos
                    </p>
                </div>
            </div>
        </div>-->

        <div class="modal fade" ref="modalCreateFolder" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Criar nova pasta</h5>
                    </header>

                    <form accept-charset="UTF-8" @submit.prevent="createFolder">
                        <div class="modal-body">
                            <form-control class="mb-0" :error="errors.name">
                                <label class="form-label ml-2" for="folderName">Nome da pasta</label>
                                <input type="text" id="folderName" class="form-control input-rounded" spellcheck="false" v-model="newFolder.name">
                            </form-control>
                        </div>

                        <footer class="modal-footer justify-content-start">
                            <button-submit ref="submitCreateFolder" class="btn-rounded">Criar</button-submit>
                            <button type="button" ref="cancelCreateFolder" class="btn btn-secondary btn-rounded ml-1" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" ref="modalCreateFile" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Criar novo arquivo</h5>
                    </header>

                    <form accept-charset="UTF-8" @submit.prevent="createFile">
                        <div class="modal-body">
                            <form-control class="mb-0" :error="errors.name">
                                <label class="form-label ml-2" for="fileName">Nome do arquivo</label>
                                <input type="text" id="fileName" class="form-control input-rounded" spellcheck="false" v-model="newFile.name">
                            </form-control>
                        </div>

                        <footer class="modal-footer justify-content-start">
                            <button-submit ref="submitCreateFile" class="btn-rounded">Criar</button-submit>
                            <button type="button" ref="cancelCreateFile" class="btn btn-secondary btn-rounded ml-1" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" ref="modalRename" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Renomear arquivo</h5>
                    </header>

                    <form accept-charset="UTF-8" @submit.prevent="rename">
                        <div class="modal-body">
                            <div class="form-group">
                                <label class="form-label ml-2" for="fileOldName">Nome atual</label>
                                <input type="text" id="fileOldName" class="form-control input-rounded" readonly v-model="newFile.oldName">
                            </div>
                            <form-control class="mb-0" :error="errors.name">
                                <label class="form-label ml-2" for="fileNewName">Novo nome</label>
                                <input type="text" id="fileNewName" class="form-control input-rounded" spellcheck="false" v-model="newFile.name">
                            </form-control>
                        </div>

                        <footer class="modal-footer justify-content-start">
                            <button-submit ref="submitRename" class="btn-rounded">Renomear</button-submit>
                            <button type="button" ref="cancelRename" class="btn btn-secondary btn-rounded ml-1" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>

        <modal-confirm id="modalConfirmRemove" title="Confirmação de exclusão" confirm-text="Remover" danger @confirm="remove">
            <p>
                Tem certeza que deseja excluir os seguintes arquivos:
            </p>

            <div class="card">
                <ul class="list-group list-group-flush" style="max-height:260px;overflow-y:scroll">
                    <li class="list-group-item py-1 px-2" v-for="item in filesToModify">
                        <span class="text-monospace">{{ item.path }}</span>
                    </li>
                </ul>
            </div>
        </modal-confirm>

        <div class="modal fade" ref="modalCompress" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Compactar arquivos</h5>
                    </header>

                    <form accept-charset="UTF-8" @submit.prevent="compress">
                        <div class="modal-body">
                            <form-control class="pl-2" :error="errors.format">
                                <label class="form-label">Formato da compressão</label>
                                <b-form-radio-group v-model="filesToCompress.format" @change="updateCompressFormat">
                                    <b-form-radio value="zip">*.zip</b-form-radio>
                                    <b-form-radio value="gz">*.gz</b-form-radio>
                                </b-form-radio-group>
                            </form-control>

                            <form-control :error="errors.name">
                                <label class="form-label ml-2">Nome do arquivo compactado</label>
                                <input type="text" id="compressName" class="form-control input-rounded" spellcheck="false" v-model="filesToCompress.name">
                            </form-control>

                            <div class="card">
                                <div class="card-header border-bottom-0 p-0">
                                    <button class="btn btn-link w-100 text-left px-2 collapsed" type="button" data-toggle="collapse" data-target="#collapseCompressFiles" aria-expanded="false" aria-controls="collapseCompressFiles">
                                        Lista de arquivos <i class="fas fa-angle-down ml-1"></i>
                                    </button>
                                </div>
                                <div id="collapseCompressFiles" class="collapse">
                                    <ul class="list-group list-group-flush" style="max-height:180px;overflow-y:scroll">
                                        <li class="list-group-item py-1 px-2" v-for="(item, index) in filesToCompress.files" v-bind:class="{'border-top': index === 0}">
                                            <span class="text-monospace">{{ item.path }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <footer class="modal-footer justify-content-start flex-wrap">
                            <div ref="compressAlert" class="alert alert-warning w-100 mx-0" style="display:none">
                                <i class="far fa-clock mr-1"></i>
                                A compactação pode demorar alguns minutos...
                            </div>

                            <button-submit ref="submitCompress" class="btn-rounded">Compactar</button-submit>
                            <button type="button" ref="cancelCompress" class="btn btn-secondary btn-rounded ml-1" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" ref="modalExtract" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Descompactar arquivo</h5>
                    </header>

                    <form accept-charset="UTF-8" @submit.prevent="extract">
                        <div class="modal-body">
                            <p class="mb-3">
                                <strong>Arquivo:</strong> {{ fileToExtract.file ? fileToExtract.file.name : '' }}<br>
                                <strong>Destino:</strong> {{ fileToExtract.dest }}
                            </p>

                            <form-control class="mb-0" :error="errors.deleteArchive">
                                <b-form-checkbox v-model="fileToExtract.deleteArchive">
                                    Excluir arquivo após descompactar
                                </b-form-checkbox>
                            </form-control>
                        </div>

                        <footer class="modal-footer justify-content-start flex-wrap">
                            <div class="alert alert-warning w-100 mx-0">
                                Atenção! Os arquivos que possuírem o mesmo nome no diretório de destino serão sobrescritos.
                            </div>

                            <div ref="extractAlert" class="alert alert-warning w-100 mx-0" style="display:none">
                                <i class="far fa-clock mr-1"></i>
                                A descompactação pode demorar alguns minutos...
                            </div>

                            <button-submit ref="submitExtract" class="btn-rounded">Descompactar</button-submit>
                            <button type="button" ref="cancelExtract" class="btn btn-secondary btn-rounded ml-1" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" ref="modalDownloadMultiple" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Download de múltiplos arquivos</h5>
                    </header>

                    <div class="modal-body">
                        <p>
                            Para fazer o download de múltiplos arquivos ou de uma pasta será gerado um arquivo
                            compactado com os itens selecionados.
                        </p>
                        <p>
                            <strong>Certifique-se que sua hospedagem possui espaço de armazenamento disponível para o
                            arquivo gerado.</strong>
                        </p>
                        <p>
                            Arquivos e pastas a serem compactados:
                        </p>

                        <div class="card">
                            <ul class="list-group list-group-flush" style="max-height:260px;overflow-y:scroll">
                                <li class="list-group-item py-1 px-2" v-for="item in filesToModify">
                                    <span class="text-monospace">{{ item.path }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <footer class="modal-footer justify-content-start flex-wrap">
                        <div ref="alertDownloadMultiple" class="alert alert-warning w-100 mx-0" style="display:none">
                            <i class="far fa-clock mr-1"></i>
                            A compactação pode demorar alguns minutos...
                        </div>

                        <button type="button" ref="submitDownloadMultiple" class="btn btn-primary btn-rounded" @click="downloadMultiple">Compactar e Baixar</button>
                        <button type="button" ref="cancelDownloadMultiple" class="btn btn-secondary btn-rounded" data-dismiss="modal">Cancelar</button>
                    </footer>
                </div>
            </div>
        </div>

        <div class="modal fade" ref="modalChangePermissions" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Alterar permissões</h5>
                    </header>

                    <form accept-charset="UTF-8" @submit.prevent="changePermissions">
                        <div class="modal-body">
                            <div class="form-group">
                                <label class="form-label ml-2">Arquivo</label>
                                <input type="text" class="form-control input-rounded text-monospace" v-model="permissions.name" readonly>
                            </div>

                            <div class="form-group">
                                <label class="form-label ml-2">Permissões de usuário</label>
                                <div class="row px-2">
                                    <div class="col-4">
                                        <b-form-checkbox v-model="permissions.u_r" @change="updateNumericPermissions">Leitura</b-form-checkbox>
                                    </div>
                                    <div class="col-4">
                                        <b-form-checkbox v-model="permissions.u_w" @change="updateNumericPermissions">Gravação</b-form-checkbox>
                                    </div>
                                    <div class="col-4">
                                        <b-form-checkbox v-model="permissions.u_x" @change="updateNumericPermissions">Execução</b-form-checkbox>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="form-label ml-2">Permissões de grupo</label>
                                <div class="row px-2">
                                    <div class="col-4">
                                        <b-form-checkbox v-model="permissions.g_r" @change="updateNumericPermissions">Leitura</b-form-checkbox>
                                    </div>
                                    <div class="col-4">
                                        <b-form-checkbox v-model="permissions.g_w" @change="updateNumericPermissions">Gravação</b-form-checkbox>
                                    </div>
                                    <div class="col-4">
                                        <b-form-checkbox v-model="permissions.g_x" @change="updateNumericPermissions">Execução</b-form-checkbox>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="form-label ml-2">Permissões públicas</label>
                                <div class="row px-2">
                                    <div class="col-4">
                                        <b-form-checkbox v-model="permissions.o_r" @change="updateNumericPermissions">Leitura</b-form-checkbox>
                                    </div>
                                    <div class="col-4">
                                        <b-form-checkbox v-model="permissions.o_w" @change="updateNumericPermissions">Gravação</b-form-checkbox>
                                    </div>
                                    <div class="col-4">
                                        <b-form-checkbox v-model="permissions.o_x" @change="updateNumericPermissions">Execução</b-form-checkbox>
                                    </div>
                                </div>
                            </div>

                            <form-control class="mb-0" :error="errors.numeric">
                                <label class="form-label ml-2" for="permissionsNumeric">Valor numérico (octal)</label>
                                <the-mask
                                    type="tel"
                                    id="permissionsNumeric"
                                    class="form-control input-rounded"
                                    v-model="permissions.numeric"
                                    mask="OOO"
                                    :tokens="permissionTokens"
                                    placeholder="000"
                                    readonly></the-mask>
                            </form-control>
                        </div>

                        <footer class="modal-footer justify-content-start">
                            <button-submit ref="submitChangePermissions" class="btn-rounded">Salvar</button-submit>
                            <button type="button" ref="cancelChangePermissions" class="btn btn-secondary btn-rounded ml-1" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modalTips" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <h4 class="text-center">Atalhos do teclado</h4>

                        <table class="table">
                            <tr>
                                <td class="col-100 border-top-0">
                                    <kbd><kbd>Ctrl</kbd> + <kbd>A</kbd></kbd>
                                </td>
                                <td class="border-top-0">Selecionar todos os arquivos</td>
                            </tr>
                            <tr>
                                <td class="col-100">
                                    <kbd><kbd>Alt</kbd> + <kbd>R</kbd></kbd>
                                </td>
                                <td>Atualizar lista de arquivos</td>
                            </tr>
                            <tr>
                                <td class="col-100">
                                    <kbd>F2</kbd>
                                </td>
                                <td>Renomear arquivo selecionado</td>
                            </tr>
                            <tr>
                                <td class="col-100">
                                    <kbd>Delete</kbd>
                                </td>
                                <td>Excluir arquivo(s)</td>
                            </tr>
                        </table>

                        <hr class="spacer mb-3">

                        <h4 class="text-center">Ações do Gerenciador</h4>

                        <table class="table mb-0">
                            <tr>
                                <td class="border-top-0">
                                    <strong>Duplo clique em um arquivo</strong><br>
                                    Faz o download do arquivo ou abre o editor se for um arquivo editável.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Duplo clique em uma pasta</strong><br>
                                    Abrir pasta e listar arquivos.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Selecionar arquivos e clicar no botão Download</strong><br>
                                    Será gerado um arquivo compactado com os arquivos selecionados e após a conclusão o
                                    arquivo compactado será baixado.
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CardDefault    from "../../components/CardDefault"
    import LoadingMessage from "../../components/LoadingMessage"
    import FileManager    from "../../components/FileManager"
    import FormControl    from "../../components/FormControl.vue"
    import ModalConfirm   from '../../components/ModalConfirm'
    import GlobalAlert    from '../../services/GlobalAlertService'
    import editor         from "vue2-ace-editor"

    export default {
        props: ['resource'],
        data() {
            return {
                files: [],
                currentPath: "",
                newFolder: {},
                newFile: {},
                filesToModify: [],
                errors: {},

                filesToCompress: {},
                fileToExtract: {},

                filesToUpload: [],
                countUploads: 0,

                permissions: {},
                permissionTokens: {
                    O: {
                        pattern: /[0-7]/,
                    }
                },

                editor: {
                    file: "",
                    content: "",
                    lang: "",
                    options: {

                    }
                },
                editorOptions: {
                    minLines: 45,
                },

                cancel: null,
                cancelTokens: {},
                isLoadingFiles: false,
                isEditing: false,
                isLoadingContent: false,
                // isFullScreen: false,
            }
        },
        components: {
            CardDefault,
            LoadingMessage,
            FileManager,
            FormControl,
            ModalConfirm,
            editor,
        },
        // beforeRouteEnter (to, from, next) {
        //     Loading.show();
        //
        //     axios.get(`/api/hosting/${to.params.resource}/files`)
        //         .then ((response) => next(vm => {
        //             vm.files = response.data.data.files;
        //             vm.currentPath = response.data.data.path;
        //         }))
        //         .catch((error) => HandleErrors.formError(error))
        //         .then (() => Loading.hide());
        // },
        mounted()
        {
            const CancelToken = axios.CancelToken;
            this.cancel = CancelToken.source();

            this.fetchFiles();
        },
        beforeDestroy()
        {
            this.cancel.cancel();
        },
        methods: {
            /**
             * Inicializar editor de arquivos.
             * TODO: Carregar de forma assíncrona
             */
            initEditor()
            {
                let vm = this;

                require("brace/theme/monokai");
                require("brace/ext/language_tools");
                require("brace/mode/html");
                require("brace/mode/css");
                require("brace/mode/javascript");
                require("brace/mode/json");
                require("brace/mode/php");
                require("brace/mode/ini");
                require("brace/mode/xml");
                require("brace/mode/apache_conf");

                vm.$refs.editor.editor.renderer.setScrollMargin(10, 10);

                /*
                vm.$refs.editor.editor.commands.addCommand({
                    name: "Alternar tela cheia",
                    bindKey: "F11",
                    exec: function(editor) {
                        vm.isFullScreen = !vm.isFullScreen;

                        if (vm.isFullScreen) {
                            vm.$refs.editor.$el.classList.add("full-screen");
                        }
                        else {
                            vm.$refs.editor.$el.classList.remove("full-screen");
                        }

                        editor.setAutoScrollEditorIntoView(!vm.isFullScreen);
                        editor.resize();
                    }
                });
                */
            },

            /**
             * Carregar lista de arquivos.
             */
            fetchFiles(path, history)
            {
                const CancelToken = axios.CancelToken;
                let vm = this;

                let _params   = {},
                    _path     = typeof path !== 'undefined' && path ? path : '',
                    _new_path = _path || '/',
                    _history  = typeof history !== 'undefined' ? history : true;

                if (_path) {
                    _params['path'] = _path;
                }

                this.$set(this, 'currentPath', _new_path);

                this.isLoadingFiles = true;

                if (this.cancelTokens['fetchFiles']) {
                    this.cancelTokens.fetchFiles();
                }

                let promise = axios({
                    method: 'get',
                    url: `/api/hosting/${this.resource}/files`,
                    params: _params,
                    cancelToken: new CancelToken(function executor(c) {
                        vm.cancelTokens['fetchFiles'] = c;
                    })
                });

                promise.then((response) => {
                    let _new_files = response.data.data.files;
                    let _new_path  = response.data.data.path;

                    this.$set(this, 'files', _new_files);
                    this.$set(this, 'currentPath', _new_path);

                    this.$refs.filemanager.removeSelection();

                    if (_history) {
                        this.$refs.filemanager.historyPush(this.currentPath);
                    }

                    this.isLoadingFiles = false;
                })
                .catch((error) => {
                    if (axios.isCancel(error)) return;
                    HandleErrors.formError(error);
                    this.isLoadingFiles = false;
                });
            },

            /**
             *
             */
            sortFiles()
            {
                this.files.sort(function(a, b) {
                    const textA = a.name.toUpperCase();
                    const textB = b.name.toUpperCase();

                    if (a.type === 'dir' && b.type !== 'dir') return -1;
                    if (a.type !== 'dir' && b.type === 'dir') return 1;

                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
            },

            /**
             *
             */
            fileEnter(file)
            {
                if (file.type === 'dir') {
                    this.fetchFiles(file.path);
                }
                else {
                    if (file.editable) {
                        this.$refs.filemanager.disableKeyboardEvents();
                        this._fileEdit(file);
                    }
                    else {
                        this.downloadSingle(file);
                    }
                }
            },

            /**
             * Exibir editor de arquivo.
             */
            _fileEdit(file)
            {
                const CancelToken = axios.CancelToken;
                let vm = this;

                let _params = {
                    'path': file.path,
                };

                this.editor.file = file.path;
                this.isEditing = true;
                this.isLoadingContent = true;
                this.$refs.btnSaveFile.disabled = true;

                if (this.cancelTokens['fileEdit']) {
                    this.cancelTokens.fileEdit();
                }

                let promise = axios({
                    method: 'get',
                    url: `/api/hosting/${this.resource}/files/edit`,
                    params: _params,
                    cancelToken: new CancelToken(function executor(c) {
                        vm.cancelTokens['fileEdit'] = c;
                    })
                });

                promise
                    .then ((response) => {
                        this.editor.lang = response.data.data.lang;
                        this.$refs.editor.editor.session.setValue(response.data.data.content, 1);
                        this.isLoadingContent = false;
                        this.$refs.btnSaveFile.disabled = false;
                    })
                    .catch((error) => {
                        if (axios.isCancel(error)) return;
                        HandleErrors.ajaxErrorAlert(error);
                        this.isLoadingContent = false;
                    });
            },

            saveFile()
            {
                let _params = {
                    'path': this.editor.file,
                    'content': this.editor.content,
                };

                this.$refs.btnSaveFile.focus();
                this.$refs.btnSaveFile.disabled = true;
                this.$refs.btnSaveFile.classList.add("btn-loading");
                this.$refs.btnCancelFile.disabled = true;

                axios.post(`/api/hosting/${this.resource}/files/edit`, _params)
                    .then ((response) => {
                        GlobalAlert.success("Arquivo salvo com sucesso!");
                    })
                    .catch((error) => {
                        HandleErrors.formError(error);
                    })
                    .then(() => {
                        this.$refs.btnSaveFile.disabled = false;
                        this.$refs.btnSaveFile.classList.remove("btn-loading");
                        this.$refs.btnCancelFile.disabled = false;
                    });
            },

            /**
             * Cancelar edição de arquivo.
             */
            cancelEdit()
            {
                if (this.cancelTokens['fileEdit']) {
                    this.cancelTokens.fileEdit();
                }

                this.isEditing = false;
                this.editor.file = "";
                this.editor.content = "";
                this.editor.lang = "";
                this.$refs.filemanager.enableKeyboardEvents();
            },

            getFormattedFilePath(path)
            {
                if (!path) return "";

                const parts = path.split("/");
                const last = parts.splice(-1,1);
                let html = "";

                parts.forEach((el) => {
                    if (!el) return;
                    html += '<span class="px-1">/</span>' + el;
                });

                html += '<span class="px-1">/</span><strong>'+ last +'</strong>';

                return html;
            },

            changeFolder(path, history)
            {
                const _history = typeof history !== 'undefined' ? history : true;
                this.fetchFiles(path, _history);
            },

            fileRename(value, file, index)
            {
                // Se o nome não mudou, cancela a ação
                if (value == this.files[index].name) return;

                this.$set(this.files[index], 'oldName', this.files[index].name);
                this.$set(this.files[index], 'name', value);
                this.$set(this.files[index], 'loading', true);

                let params = {
                    'file': this.files[index].path,
                    'name': value,
                };

                axios.post(`/api/hosting/${this.resource}/files/rename`, params)
                    .then ((response) => {})
                    .catch((error) => {
                        const newIndex = this.files.findIndex(el => el.id == file.id);

                        alert('Ocorreu um erro ao tentar renomear o arquivo '+ this.files[newIndex].oldName);

                        this.files[newIndex].name = this.files[newIndex].oldName;
                        this.sortFiles();
                    })
                    .then (() => {
                        const newIndex = this.files.findIndex(el => el.id == file.id);
                        delete this.files[newIndex].oldName;
                        this.$set(this.files[newIndex], 'loading', false);
                    });

                // Reordenar arquivos após renomear
                this.sortFiles();
            },

            fileMove(files, dest)
            {
                let _files = [];

                files.forEach((el) => { _files.push(el.path) });

                const params = {
                    'file': _files,
                    'dest': dest.path,
                };

                files.forEach((file) => {
                    let index = this.files.findIndex(_el => _el.id == file.id);
                    this.$delete(this.files, index);
                });

                axios.post(`/api/hosting/${this.resource}/files/move`, params, { cancelToken: this.cancel.token })
                    .then ((response) => {})
                    .catch((error) => {
                        alert('Ocorreu um erro ao tentar mover os arquivos.');
                        this.fetchFiles(this.currentPath);
                    });
            },

            fileDownload(items)
            {
                if (items.length === 1 && items[0].type !== 'dir') {
                    this.downloadSingle(items[0]);
                }
                else {
                    this.filesToModify = items;
                    this.openDownloadMultipleModal();
                }
            },

            downloadSingle(file)
            {
                let $alert = GlobalAlert.add("Preparando o arquivo para download...", "info", 0);

                const data = {
                    'name': file.name,
                    'path': file.path,
                };

                axios.post(`/api/hosting/${this.resource}/files/download`, data)
                    .then ((response) => {
                        $alert.remove();
                        GlobalAlert.success("Iniciando download...");

                        // Download
                        let anchor = document.createElement('a');
                        anchor.href = response.data.data.url;
                        anchor.download = data.name;
                        document.body.appendChild(anchor);
                        anchor.click();
                        document.body.removeChild(anchor);
                    })
                    .catch((error) => {
                        $alert.remove();
                        alert('Ocorreu um erro ao tentar fazer o download do arquivo');
                    });
            },

            openDownloadMultipleModal()
            {
                this.$refs.submitDownloadMultiple.classList.remove("btn-loading");
                this.$refs.submitDownloadMultiple.disabled = false;
                this.$refs.cancelDownloadMultiple.disabled = false;
                jQuery(this.$refs.alertDownloadMultiple).hide();
                jQuery(this.$refs.modalDownloadMultiple).modal("show");
            },

            /**
             * Download de múltiplos arquivos.
             * Obs: Será gerado um arquivo compactado com os arquivos selecionados.
             */
            downloadMultiple()
            {
                this.$refs.submitDownloadMultiple.focus();
                this.$refs.submitDownloadMultiple.classList.add("btn-loading");
                this.$refs.submitDownloadMultiple.disabled = true;
                this.$refs.cancelDownloadMultiple.disabled = true;

                this.isLoadingFiles = true;

                let data = {
                    dir: this.currentPath,
                    files: [],
                };

                this.filesToModify.forEach((el) => { data.files.push(el.path) });

                let $alert = GlobalAlert.add("Compactando arquivos para o download...", "info", 0);

                let alertTimeout = setTimeout(() => { $(this.$refs.alertDownloadMultiple).show(); }, 5000);

                axios.post(`/api/hosting/${this.resource}/files/compress?download=1`, data)
                    .then((response) => {
                        jQuery(this.$refs.modalDownloadMultiple).modal("hide");
                        GlobalAlert.success("Arquivos compactados com sucesso!");
                        GlobalAlert.success("Iniciando download...");
                        this.fetchFiles(this.currentPath);

                        // Download
                        let anchor = document.createElement('a');
                        anchor.href = response.data.data.url;
                        anchor.download = response.data.data.name;
                        document.body.appendChild(anchor);
                        anchor.click();
                        document.body.removeChild(anchor);
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, this);
                        this.isLoadingFiles = false;
                    })
                    .then (() => {
                        $alert.remove();
                        clearTimeout(alertTimeout);
                    });
            },

            /**
             *
             */
            fileUpload(file)
            {
                this.filesToUpload.push(file);
                this._nextUpload();
            },

            _nextUpload()
            {
                if (!this.filesToUpload.length || this.countUploads >= 5) return;

                const file = this.filesToUpload.shift();
                this._uploadFile(file);
            },

            /**
             * Upload a file to server.
             */
            _uploadFile(file)
            {
                this.countUploads++;

                let formData = new FormData();

                formData.append('dir',  this.currentPath);
                formData.append('path', file.name);
                formData.append('file', file.file, file.name);

                let axiosPromise = axios({
                    method: 'post',
                    url: `/api/hosting/${this.resource}/files/upload`,
                    data: formData,
                    headers: { 'content-type': 'multipart/form-data' },
                });

                axiosPromise
                    .then((response) => {
                        this.$refs.filemanager.updateUploadFile(file, { success: true });
                    })
                    .catch((error) => {
                        let message = typeof error.response.data.message !== "undefined" ? error.response.data.message : "Erro";
                        this.$refs.filemanager.updateUploadFile(file, { error: message });
                    })
                    .then (() => {
                        this.countUploads--;

                        // Do stuff here

                        this._nextUpload();
                    });
            },

            /**
             * Exibir diálogo para criação de nova pasta.
             */
            openCreateFolderModal()
            {
                this.newFolder = {};
                jQuery(this.$refs.modalCreateFolder).modal("show");
            },

            /**
             * Criar nova pasta.
             */
            createFolder()
            {
                this.$refs.submitCreateFolder.$el.focus();
                this.$refs.submitCreateFolder.setLoading();
                this.$refs.cancelCreateFolder.disabled = true;

                this.newFolder.path = this.currentPath;

                axios.post(`/api/hosting/${this.resource}/files/create-folder`, this.newFolder)
                    .then ((response) => {
                        jQuery(this.$refs.modalCreateFolder).modal("hide");
                        this.fetchFiles(this.currentPath);

                        setTimeout(() => {
                            this.newFolder = {};
                            this.$refs.submitCreateFolder.setLoading(false);
                            this.$refs.cancelCreateFolder.disabled = false;
                        }, 300);
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, this);
                        this.$refs.submitCreateFolder.setLoading(false);
                        this.$refs.cancelCreateFolder.disabled = false;
                    });
            },

            /**
             * Exibir diálogo para criação de novo arquivo.
             */
            openCreateFileModal()
            {
                this.newFile = {};
                jQuery(this.$refs.modalCreateFile).modal("show");
            },

            /**
             * Criar nova pasta.
             */
            createFile()
            {
                this.$refs.submitCreateFile.$el.focus();
                this.$refs.submitCreateFile.setLoading();
                this.$refs.cancelCreateFile.disabled = true;

                this.newFile.path = this.currentPath;

                axios.post(`/api/hosting/${this.resource}/files/create-file`, this.newFile)
                    .then ((response) => {
                        jQuery(this.$refs.modalCreateFile).modal("hide");
                        this.fetchFiles(this.currentPath);

                        setTimeout(() => {
                            this.newFile = {};
                            this.$refs.submitCreateFile.setLoading(false);
                            this.$refs.cancelCreateFile.disabled = false;
                        }, 300);
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, this);
                        this.$refs.submitCreateFile.setLoading(false);
                        this.$refs.cancelCreateFile.disabled = false;
                    });
            },

            /**
             * Exibir diálogo para renomear arquivo ou pasta.
             */
            openRenameModal(file)
            {
                this.newFile = { file: file.path, name: file.name, oldName: file.name };
                jQuery(this.$refs.modalRename).modal("show");
            },

            /**
             * Renomear arquivo ou pasta.
             */
            rename()
            {
                this.errors = {};

                this.$refs.submitRename.$el.focus();
                this.$refs.submitRename.setLoading();
                this.$refs.cancelRename.disabled = true;

                axios.post(`/api/hosting/${this.resource}/files/rename`, this.newFile)
                    .then((response) => {
                        jQuery(this.$refs.modalRename).modal("hide");
                        this.fetchFiles(this.currentPath);

                        setTimeout(() => {
                            this.newFile = {};
                            this.$refs.submitRename.setLoading(false);
                            this.$refs.cancelRename.disabled = false;
                        }, 300);
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, this);
                        this.$refs.submitRename.setLoading(false);
                        this.$refs.cancelRename.disabled = false;
                    });
            },

            /**
             * Exibir diálogo para compactar arquivos.
             */
            openCompressModal(files)
            {
                this.filesToCompress = {
                    'format': 'zip',
                    'name': files[0].name + '.zip',
                    'files': files,
                };

                this.errors = {};

                jQuery(this.$refs.modalCompress).modal("show");
            },

            /**
             * Atualizar nome do arquivo comprimido ao alterar o formato.
             */
            updateCompressFormat(value)
            {
                let newFilename = this.filesToCompress.files[0].name;
                let extension = value;

                if (extension === 'gz' && this.filesToCompress.files.length > 1) {
                    extension = 'tar.gz';
                }

                newFilename += '.' + extension;

                this.filesToCompress.name = newFilename;
            },

            /**
             * Compactar arquivos.
             */
            compress()
            {
                this.$refs.submitCompress.$el.focus();
                this.$refs.submitCompress.setLoading();
                this.$refs.cancelCompress.disabled = true;

                this.isLoadingFiles = true;

                let data = {
                    dir: this.currentPath,
                    format: this.filesToCompress.format,
                    name: this.filesToCompress.name,
                    files: [],
                };

                this.filesToCompress.files.forEach((el) => { data.files.push(el.path) });

                let $alert = GlobalAlert.add("Compactando arquivos...", "info", 0);

                let alertTimeout = setTimeout(() => { $(this.$refs.compressAlert).show(); }, 5000);

                axios.post(`/api/hosting/${this.resource}/files/compress`, data)
                    .then((response) => {
                        jQuery(this.$refs.modalCompress).modal("hide");
                        GlobalAlert.success("Arquivos compactados com sucesso!");
                        this.fetchFiles(this.currentPath);
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, this);
                        this.isLoadingFiles = false;
                    })
                    .then (() => {
                        $alert.remove();
                        clearTimeout(alertTimeout);
                        $(this.$refs.compressAlert).hide();
                        this.$refs.submitCompress.setLoading(false);
                        this.$refs.cancelCompress.disabled = false;
                    });
            },

            /**
             * Exibir diálogo para descompactar um arquivo.
             */
            openExtractModal(file)
            {
                this.fileToExtract = {
                    'file': file,
                    'deleteArchive': false,
                    'dest': this.currentPath,
                };

                this.errors = {};

                jQuery(this.$refs.modalExtract).modal("show");
            },

            /**
             * Descompactar arquivo.
             */
            extract()
            {
                this.$refs.submitExtract.$el.focus();
                this.$refs.submitExtract.setLoading();
                this.$refs.cancelExtract.disabled = true;

                this.isLoadingFiles = true;

                let data = {
                    file: this.fileToExtract.file.path,
                    dir: this.fileToExtract.dest,
                    deleteArchive: this.fileToExtract.deleteArchive,
                };

                let $alert = GlobalAlert.add("Descompactando arquivo...", "info", 0);

                let alertTimeout = setTimeout(() => { $(this.$refs.extractAlert).show(); }, 5000);

                axios.post(`/api/hosting/${this.resource}/files/extract`, data)
                    .then((response) => {
                        jQuery(this.$refs.modalExtract).modal("hide");
                        GlobalAlert.success("Arquivo descompactado com sucesso!");
                        this.fetchFiles(this.currentPath);
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, this);
                        this.isLoadingFiles = false;
                    })
                    .then (() => {
                        $alert.remove();
                        clearTimeout(alertTimeout);
                        $(this.$refs.extractAlert).hide();
                        this.$refs.submitExtract.setLoading(false);
                        this.$refs.cancelExtract.disabled = false;
                    });
            },

            /**
             * Exibir diálogo para confirmação de exclusão.
             * @param items
             */
            confirmRemove(items)
            {
                this.filesToModify = items;
                jQuery("#modalConfirmRemove").modal("show");
            },

            /**
             * Remover arquivos.
             */
            remove()
            {
                document.activeElement.blur();
                jQuery("#modalConfirmRemove").modal("hide");
                this.isLoadingFiles = true;

                let _files = [];
                this.filesToModify.forEach((el) => { _files.push(el.path) });

                let $alert = GlobalAlert.add("Excluindo arquivos...", "info", 0);

                axios.post(`/api/hosting/${this.resource}/files/remove`, { files: _files })
                    .then((response) => {
                        GlobalAlert.success("Arquivos excluídos com sucesso!");
                        this.fetchFiles(this.currentPath);
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, this);
                        this.isLoadingFiles = false;
                    })
                    .then (() => $alert.remove());
            },

            openChangePermissionsModal(file)
            {
                this.permissions = {
                    file: file.path,
                    name: file.name,
                    numeric: file.permissions,
                };

                if (file.permissions && file.permissions.length === 3)
                {
                    // Usuário
                    const permUser = file.permissions.charAt(0);
                    const permUserBin = parseInt(permUser).toString(2);

                    this.permissions.u_r = (permUserBin.charAt(0) === "1");
                    this.permissions.u_w = (permUserBin.charAt(1) === "1");
                    this.permissions.u_x = (permUserBin.charAt(2) === "1");

                    // Grupo
                    const permGroup = file.permissions.charAt(1);
                    const permGroupBin = parseInt(permGroup).toString(2);

                    this.permissions.g_r = (permGroupBin.charAt(0) === "1");
                    this.permissions.g_w = (permGroupBin.charAt(1) === "1");
                    this.permissions.g_x = (permGroupBin.charAt(2) === "1");

                    // Outros
                    const permOthers = file.permissions.charAt(2);
                    const permOthersBin = parseInt(permOthers).toString(2);

                    this.permissions.o_r = (permOthersBin.charAt(0) === "1");
                    this.permissions.o_w = (permOthersBin.charAt(1) === "1");
                    this.permissions.o_x = (permOthersBin.charAt(2) === "1");
                }

                jQuery(this.$refs.modalChangePermissions).modal("show");
            },

            /**
             * Atualizar valor das permissões em formato numérico.
             */
            updateNumericPermissions()
            {
                this.$nextTick(() => {
                    const permUserBin   = (+this.permissions.u_r).toString() + (+this.permissions.u_w).toString() + (+this.permissions.u_x).toString();
                    const permGroupBin  = (+this.permissions.g_r).toString() + (+this.permissions.g_w).toString() + (+this.permissions.g_x).toString();
                    const permOthersBin = (+this.permissions.o_r).toString() + (+this.permissions.o_w).toString() + (+this.permissions.o_x).toString();

                    console.log(permUserBin, permGroupBin, permOthersBin);

                    const permNumeric = parseInt(permUserBin, 2).toString() + parseInt(permGroupBin, 2).toString() +parseInt(permOthersBin, 2).toString();

                    this.$set(this.permissions, "numeric", permNumeric);
                });
            },

            /**
             *
             */
            changePermissions()
            {
                this.errors = {};

                this.$refs.submitChangePermissions.$el.focus();
                this.$refs.submitChangePermissions.setLoading();
                this.$refs.cancelChangePermissions.disabled = true;

                let data = {
                    file: this.permissions.file,
                    permissions: this.permissions.numeric,
                };

                axios.post(`/api/hosting/${this.resource}/files/permissions`, data)
                    .then((response) => {
                        jQuery(this.$refs.modalChangePermissions).modal("hide");
                        this.fetchFiles(this.currentPath);

                        setTimeout(() => {
                            this.permissions = {};
                            this.$refs.submitChangePermissions.setLoading(false);
                            this.$refs.cancelChangePermissions.disabled = false;
                        }, 300);
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, this);
                        this.$refs.submitChangePermissions.setLoading(false);
                        this.$refs.cancelChangePermissions.disabled = false;
                    });
            },
        }
    }
</script>
