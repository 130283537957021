<template>
    <div>
        <card-default :title="$route.meta.title" icon="fas fa-undo">
            <p class="mb-4">
                Utilize esta funcionalidade para restaurar um backup de sua hospedagem realizado pelo sistema.
                Todos os seus arquivos, bancos de dados, contas e mensagens de e-mails serão restaurados para a data em
                que o backup foi realizado.
            </p>

            <div class="card card-table">
                <table class="table" v-bind:class="[backups.length ? 'table-hover' : '']">
                    <thead>
                        <tr>
                            <th scope="col">Data</th>
                            <th scope="col" class="col-options"><span class="sr-only">Opções</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="isLoading">
                            <td colspan="2">
                                <loading-message></loading-message>
                            </td>
                        </tr>

                        <tr v-else-if="!isLoading && !backups.length">
                            <td colspan="2">
                                Nenhum backup encontrado.
                            </td>
                        </tr>

                        <tr v-else v-for="item in backups">
                            <td>
                                {{ item.date | moment("DD/MM/YYYY") }}
                                <span v-if="item.date == $moment().format('YYYY-MM-DD')" class="text-muted pl-2">hoje</span>
                                <span v-else class="text-muted pl-2">
                                    {{ item.date | moment("from", $moment().startOf('day')) }}
                                </span>
                            </td>
                            <td class="col-options">
                                <button type="button" class="btn btn-link py-0" @click="openConfirmRestoreModal(item.date)">Restaurar</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </card-default>

        <modal-confirm ref="modalConfirmRestore" title="Restaurar hospedagem" confirm-text="Restaurar" @confirm="restore">
            <p>
                Tem certeza que deseja restaurar o backup do dia <strong>{{ restoreDate | moment("DD/MM/YYYY") }}</strong>?
            </p>
            <div class="alert alert-warning mt-3 mb-0">
                <strong>Atenção:</strong> Ao restaurar um backup todos os arquivos, informações no banco de dados e
                mensagens de e-mail criados após a data do backup serão perdidos.
            </div>
        </modal-confirm>
    </div>
</template>

<script>
    import CardDefault    from '../../components/CardDefault'
    import FormControl    from '../../components/FormControl'
    import LoadingMessage from '../../components/LoadingMessage'
    import ModalConfirm   from '../../components/ModalConfirm'

    export default {
        props: ['resource'],
        data() {
            return {
                backups: [],
                restoreDate: "",
                errors: {},
                isLoading: false,
            }
        },
        components: {
            CardDefault,
            FormControl,
            LoadingMessage,
            ModalConfirm,
        },
        mounted()
        {
            this.fetch();
        },
        methods:
        {
            /**
             * Listar datas com backups disponíveis.
             */
            fetch()
            {
                this.isLoading = true;
                this.restoreDate = "";

                return axios.get(`/api/hosting/${this.resource}/backup`)
                    .then ((response) => this.backups = response.data.data)
                    .catch((error) => HandleErrors.formError(error, this))
                    .then (() => this.isLoading = false);
            },

            openConfirmRestoreModal(date)
            {
                this.restoreDate = date;
                $(this.$refs.modalConfirmRestore.$el).modal("show");
            },

            restore()
            {
                $(this.$refs.modalConfirmRestore.$el).modal("hide");

                Loading.show();

                axios.post(`/api/hosting/${this.resource}/backup/restore`, { date: this.restoreDate })
                    .then ((response) => {
                        window.location.href = "/hosting/" + this.resource;
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, this);
                        Loading.hide();
                    });
            },
        },
    }
</script>