<template>
    <div class="btn-toolbar" role="toolbar">
        <div class="flex-grow-1 mb-0">
            <input
                    type="password"
                    class="form-control"
                    v-bind:class="{ 'input-rounded': rounded }"
                    :id="id"
                    v-model="password"
                    :autocomplete="autocomplete"
                    :placeholder="placeholder"
                    v-show="!showPassword">

            <input
                    type="text"
                    class="form-control input-password"
                    v-bind:class="{ 'input-rounded': rounded }"
                    ref="helper"
                    v-model="password"
                    v-show="showPassword"
                    autocorrect="off"
                    spellcheck="false">
        </div>

        <div class="btn-group ml-2" role="group">
            <button type="button"
                    class="btn btn-outline-primary"
                    v-bind:class="{ 'btn-rounded-left': rounded }"
                    @click="viewPassword">{{ showPassword ? 'Ocultar' : 'Ver' }}</button>

            <button type="button"
                    class="btn btn-outline-primary ml-1"
                    v-bind:class="{ 'btn-rounded-right': rounded }"
                    @click="generatePassword">Gerar</button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: [String, Number],
            id: {
                type: [String, Number],
                default: ""
            },
            autocomplete: {
                type: String,
                default: ""
            },
            placeholder: {
                type: String,
                default: ""
            },
            rounded: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                password: this.value,
                showPassword: false,
                passwordGenerator: null,
            }
        },
        mounted()
        {
            let vm = this;

            this.passwordGenerator = require('generate-password');

            $(this.$refs.helper).on("blur", function() {
                vm.showPassword = false;
            });
        },
        methods: {
            generatePassword()
            {
                let password = this.passwordGenerator.generate({
                    length: 16,
                    numbers: true,
                    uppercase: true,
                    symbols: true,
                    excludeSimilarCharacters: true,
                    exclude: '{}[]()/\\\'"`~,;:.<>',
                    strict: true,
                });

                this.password = password;
                this.showPassword = true;

                this.$nextTick(() => {
                    $(this.$refs.helper).focus();
                });

                this.$emit("input", password);
                this.$emit("generated", password);
            },
            viewPassword()
            {
                this.showPassword = !this.showPassword;
            }
        },
        watch: {
            value: function(val) {
                this.password = val;
            },
            password: function(val) {
                this.$emit("input", val);
            }
        }
    }
</script>
