
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue          from 'vue'
import router       from './router'
import VueCookie    from 'vue-cookie'
import BootstrapVue from 'bootstrap-vue'
import VueScrollbar from 'vue2-scrollbar'
import VueTheMask   from 'vue-the-mask'

// Moment.js
const moment = require('moment');
const VueMoment = require('vue-moment');
import 'moment/locale/pt-br'

import creditCardType from 'credit-card-type';

import Loading       from './services/LoadingService.js'
import HandleErrors  from './services/HandleErrors.js'
import ButtonSubmit  from './components/ButtonSubmit.vue'
import TableRowLink  from './components/TableRowLink.vue'

import AppDashboard from './pages/app/AppDashboard'
import AppTopbar    from './pages/app/AppTopbar'


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.config.productionTip = false;

Vue.prototype.$eventBus = new Vue();

window.Vue = Vue;
window.Loading = Loading;
window.HandleErrors = HandleErrors;
window.creditCardType = creditCardType;

Vue.use(VueCookie);
Vue.use(BootstrapVue);
Vue.use(VueTheMask);
Vue.use(VueMoment, { moment });

Vue.component('tr-link',       TableRowLink);
Vue.component('button-submit', ButtonSubmit);
Vue.component('vue-scrollbar', VueScrollbar);

Vue.component('app-dashboard', AppDashboard);
Vue.component('app-topbar',    AppTopbar);

Vue.mixin({
    methods: {
        routeBeforeEnter: function (el) {
            el.style.display = 'none';
        },
        routeEnter: function (el, done) {
            setTimeout(() => { el.style.display = 'block'; }, 500);
            setTimeout(() => { done(); }, 1000);
        },
        routeLeave: function (el, done) {
            setTimeout(() => { done(); }, 500);
        },
        isMac: function () {
            return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
        },
    }
});


/**
 * Store pattern
 */
var Store = {};

window.Store = Store;


/**
 * https://github.com/lian-yue/vue-upload-component/issues/152
 */
Vue.filter('formatSize', function (size, decimals, optionalDecimals)
{
    const _decimals = typeof decimals !== "undefined" ? parseInt(decimals) : 2;
    const _optional = !(typeof optionalDecimals !== "undefined" && optionalDecimals === false);

    let _value = "";
    let _unit = "";

    if (size > 1024 * 1024 * 1024 * 1024) {
        _value = (size / 1024 / 1024 / 1024 / 1024);
        _unit = ' TB';
    } else if (size > 1024 * 1024 * 1024) {
        _value = (size / 1024 / 1024 / 1024);
        _unit = ' GB';
    } else if (size > 1024 * 1024) {
        _value = (size / 1024 / 1024);
        _unit = ' MB';
    } else if (size > 1024) {
        _value = (size / 1024);
        _unit = ' KB';
    } else {
        _value = size;
        _unit = ' B'
    }

    if (!_optional || (_value - Math.floor(_value))) {
        _value = _value.toFixed(_decimals);
    }
    else {
        _value = _value.toFixed(0);
    }

    _value = _value.replace(".", ",");

    return _value + _unit;
});


/**
 * https://gist.github.com/james2doyle/4aba55c22f084800c199
 * Usage: {{ file.size | filesize }}
 */
Vue.filter('filesize', function (num) {
    if (typeof num !== 'number' || isNaN(num)) {
        throw new TypeError('Expected a number');
    }

    var exponent;
    var unit;
    var neg = num < 0;
    var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    if (neg) {
        num = -num;
    }

    if (num < 1) {
        return (neg ? '-' : '') + num + ' B';
    }

    exponent = Math.min(Math.floor(Math.log(num) / Math.log(1024)), units.length - 1);
    num = Math.round(num / Math.pow(1024, exponent));
    unit = units[exponent];

    return (neg ? '-' : '') + num + ' ' + unit;
});


/**
 * Diretivas
 */
Vue.directive("modal-animated", {
    inserted: function (el) {
        if (!$(el).hasClass("modal") && !$(el).find(".modal").length) return;

        let $el = $(el).hasClass("modal") ? $(el) : $(el).find(".modal").first();

        $el.on('show.bs.modal', function() {
            $el.find('.modal-dialog').removeClass("zoomIn flipOutX").addClass("zoomIn animated");
        });

        $el.on('hide.bs.modal', function() {
            $el.find('.modal-dialog').removeClass("zoomIn flipOutX").addClass("flipOutX animated");
        });
    }
});

Vue.directive("input-group-focus", {
    inserted: function (el) {
        if (!$(el).hasClass("input-group")) return;

        $(el).addClass("input-group-focus");

        $(el).find(".form-control")
            .on("focus", function() {
                $(el).addClass("is-focused");
            })
            .on("blur", function() {
                $(el).removeClass("is-focused");
            });
    }
});

Vue.directive("only-digits", {
    inserted: function (el) {
        $(el).on("keypress", function(evt) {
            evt = (evt) ? evt : window.event;
            let charCode = (evt.which) ? evt.which : evt.keyCode;

            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            }
            else {
                return true;
            }
        });
    }
});

Vue.directive("inputLowercase", {
    bind(el, binding, vnode) {
        let handlerInput = (e) => {
            e.target.value = e.target.value.toLowerCase();
            e.target.dispatchEvent(new CustomEvent('input'));
        };

        el.addEventListener('keyup', handlerInput);
    }
});

Vue.directive("inputUppercase", {
    bind(el, binding, vnode) {
        let handlerInput = (e) => {
            e.target.value = e.target.value.toUpperCase();
            e.target.dispatchEvent(new CustomEvent('input'));
        };

        el.addEventListener('keyup', handlerInput);
    }
});


/**
 * Configurações nas rotas
 */
router.beforeEach((to, from, next) =>
{
    // Atualizar título da página
    const pageTitleMeta = to.matched.find(r => r.meta && r.meta.title);

    document.title = pageTitleMeta ? pageTitleMeta.meta.title : "Painel de hospedagem";

    // Verificar token e rota
    let accessToken = "";

    try { accessToken = Vue.cookie.get("access_token"); }
    catch(e) {}

    if (!accessToken && !to.matched.some(r => r.meta && r.meta.guest)) {
        next({ path: "/401", replace: true });
    }
    else {
        next();
    }
});


/**
 * Interceptores para Ajax
 */
window.axios.interceptors.request.use(
    function (config) {
        const access_token = Vue.cookie.get('access_token');

        if (access_token) {
            config.headers.Authorization = `Bearer ${access_token}`;
        }

        return config;
    },
    function(error) {
        return Promise.reject(error);
    });

window.axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response) {
            if (error.response.status === 401)
            {
                Vue.cookie.delete('access_token');

                router.push({ path: "/401", replace: true });

                alert('Não autorizado.');
            }
            else if (error.response.status === 403)
            {
                router.push({ path: "/403", replace: true });

                alert('Você não possui permissão para fazer isso!');
            }
        }

        return Promise.reject(error);
    });


/**
 * Instanciar aplicação
 */
new Vue({ router }).$mount('#app');
