<template>
    <div>
        <card-default v-show="!creating && !editing" :title="$route.meta.title" icon="far fa-clock">
            <template slot="buttons">
                <button type="button" class="btn btn-primary btn-rounded" @click="showCreateForm">Adicionar tarefa</button>
            </template>

            <p class="mb-4">
                Agende tarefas para serem executadas de forma automática em tempos pré-configurados.
            </p>

            <div class="card card-table mb-0">
                <table class="table" v-bind:class="[tasks.length ? 'table-hover' : '']">
                    <thead>
                        <tr>
                            <th scope="col" class="col-150">Intervalo</th>
                            <th scope="col">Comando</th>
                            <th scope="col"><span class="sr-only">Opções</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="loading">
                            <td colspan="3">
                                <loading-message></loading-message>
                            </td>
                        </tr>
                        <tr v-else-if="!loading && !tasks.length">
                            <td colspan="3">Nenhuma tarefa encontrada</td>
                        </tr>
                        <template v-else v-for="(item, index) in tasks">
                            <tr>
                                <td class="col-150">{{ item.expression }}</td>
                                <td class="text-monospace">{{ item.command }}</td>
                                <td class="col-100 col-nowrap text-right py-0 align-middle">
                                    <button type="button" class="btn btn-sm btn-link" title="Editar" @click="showEditForm(index)">
                                        <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                                        <span class="sr-only">Editar</span>
                                    </button>

                                    <button type="button" class="btn btn-sm btn-link text-danger" title="Excluir" @click="remove(index)">
                                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                                        <span class="sr-only">Excluir</span>
                                    </button>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </card-default>

        <!--
        <card-default v-show="!creating && !editing">
            <div class="d-flex justify-content-between align-items-center">
                <div class="flex-grow-1">
                    <h6 class="card-subtitle mt-0">Notificações por e-mail</h6>
                    <p class="mb-0">
                        Permite que você receba um e-mail com os dados de saída das tarefas quando forem executadas.
                    </p>
                </div>

                <div v-show="isLoadingNotifications" class="pl-3">
                    <i class="d-block fas fa-circle-notch fa-spin"></i>
                </div>

                <div class="pl-3">
                    <span class="switch switch-sm">
                        <input
                            type="checkbox"
                            class="switch"
                            id="cronNotifications"
                            v-model="notifications"
                            @change="updateNotifications"
                            :disabled="isLoadingNotifications">
                        <label for="cronNotifications" class="mb-0"></label>
                    </span>
                </div>
            </div>
        </card-default>
        -->

        <card-default v-show="creating || editing" :title="creating ? 'Adicionar uma tarefa' : 'Editar tarefa'">
            <form accept-charset="UTF-8" @submit.prevent="save">
                <form-control :error="errors.interval">
                    <label class="form-label pl-2" for="interval">Intervalo de execução</label>
                    <select id="interval" class="form-control custom-select input-rounded" v-model="task.interval">
                        <option value="5">A cada 5 minutos</option>
                        <option value="10">A cada 10 minutos</option>
                        <option value="30">A cada 30 minutos</option>
                        <option value="60">A cada 1 hora</option>
                        <option value="D">Diariamente</option>
                        <option value="S">Semanalmente</option>
                        <option value="M">Mensalmente</option>
                        <option value="P">Personalizado</option>
                    </select>
                </form-control>

                <form-control v-show="task.interval === 'S'" :error="errors.dayWeek">
                    <label class="form-label pl-2" for="dayWeek">Dia da semana</label>
                    <select id="dayWeek" class="form-control custom-select input-rounded" v-model="task.dayWeek">
                        <option value="1">Segunda-feira</option>
                        <option value="2">Terça-feira</option>
                        <option value="3">Quarta-feira</option>
                        <option value="4">Quinta-feira</option>
                        <option value="5">Sexta-feira</option>
                        <option value="6">Sábado</option>
                        <option value="0">Domingo</option>
                    </select>
                </form-control>

                <form-control v-show="task.interval === 'M'" :error="errors.dayMonth">
                    <label class="form-label pl-2" for="dayMonth">Dia do mês</label>
                    <input type="text" id="dayMonth" class="form-control input-rounded" v-model="task.dayMonth" v-mask="'##'" placeholder="12">
                </form-control>

                <form-control v-show="task.interval === 'D' || task.interval === 'S' || task.interval === 'M'" :error="errors.time">
                    <label class="form-label pl-2" for="time">Horário</label>
                    <input type="text" id="time" class="form-control input-rounded" v-model="task.time" v-mask="'##:##'" placeholder="00:00">
                </form-control>

                <form-control v-show="task.interval === 'P'" :error="errors.expression">
                    <label class="form-label pl-2" for="expression">Intervalo no padrão Cron</label>
                    <p class="form-text text-muted pl-2">
                        Formato: &#x3C;minuto&#x3E; &#x3C;hora&#x3E; &#x3C;dia-do-mes&#x3E; &#x3C;mes&#x3E; &#x3C;dia-da-semana&#x3E;
                    </p>
                    <input type="text" id="expression" class="form-control input-rounded" v-model="task.expression" placeholder="* * * * *">
                </form-control>

                <form-control :error="errors.command">
                    <label class="form-label pl-2" for="command">Comando</label>
                    <input type="text" id="command" class="form-control input-rounded text-monospace" v-model="task.command">
                </form-control>

                <div class="form-buttons">
                    <button-submit ref="submit" class="btn-rounded">Salvar</button-submit>
                    <button type="button" ref="cancel" class="btn btn-secondary btn-rounded" @click="cancelForm">Cancelar</button>
                </div>
            </form>
        </card-default>
    </div>
</template>

<script>
    import CardDefault from '../../components/CardDefault'
    import FormControl from '../../components/FormControl'
    import LoadingMessage from '../../components/LoadingMessage'

    export default {
        props: ['resource'],
        data() {
            return {
                tasks: [],
                task: {
                    interval: "5",
                    dayWeek: 1,
                },
                //notifications: false,
                errors: {},
                creating: false,
                editing: false,

                loading: false,
                //isLoadingNotifications: false,
            }
        },
        components: {
            CardDefault,
            FormControl,
            LoadingMessage,
        },
        mounted() {
            this.fetch();
        },
        methods: {
            fetch()
            {
                this.loading = true;
                //this.isLoadingNotifications = true;

                return axios.get(`/api/hosting/${this.resource}/cron`)
                    .then ((response) => {
                        this.tasks = response.data.data.tasks;
                        //this.notifications = response.data.data.notifications;
                    })
                    .catch((error) => HandleErrors.formError(error, this))
                    .then (() => {
                        this.loading = false;
                        //this.isLoadingNotifications = false;
                    });
            },
            save()
            {
                let vm = this;
                this.errors = {};

                this.$refs.submit.setLoading();
                this.$refs.cancel.disabled = true;

                let postData = {
                    url: `/api/hosting/${this.resource}/cron`,
                    method: "post",
                    data: this.task,
                };

                if (this.editing) {
                    postData.url += "/" + this.task.id;
                    postData.method = "put";
                }

                axios(postData)
                    .then((response) => {
                        this.cancelForm();
                        this.fetch();
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, vm);
                    })
                    .then (() => {
                        this.$refs.cancel.disabled = false;
                        this.$refs.submit.setLoading(false);
                    });
            },
            remove(index)
            {
                if (!confirm('Tem certeza que deseja remover esta tarefa?'))
                    return;

                let id = this.tasks[index].id;

                Loading.show();

                setTimeout(() => {
                    this.tasks.splice(index, 1);
                    Loading.hide();
                }, 2000);

                axios.delete(`/api/hosting/${this.resource}/cron/${id}`)
                    .then ((response) => {})
                    .catch((error) => {
                        alert(`Ocorreu um erro ao tentar excluir a tarefa.
                               Por favor, atualize sua página e tente novamente.`)
                    });
            },

            /**
             * Atualizar status das notificações por e-mail.
             */
            // updateNotifications()
            // {
            //     this.isLoadingNotifications = true;
            //
            //     axios.post(`/api/hosting/${this.resource}/cron/email`, { 'active': this.notifications })
            //         .then ((response) => this.notifications = response.data.data.active)
            //         .catch((error) => HandleErrors.formError(error, this))
            //         .then (() => this.isLoadingNotifications = false);
            // },

            showCreateForm()
            {
                this.task     = { interval: "5", dayWeek: 1 };
                this.creating = true;
                this.editing  = false;
            },
            showEditForm(index)
            {
                this.task     = $.extend({}, this.tasks[index]);
                this.creating = false;
                this.editing  = true;
            },
            cancelForm()
            {
                this.creating = false;
                this.editing  = false;
                this.task     = {};
            },
        }
    }
</script>
