<template>
    <div>
        <card-default :title="$route.meta.title" icon="far fa-folder-open">
            <template slot="buttons">
                <button type="button" class="btn btn-primary btn-rounded" @click="openChangePasswordModal()">Alterar senha</button>
                <button type="button" class="btn btn-secondary btn-rounded ml-1" data-toggle="modal" data-target="#modalAddUser">Adicionar usuário</button>
            </template>

            <p class="mb-4">
                O protocolo FTP permite que você gerencie os arquivos de sua hospedagem através de um software como o <strong>FileZilla</strong>
                <a href="https://filezilla-project.org/download.php" target="_blank"><i class="fas fa-external-link-alt" aria-hidden="true"></i></a>.
                É possível também adicionar outros usuários com acesso apenas a pastas específicas.
            </p>

            <div v-if="isLoading" class="text-center py-4">
                <loading-message>Carregando as informações de FTP&hellip;</loading-message>
            </div>

            <div v-else>
                <div v-if="ftp.main">
                    <div class="col-xl-8 px-0">
                        <div class="row">
                            <div class="col-xl-8">
                                <div class="form-group">
                                    <label class="form-label">Host:</label>
                                    <input type="text" class="form-control input-rounded" v-model="ftp.main.host" readonly @click="selectInput($event)" aria-readonly="true">
                                </div>
                            </div>

                            <div class="col-xl-4">
                                <div class="form-group">
                                    <label class="form-label">Porta:</label>
                                    <input type="text" class="form-control input-rounded" v-model="ftp.main.port" readonly @click="selectInput($event)" aria-readonly="true">
                                </div>
                            </div>

                            <div class="col-xl-8">
                                <div class="form-group">
                                    <label class="form-label">Usuário:</label>
                                    <input type="text" class="form-control input-rounded" v-model="ftp.main.username" readonly @click="selectInput($event)" aria-readonly="true">
                                </div>
                            </div>

                            <div class="col-xl-4">
                                <div class="form-group">
                                    <label class="form-label">Senha:</label>

                                    <div class="input-group input-group-rounded" v-show="!showFtpPassword">
                                        <input type="password" class="form-control" v-model="ftp.main.password" readonly aria-readonly="true">
                                        <div class="input-group-append">
                                            <button type="button" class="btn btn-outline-secondary btn-rounded-right pr-3" title="Exibir senha" @click="showFtpPassword = true">
                                                <i class="fas fa-eye" aria-hidden="true" style="font-size:1.5em;"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="input-group input-group-rounded" v-show="showFtpPassword">
                                        <input type="text" class="form-control" v-model="ftp.main.password" readonly @click="selectInput($event)" aria-readonly="true">
                                        <div class="input-group-append">
                                            <button type="button" class="btn btn-outline-secondary btn-rounded-right pr-3" title="Ocultar senha" @click="showFtpPassword = false">
                                                <i class="fas fa-eye-slash" aria-hidden="true" style="font-size:1.5em;"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--
                    <table class="table table-ftp" >
                        <tbody>
                            <tr class="copy-text-wrapper">
                                <th class="border-top-0 pt-0 pl-1">Host:</th>
                                <td class="border-top-0 pt-0">
                                    <text-copy :text="ftp.main.host" />
                                </td>
                            </tr>
                            <tr class="copy-text-wrapper">
                                <th class="pl-1">Porta:</th>
                                <td>
                                    <text-copy :text="ftp.main.port" />
                                </td>
                            </tr>
                            <tr class="copy-text-wrapper">
                                <th class="pl-1">Usuário:</th>
                                <td>
                                    <text-copy :text="ftp.main.username" />
                                </td>
                            </tr>
                            <tr class="copy-text-wrapper">
                                <th class="pl-1">Senha:</th>
                                <td>
                                    <text-copy :text="'*'.repeat(ftp.main.password.length)" :copy="ftp.main.password" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    -->

                    <div class="alert alert-success" role="alert" v-show="passwordChanged">
                        A senha do FTP foi alterada com sucesso!
                    </div>
                </div>

                <div v-if="ftp.sub && ftp.sub.length" class="mt-5">
                    <h5 class="card-subtitle">Contas adicionais</h5>

                    <div class="card card-table">
                        <table class="table table-ftp-sub">
                            <thead class="thead-sm">
                                <tr>
                                    <th class="col-username">Usuário</th>
                                    <th class="col-password"></th>
                                    <th class="col-homedir">Diretório</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(item, index) in ftp.sub">
                                    <tr v-if="!item.deleted" v-bind:class="[item.deleting ? 'table-warning' : '']">
                                        <td>{{ item.username }}</td>
                                        <td></td>
                                        <td>{{ item.homedir }}</td>
                                        <td class="col-100 col-nowrap text-right py-0 align-middle">
                                            <button type="button" class="btn btn-sm btn-link" title="Alterar senha" @click="openChangePasswordModal(item.username)">
                                                <i class="fas fa-key" aria-hidden="true"></i>
                                                <span class="sr-only">Alterar senha</span>
                                            </button>
                                            <button type="button" class="btn btn-sm btn-link text-danger" title="Excluir" @click="deleteUser(index, $event)">
                                                <i class="far fa-trash-alt" aria-hidden="true"></i>
                                                <span class="sr-only">Excluir</span>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr v-if="!item.deleted && item.deleting" class="table-warning">
                                        <td class="border-top-0 pt-0" colspan="4">
                                            <div>
                                                <p class="mb-2">
                                                    Tem certeza que deseja excluir este usuário?
                                                </p>
                                                <p class="mb-0">
                                                    <button type="button" class="btn btn-danger" @click="confirmDeleteUser(index, $event)">Excluir</button>
                                                    <button type="button" class="btn btn-secondary btn-cancel" @click="cancelDeleteUser(index, $event)">Cancelar</button>
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </card-default>

        <div class="modal fade" id="modalNewPassword" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <form accept-charset="UTF-8" @submit.prevent="changePassword">
                        <div class="modal-body">
                            <div v-show="password.username" class="form-group">
                                <label for="newPasswordUser">Usuário</label>
                                <input type="text" id="newPasswordUser" class="form-control input-rounded" :value="password.username" readonly>
                            </div>

                            <form-control :error="errors.password">
                                <label for="newPassword">Nova senha</label>
                                <password-generator id="newPassword" v-model="password.password" autocomplete="new-password" rounded />
                                <!--<div class="input-group">
                                    <input type="password" id="newPassword" class="form-control" v-model="password.password" autocomplete="new-password" v-show="!showNewPassword">
                                    <input type="text" class="form-control input-password" v-model="password.password" v-show="showNewPassword">
                                    <div class="input-group-append">
                                        <button type="button" class="btn btn-primary" @click="generateNewPassword">Gerar</button>
                                    </div>
                                </div>-->
                            </form-control>

                            <form-control class="mb-0" :error="errors.passwordConfirm">
                                <label for="newPasswordConfirm">Confirmação de senha</label>
                                <input type="password" id="newPasswordConfirm" class="form-control input-rounded" v-model="password.passwordConfirm" autocomplete="new-password">
                            </form-control>
                        </div>

                        <footer class="modal-footer justify-content-start">
                            <button-submit ref="submitNewPassword" class="btn-rounded">Salvar</button-submit>
                            <button type="button" ref="cancelNewPassword" class="btn btn-secondary btn-rounded" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modalAddUser" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <form accept-charset="UTF-8" @submit.prevent="addUser">
                        <div class="modal-body">
                            <form-control :error="errors.username">
                                <label for="username">Usuário</label>
                                <div class="input-group input-group-rounded" v-input-group-focus>
                                    <input type="text" id="username" class="form-control" v-model="user.username">
                                    <div class="input-group-append">
                                        <span class="input-group-text">{{ '@' + ftp.domain }}</span>
                                    </div>
                                </div>
                            </form-control>

                            <form-control :error="errors.password">
                                <label for="userPassword">Senha</label>
                                <password-generator id="userPassword" v-model="user.password" autocomplete="new-password" rounded />
                                <!--<div class="input-group">
                                    <input type="password" id="userPassword" class="form-control" v-model="user.password" autocomplete="new-password" v-show="!showUserPassword">
                                    <input type="text" class="form-control input-password" v-model="user.password" v-show="showUserPassword">
                                    <div class="input-group-append">
                                        <button type="button" class="btn btn-primary" @click="generateUserPassword">Gerar</button>
                                    </div>
                                </div>-->
                            </form-control>

                            <form-control class="mb-0" :error="errors.homedir">
                                <label for="homedir">Diretório</label>
                                <div class="input-group input-group-rounded" v-input-group-focus>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text px-4">/</span>
                                    </div>
                                    <input type="text" id="homedir" class="form-control" v-model="user.homedir">
                                </div>
                            </form-control>
                        </div>

                        <footer class="modal-footer justify-content-start">
                            <button-submit ref="submitAddUser" class="btn-rounded">Salvar</button-submit>
                            <button type="button" ref="cancelAddUser" class="btn btn-secondary btn-rounded ml-1" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import CardDefault       from '../../components/CardDefault'
    import FormControl       from '../../components/FormControl'
    import TextCopy          from '../../components/TextCopy'
    import PasswordGenerator from '../../components/PasswordGenerator'
    import LoadingMessage    from '../../components/LoadingMessage'

    export default {
        props: ['resource'],
        data() {
            return {
                ftp: {},
                password: {},
                user: {},
                passwordChanged: false,
                errors: {},
                generator: null,
                showFtpPassword: false,
                showNewPassword: false,
                showUserPassword: false,

                isLoading: false,
            }
        },
        components: {
            CardDefault,
            FormControl,
            TextCopy,
            PasswordGenerator,
            LoadingMessage,
        },
        mounted() {
            let vm = this;

            $("#modalNewPassword, #modalAddUser")
                .on("show.bs.modal", function() {
                    vm.user     = {};
                    vm.errors   = {};
                    vm.showNewPassword  = false;
                    vm.showUserPassword = false;
                })
                .on("shown.bs.modal", function() {
                    $(this).find("input:not([readonly])").get(0).focus();
                });

            $(".input-password").on("blur", function() {
                vm.showNewPassword  = false;
                vm.showUserPassword = false;
            });

            this.fetch();
        },
        methods: {
            fetch()
            {
                this.isLoading = true;

                return axios.get(`/api/hosting/${this.resource}/ftp`)
                    .then ((response) => this.ftp = response.data.data)
                    .catch((error) => HandleErrors.formError(error))
                    .then (() => this.isLoading = false);
            },
            generateNewPassword()
            {
                let newPassword = this._generatePassword();

                this.$set(this.password, 'password', newPassword);
                this.$set(this.password, 'passwordConfirm', newPassword);

                this.showNewPassword = true;
            },
            generateUserPassword()
            {
                let newPassword = this._generatePassword();

                this.$set(this.user, 'password', newPassword);
                this.showUserPassword = true;
            },
            _generatePassword()
            {
                if (!this.generator) {
                    this.generator = require('generate-password');
                }

                let generatedPassword = this.generator.generate({
                    length: 12,
                    numbers: true,
                    uppercase: true,
                    symbols: true,
                    strict: true,
                });

                return generatedPassword;
            },
            openChangePasswordModal(username)
            {
                this.password = {
                    'username': (typeof username !== 'undefined' ? username : '')
                };

                $("#modalNewPassword").modal("show");
            },
            changePassword()
            {
                let vm = this;
                this.errors = {};

                this.$refs.submitNewPassword.$el.focus();
                this.$refs.submitNewPassword.setLoading();
                this.$refs.cancelNewPassword.disabled = true;

                axios.post(`/api/hosting/${this.resource}/ftp/password`, this.password)
                    .then((response) => {
                        this.fetch()
                            .then(() => {
                                this.passwordChanged = true;
                                setTimeout(() => this.passwordChanged = false, 3000);
                            });

                        $("#modalNewPassword").modal("hide");

                        setTimeout(() => {
                            this.password = {};
                            this.errors = {};

                            this.$refs.cancelNewPassword.disabled = false;
                            this.$refs.submitNewPassword.setLoading(false);

                        }, 300);
                    })
                    .catch((response) => {
                        HandleErrors.formError(response, vm);
                        this.$refs.cancelNewPassword.disabled = false;
                        this.$refs.submitNewPassword.setLoading(false);
                    });
            },
            addUser()
            {
                let vm = this;
                this.errors = {};

                this.$refs.submitAddUser.$el.focus();
                this.$refs.submitAddUser.setLoading();
                this.$refs.cancelAddUser.disabled = true;

                axios.post(`/api/hosting/${this.resource}/ftp/add-user`, this.user)
                    .then((response) => {
                        this.fetch();

                        $("#modalAddUser").modal("hide");

                        setTimeout(() => {
                            this.user = {};
                            this.errors = {};
                            this.$refs.cancelAddUser.disabled = false;
                            this.$refs.submitAddUser.setLoading(false);
                        }, 300);
                    })
                    .catch((response) => {
                        HandleErrors.formError(response, vm);
                        this.$refs.cancelAddUser.disabled = false;
                        this.$refs.submitAddUser.setLoading(false);
                    });
            },
            deleteUser(index, event)
            {
                this.ftp.sub.map((el, idx) => this.$set(this.ftp.sub[idx], 'deleting', false));
                this.$set(this.ftp.sub[index], 'deleting', true);

                $(event.target).closest("table").removeClass("table-hover");
            },
            confirmDeleteUser(index, event)
            {
                let username = this.ftp.sub[index].username;

                // Remover linha do usuário na tabela
                $(event.target).prop("disabled", true).addClass("btn-loading");
                setTimeout(() => this.ftp.sub.splice(index, 1), 500);

                // Excluir o usuário
                axios.post(`/api/hosting/${this.resource}/ftp/remove-user`, { 'username': username })
                    .then((response) => {})
                    .catch((response) => {
                        alert('Ocorreu um erro ao tentar remover o usuário de FTP. Por favor, atualize sua página e tente novamente.');
                    });
            },
            cancelDeleteUser(index, event)
            {
                this.$set(this.ftp.sub[index], 'deleting', false);
                $(event.target).closest("table").addClass("table-hover");
            },

            /**
             * Deixar o texto do input selecionado.
             */
            selectInput(event)
            {
                event.target.setSelectionRange(0, event.target.value.length);
            }
        },
        watch: {
            '$route.params.resource': function (resource) {
                this.ftp = {};
                this.fetch();
            }
        },
    }
</script>
