// services/LoadingService.js

let _loadingCounter = 0;

export default {

    show()
    {
        _loadingCounter++;

        $("#page-loader").show();
        setTimeout(() => { $("#page-loader").addClass("active"); }, 100);
    },

    hide()
    {
        _loadingCounter--;

        if (_loadingCounter <= 0) {
            $("#page-loader").removeClass("active");
            setTimeout(() => { $("#page-loader").hide(); }, 400);
        }
    }

}
