<template>
    <div>
        <card-default :title="$route.meta.title" icon="fas fa-ban fa-flip-horizontal">
            <div class="mb-4">
                <p>
                    O filtro antispam avalia as mensagens recebidas e enviadas através de sua hospedagem e classifica
                    mensagens indesejadas como spam. Aqui você pode controlar o funcionamento do filtro antispam em seu
                    domínio. Você também pode adicionar endereços de e-mail na blacklist ou whitelist.
                </p>
            </div>

            <div v-if="isLoading" class="text-center py-4">
                <loading-message>
                    Carregando informações do filtro antispam&hellip;
                </loading-message>
            </div>

            <div v-if="!isLoading && antispam" class="d-flex justify-content-between align-items-start">
                <div class="flex-grow-1 pt-1">
                    <h6 class="card-subtitle my-0">Habilitar antispam em todas as contas de e-mail</h6>
                </div>

                <div class="d-flex align-items-center">
                    <div v-show="isLoadingStatus" class="pl-3">
                        <i class="d-block fas fa-circle-notch fa-spin"></i>
                    </div>

                    <div class="pl-3">
                        <span class="switch switch-sm">
                            <input
                                    type="checkbox"
                                    class="switch"
                                    id="antispamStatus"
                                    v-model="antispam.status"
                                    @change="updateStatus"
                                    :disabled="isLoadingStatus">
                            <label for="antispamStatus" class="mb-0"></label>
                        </span>
                    </div>
                </div>
            </div>

            <!--
            <div v-if="!isLoading && antispam && antispam.level" class="d-flex justify-content-between align-items-start mt-3">
                <div class="flex-grow-1 pt-1">
                    <h6 class="card-subtitle mt-0 mb-1">Nível do filtro antispam</h6>
                    <p class="text-muted mb-0">
                        Controle o quanto o filtro antispam poderá bloquear mensagens suspeitas.
                    </p>
                </div>

                <div v-show="isLoadingLevel" class="pl-3">
                    <i class="d-block fas fa-circle-notch fa-spin"></i>
                </div>

                <div class="pl-3 align-middle text-right">
                    <span class="d-inline-block align-middle mr-1">
                        {{ antispam.level }}
                    </span>
                    <vue-slider
                        class="d-inline-block align-middle pr-0"
                        :value="antispam.level"
                        :min="1"
                        :max="5"
                        :interval="1"
                        :width="200"
                        :height="8"
                        :dotSize="22"
                        :tooltip="false"
                        :speed="0.1"
                        :use-keyboard="true"
                        @callback="val => antispam.level = val">
                    </vue-slider>
                </div>
            </div>
            -->
        </card-default>

        <card-default>
            <h5 class="card-subtitle">Blacklist</h5>

            <p class="mb-4">
                Nesta seção você pode adicionar ou remover endereços de e-mail que devem ser bloqueados pelo filtro
                antispam.
            </p>

            <div class="btn-toolbar mb-3" role="toolbar">
                <div class="input-group input-group-rounded" v-input-group-focus>
                    <div class="input-group-prepend">
                        <div class="input-group-text bg-white pr-0">
                            <i class="fas fa-search" aria-hidden="true"></i>
                        </div>
                    </div>
                    <input type="search" class="form-control bg-white border-left-0" v-model="filterBlacklist" :disabled="isLoading" placeholder="Buscar..." spellcheck="false">
                </div>
            </div>

            <div class="card card-table">
                <table class="table" v-bind:class="[antispam && filteredBlacklist.length ? 'table-hover' : '']">
                    <thead>
                        <tr>
                            <th scope="col">E-mail</th>
                            <th scope="col" class="col-options "><span class="sr-only">Opções</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="isLoading">
                            <td colspan="2">
                                <loading-message></loading-message>
                            </td>
                        </tr>

                        <tr v-else-if="!isLoading && !filteredBlacklist.length">
                            <td colspan="2">
                                Nenhum endereço de e-mail encontrado.
                            </td>
                        </tr>

                        <tr v-else v-for="(item, index) in filteredBlacklist" v-bind:class="{'table-active text-muted': item.loading}">
                            <td class="col-nowrap">
                                <i v-show="item.loading" class="fas fa-circle-notch fa-spin mr-1"></i>
                                {{ item.email }}
                            </td>
                            <td class="col-options">
                                <div class="btn-group visible-on-hover" role="group">
                                    <button type="button" class="btn btn-sm btn-outline-danger" title="Remover" @click="removeBlacklist(index)" :disabled="item.loading">
                                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                                        <span class="sr-only">Remover</span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <button type="button" class="btn btn-primary btn-rounded" @click="_openAddToListModal('blacklist')" :disabled="isLoadingBlacklist">Adicionar</button>
        </card-default>

        <card-default>
            <h5 class="card-subtitle">Whitelist</h5>

            <p class="mb-4">
                Nesta seção você pode adicionar ou remover endereços de e-mail que devem ser tratados como origens
                seguras pelo filtro antispam. Mensagens enviadas por endereços de e-mail presentes na lista abaixo não
                serão consideradas como spam.
            </p>

            <div class="btn-toolbar mb-3" role="toolbar">
                <div class="input-group input-group-rounded" v-input-group-focus>
                    <div class="input-group-prepend">
                        <div class="input-group-text bg-white pr-0">
                            <i class="fas fa-search" aria-hidden="true"></i>
                        </div>
                    </div>
                    <input type="search" class="form-control bg-white border-left-0" v-model="filterWhitelist" :disabled="isLoading" placeholder="Buscar..." spellcheck="false">
                </div>
            </div>

            <div class="card card-table">
                <table class="table" v-bind:class="[antispam && filteredWhitelist.length ? 'table-hover' : '']">
                    <thead>
                        <tr>
                            <th scope="col">E-mail</th>
                            <th scope="col" class="col-options "><span class="sr-only">Opções</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="isLoading">
                            <td colspan="2">
                                <loading-message></loading-message>
                            </td>
                        </tr>

                        <tr v-else-if="!isLoading && !filteredWhitelist.length">
                            <td colspan="2">
                                Nenhum endereço de e-mail encontrado.
                            </td>
                        </tr>

                        <tr v-else v-for="(item, index) in filteredWhitelist" v-bind:class="{'table-active text-muted': item.loading}">
                            <td class="col-nowrap">
                                <i v-show="item.loading" class="fas fa-circle-notch fa-spin mr-1"></i>
                                {{ item.email }}
                            </td>
                            <td class="col-options">
                                <div class="btn-group visible-on-hover" role="group">
                                    <button type="button" class="btn btn-sm btn-outline-danger" title="Remover" @click="removeWhitelist(index)" :disabled="item.loading">
                                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                                        <span class="sr-only">Remover</span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <button type="button" class="btn btn-primary btn-rounded" @click="_openAddToListModal('whitelist')" :disabled="isLoadingWhitelist">Adicionar</button>
        </card-default>

        <div class="modal fade" ref="modalAddBlacklist" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Adicionar endereço na blacklist</h5>
                    </header>
                    <form accept-charset="UTF-8" @submit.prevent="addBlacklist">
                        <div class="modal-body">
                            <div class="alert alert-info">
                                <p class="mb-0">
                                    Informe abaixo o endereço de e-mail que você deseja adicionar na blacklist.
                                    Também é possível inserir o endereço no formato  <strong>*@dominio.com.br</strong>
                                    para incluir todos os endereços de e-mail que pertencem a um mesmo domínio.
                                </p>
                            </div>

                            <form-control class="mb-0" :error="errors.email">
                                <label class="form-label ml-2" for="blacklistEmail">Endereço de e-mail</label>
                                <input type="text" id="blacklistEmail" class="form-control input-rounded" v-model="blacklist.email" v-input-lowercase spellcheck="false">
                            </form-control>
                        </div>

                        <footer class="modal-footer justify-content-start">
                            <button-submit ref="submitAddBlacklist" class="btn-rounded">Adicionar</button-submit>
                            <button type="button" ref="cancelAddBlacklist" class="btn btn-secondary btn-rounded" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" ref="modalAddWhitelist" tabindex="-1" role="dialog" aria-hidden="true" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Adicionar endereço na whitelist</h5>
                    </header>
                    <form accept-charset="UTF-8" @submit.prevent="addWhitelist">
                        <div class="modal-body">
                            <div class="alert alert-info">
                                <p class="mb-0">
                                    Informe abaixo o endereço de e-mail que você deseja adicionar na whitelist.
                                    Também é possível inserir o endereço no formato  <strong>*@dominio.com.br</strong>
                                    para incluir todos os endereços de e-mail que pertencem a um mesmo domínio.
                                </p>
                            </div>

                            <form-control class="mb-0" :error="errors.email">
                                <label class="form-label ml-2" for="whitelistEmail">Endereço de e-mail</label>
                                <input type="text" id="whitelistEmail" class="form-control input-rounded" v-model="whitelist.email" v-input-lowercase spellcheck="false">
                            </form-control>
                        </div>

                        <footer class="modal-footer justify-content-start">
                            <button-submit ref="submitAddWhitelist" class="btn-rounded">Adicionar</button-submit>
                            <button type="button" ref="cancelAddWhitelist" class="btn btn-secondary btn-rounded" data-dismiss="modal">Cancelar</button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CardDefault    from '../../components/CardDefault'
    import LoadingMessage from '../../components/LoadingMessage'
    import GlobalAlert    from '../../services/GlobalAlertService'
    import FormControl    from '../../components/FormControl'
    import vueSlider      from 'vue-slider-component'

    export default {
        props: ['resource'],
        data() {
            return {
                isLoading: false,
                isLoadingStatus: false,
                // isLoadingLevel: false,
                antispam: null,

                filterBlacklist: "",
                filteredBlacklist: [],
                blacklist: {},
                isLoadingBlacklist: false,

                filterWhitelist: "",
                filteredWhitelist: [],
                whitelist: {},
                isLoadingWhitelist: false,

                errors: {},
            }
        },
        components: {
            CardDefault,
            LoadingMessage,
            FormControl,
            vueSlider,
        },
        mounted()
        {
            this.fetch();
        },
        methods: {
            /**
             * Obter dados do antispam.
             */
            fetch()
            {
                this.isLoading = true;

                axios.get(`/api/hosting/${this.resource}/spam`)
                    .then ((response) => {
                        this.antispam = response.data.data;

                        this.antispam.blacklist.forEach((el, idx) => {
                            this.antispam.blacklist[idx] = { "email": el, "loading": false };
                        });

                        this.antispam.whitelist.forEach((el, idx) => {
                            this.antispam.whitelist[idx] = { "email": el, "loading": false };
                        });

                        this.filteredBlacklist = this.antispam.blacklist.slice(0);
                        this.filteredWhitelist = this.antispam.whitelist.slice(0);
                    })
                    .catch((error) => HandleErrors.ajaxErrorAlert(error))
                    .then (() => this.isLoading = false);
            },

            /**
             * Atualizar status do antispam.
             */
            updateStatus()
            {
                const newValue = this.antispam.status;

                this.isLoadingStatus = true;

                axios.post(`/api/hosting/${this.resource}/spam`, { status: newValue })
                    .then ((response) => {
                        this.antispam.status = response.data.data.status;
                        GlobalAlert.success("Configuração atualizada com sucesso.");
                    })
                    .catch((error) => {
                        this.antispam.status = !newValue;
                        HandleErrors.ajaxErrorAlert(error, 0);
                    })
                    .then (() => this.isLoadingStatus = false);
            },

            /**
             * Adicionar endereço na blacklist.
             */
            addBlacklist()
            {
                this._addToList("blacklist");
            },

            /**
             * Remover item da blacklist.
             */
            removeBlacklist(index)
            {
                this._removeFromList("blacklist", index);
            },

            /**
             * Adicionar endereço na whitelist.
             */
            addWhitelist()
            {
                this._addToList("whitelist");
            },

            /**
             * Remover item da whitelist.
             */
            removeWhitelist(index)
            {
                this._removeFromList("whitelist", index);
            },

            /**
             * Filtrar uma das listas por um termo.
             *
             * @param listName Pode ser "blacklist" ou "whitelist".
             * @param val Valor do termo de busca.
             */
            _filterList(listName, val)
            {
                if (!this.antispam) return;

                listName = listName.toLowerCase();

                const listFilteredName = "filtered" + listName.charAt(0).toUpperCase() + listName.slice(1);

                if (val) {

                    let searchTerm = val.trim().toLowerCase();

                    this[listFilteredName] = this.antispam[listName].filter((element) => {
                        return element.email.toLowerCase().indexOf(searchTerm) !== -1;
                    });
                }
                else {
                    this[listFilteredName] = this.antispam[listName].slice(0);
                }
            },

            /**
             * Exibir modal de cadastro.
             * @param listName Pode ser "blacklist" ou "whitelist".
             */
            _openAddToListModal(listName)
            {
                listName = listName.toLowerCase();
                const listNameTitleCase = listName.charAt(0).toUpperCase() + listName.slice(1);

                this[listName] = {};
                this.errors = {};

                this.$refs["submitAdd"+listNameTitleCase].setLoading(false);
                this.$refs["cancelAdd"+listNameTitleCase].disabled = false;

                $(this.$refs["modalAdd"+listNameTitleCase]).modal("show");
            },

            /**
             * Adicionar endereço a uma das listas.
             * @param listName Pode ser "blacklist" ou "whitelist".
             */
            _addToList(listName)
            {
                listName = listName.toLowerCase();
                const listNameTitleCase = listName.charAt(0).toUpperCase() + listName.slice(1);

                this.errors = {};
                this.$refs["submitAdd" + listNameTitleCase].$el.focus();
                this.$refs["submitAdd" + listNameTitleCase].setLoading();
                this.$refs["cancelAdd" + listNameTitleCase].disabled = true;

                this["isLoading" + listNameTitleCase] = true;

                axios.post(`/api/hosting/${this.resource}/spam/${listName}`, this[listName])
                    .then(() => {
                        const new_item = { "email": this[listName].email, "loading": false };
                        this.antispam[listName].push(new_item);
                        this["filtered" + listNameTitleCase].push(new_item);
                        $(this.$refs["modalAdd" + listNameTitleCase]).modal("hide");
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, this);
                        this.$refs["cancelAdd" + listNameTitleCase].disabled = false;
                        this.$refs["submitAdd" + listNameTitleCase].setLoading(false);
                    })
                    .then(() => {
                        this["isLoading" + listNameTitleCase] = false;
                    });
            },

            /**
             * Remover endereço de uma das listas.
             * @param listName Pode ser "blacklist" ou "whitelist".
             * @param index
             */
            _removeFromList(listName, index)
            {
                listName = listName.toLowerCase();
                const listNameTitleCase = listName.charAt(0).toUpperCase() + listName.slice(1);

                const email   = this["filtered" + listNameTitleCase][index].email;
                const idxReal = this.antispam[listName].findIndex(el => el.email === email);

                this.$set(this.antispam[listName][idxReal], "loading", true);
                this.$set(this["filtered" + listNameTitleCase][index], "loading", true);

                axios.delete(`/api/hosting/${this.resource}/spam/${listName}/`+ encodeURIComponent(email))
                    .then(() => {
                        const newIdx  = this.antispam[listName].findIndex(el => el.email === email);
                        const newIdxF = this["filtered" + listNameTitleCase].findIndex(el => el.email === email);
                        this.antispam[listName].splice(newIdx, 1);
                        this["filtered" + listNameTitleCase].splice(newIdxF, 1);
                    })
                    .catch((error) => {
                        HandleErrors.ajaxErrorAlert(error);
                        this.$set(this.antispam[listName][idxReal], "loading", false);
                        this.$set(this["filtered" + listNameTitleCase][index], "loading", false);
                    });
            },
        },
        watch: {
            filterBlacklist: function(val)
            {
                this._filterList("blacklist", val);
            },
            filterWhitelist: function(val)
            {
                this._filterList("whitelist", val);
            },
        }
    }
</script>
