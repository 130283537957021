<template>
    <div class="file-manager">

        <!-- Breadcrumb -->
        <ul class="nav nav-breadcrumb mb-3">
            <li class="nav-item">
                <span class="nav-link">
                    <i class="far fa-folder-open mr-1"></i>
                </span>
            </li>
            <li class="nav-item">
                <a v-if="path && path != '/'" class="nav-link active" href="#" @click.stop.prevent="$emit('folder-changed', '/')">/</a>
                <span v-else class="nav-link">/</span>
            </li>
            <template v-for="(item, index) in breadcrumb">
                <li v-if="index > 0" class="nav-item">
                    <span class="nav-link">/</span>
                </li>
                <li v-if="index < breadcrumb.length-1" class="nav-item">
                    <a class="nav-link" href="#" @click.stop.prevent="$emit('folder-changed', item.path)">{{ item.label }}</a>
                </li>
                <li v-else class="nav-item">
                    <span class="nav-link">{{ item.label }}</span>
                </li>
            </template>
        </ul>

        <!-- Actions 1 -->
        <div class="btn-toolbar mb-3" role="toolbar">
            <button type="button" class="btn btn-outline-secondary" @click="$emit('home')" title="Início">
                <i class="fas fa-home" aria-hidden="true"></i>
            </button>

            <button type="button" class="btn btn-outline-secondary" @click="levelUp" title="Um nível acima">
                <i class="fas fa-level-up-alt" aria-hidden="true"></i>
            </button>

            <button type="button" class="btn btn-outline-secondary" @click="navBack" title="Voltar" :disabled="history.length < 2">
                <i class="fas fa-arrow-left" aria-hidden="true"></i>
            </button>

            <button type="button" class="btn btn-outline-secondary" @click="navForward" title="Avançar" :disabled="!historyAux.length">
                <i class="fas fa-arrow-right" aria-hidden="true"></i>
            </button>

            <button type="button" class="btn btn-outline-secondary" @click="$emit('refresh')" title="Recarregar (Alt + R)">
                <i class="fas fa-sync-alt" aria-hidden="true"></i>
            </button>

            <button v-show="!selected.length" type="button" class="btn btn-outline-secondary" title="Selecionar todos (Ctrl + A)" @click="selectAll">
                <i class="fas fa-check" aria-hidden="true"></i>
                <span>Selecionar todos</span>
            </button>

            <button v-show="selected.length" type="button" class="btn btn-outline-secondary" @click="selectAll">
                <i class="far fa-square" aria-hidden="true"></i>
                <span>Selecionar nenhum</span>
            </button>
        </div>

        <!-- Actions 2 -->
        <div class="btn-toolbar" role="toolbar">
            <button type="button" class="btn btn-outline-secondary" title="Nova Pasta" @click="$emit('create-folder')">
                <i class="fas fa-plus"></i>
                <span>Nova Pasta</span>
            </button>

            <button type="button" class="btn btn-outline-secondary" title="Novo Arquivo" @click="$emit('create-file')">
                <i class="fas fa-plus"></i>
                <span>Novo Arquivo</span>
            </button>

            <!--<button type="button" class="btn btn-outline-secondary" title="Copiar para..." disabled>
                <i class="fas fa-copy" aria-hidden="true"></i>
                <span>Copiar</span>
            </button>

            <button type="button" class="btn btn-outline-secondary" title="Mover para..." disabled>
                <i class="fas fa-file-import" aria-hidden="true"></i>
                <span>Mover</span>
            </button>-->

            <span class="pl-4"></span>

            <button type="button" class="btn btn-outline-secondary" title="Renomear (F2)" @click="rename()" :disabled="!renameEnabled">
                <i class="fas fa-font" aria-hidden="true"></i>
                <span>Renomear</span>
            </button>

            <!--<button type="button" class="btn btn-outline-secondary" title="Editar" disabled>
                <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                <span>Editar</span>
            </button>-->

            <button type="button" class="btn btn-outline-secondary" title="Comprimir" @click="compress()" :disabled="!compressionEnabled">
                <i class="far fa-file-archive" aria-hidden="true"></i>
                <span>Compactar</span>
            </button>

            <button type="button" class="btn btn-outline-secondary" title="Excluir (Del)" @click="remove()" :disabled="!removeEnabled">
                <i class="far fa-trash-alt" aria-hidden="true"></i>
                <span>Excluir</span>
            </button>

            <span class="pl-4"></span>

            <button type="button" class="btn btn-outline-secondary" title="Download" @click="download()" :disabled="!downloadEnabled">
                <i class="fas fa-cloud-download-alt" aria-hidden="true"></i>
                <span>Download</span>
            </button>

            <!--<button type="button" class="btn btn-outline-secondary" title="Upload" @click="$emit('upload')" disabled>
                <i class="fas fa-cloud-upload-alt" aria-hidden="true"></i>
                <span>Upload</span>
            </button>-->

            <label for="uploadFiles" class="btn btn-outline-secondary mb-0" title="Upload">
                <i class="fas fa-cloud-upload-alt" aria-hidden="true"></i>
                <span>Upload</span>
            </label>
        </div>

        <hr class="spacer my-3" aria-hidden="true">

        <!-- Files -->
        <div class="list-group list-header">
            <div class="list-group-item">
                <div class="row mx-0">
                    <div class="col-5">
                        <div class="header-control">Nome</div>
                    </div>
                    <div class="col-2">
                        <div class="header-control text-right">Tamanho</div>
                    </div>
                    <div class="col-2">
                        <div class="header-control text-right">Última modificação</div>
                    </div>
                    <div class="col-3">
                        <div class="header-control"></div>
                    </div>
                </div>
            </div>
        </div>

        <div ref="listGroup" class="list-group list-body">
            <div v-show="loading" class="list-group-item-wrapper">
                <div class="list-group-item">
                    <div class="row mx-0">
                        <div class="col">
                            <loading-message></loading-message>
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="!loading && !files.length" class="list-group-item-wrapper">
                <div class="list-group-item">
                    <div class="row mx-0">
                        <div class="col">
                            Nenhum arquivo encontrado.
                        </div>
                    </div>
                </div>
            </div>

            <drop
                class="list-group-item-wrapper"
                v-show="!loading && files.length"
                v-for="(file, index) in files"
                :key="file.id"
                @dragover="onDragOver(...arguments, index)"
                @dragleave="onDragLeave(...arguments, index)"
                @drop="onDrop(...arguments, index)"
            >
                <drag
                    :key="file.id"
                    :transfer-data="{ item: file, from: index }"
                    :draggable="!isDragging && file.id >= 0"
                    @dragstart="isDragging = true"
                    @dragend="isDragging = false"
                >
                    <div
                        class="list-group-item list-group-item-action hover-control"
                        v-bind:class="rowClasses(file)"
                        @click.exact.stop.prevent="selectSingle(file.id, index, $event)"
                        @click.ctrl.exact.stop.prevent="selectMultiple(file.id)"
                        @click.shift.stop.prevent="selectContiguous(file.id)"
                        @dblclick="enter(file, index, $event)"
                    >
                        <div class="row mx-0">
                            <div class="col-5">
                                <a href="#" @click="enter(file, index, $event)">
                                    <i class="fa-fw mr-1" v-bind:class="typeClasses(file.type)" ></i>
                                </a>
                                <filename
                                    v-bind:class="{'text-muted': file.loading}"
                                    v-model="files[index].name"
                                    :title="files[index].name"
                                    :editing="files[index].isRenaming"
                                    @click.native="renameInline(file, index, $event)"
                                    @double-click="enter(file, index, $event)"
                                    @change="renameCompleted($event, file, index)"
                                ></filename>
                                <i v-if="file.loading" class="fas fa-circle-notch fa-spin text-muted ml-1"></i>
                            </div>
                            <div class="col-2 text-right">
                                <span v-if="file.id >= 0">{{ file.size | filesize }}</span>
                            </div>
                            <div class="col-2 text-right">
                                <span v-if="file.id >= 0" :title="$moment.unix(file.date).format('DD/MM/YYYY - HH:mm')">
                                    {{ file.date | moment("from", new Date(), true) }}
                                </span>
                            </div>
                            <div class="col-3 py-0 text-right">
                                <div v-if="file.id >= 0" class="btn-group btn-group-actions visible-on-hover" role="group">
                                    <button type="button" class="btn btn-sm btn-outline-primary" title="Download" v-b-tooltip.hover @click="download(file)">
                                        <i class="fas fa-cloud-download-alt" aria-hidden="true"></i>
                                        <span class="sr-only">Download</span>
                                    </button>

                                    <button v-if="file.editable" type="button" class="btn btn-sm btn-outline-primary" title="Editar" v-b-tooltip.hover @click="enter(file, index)">
                                        <i class="fas fa-pen" aria-hidden="true"></i>
                                        <span class="sr-only">Editar</span>
                                    </button>

                                    <button v-if="file.type === 'zip'" type="button" class="btn btn-sm btn-outline-primary" title="Descompactar" v-b-tooltip.hover @click="extract(file)">
                                        <i class="fas fa-file-archive" aria-hidden="true"></i>
                                        <span class="sr-only">Descompactar</span>
                                    </button>

                                    <button type="button" class="btn btn-sm btn-outline-primary" title="Renomear" v-b-tooltip.hover @click="rename(index)">
                                        <i class="fas fa-font" aria-hidden="true"></i>
                                        <span class="sr-only">Renomear</span>
                                    </button>

                                    <button type="button" class="btn btn-sm btn-outline-primary" title="Alterar permissões" v-b-tooltip.hover @click="changePermissions(index)">
                                        <i class="fas fa-lock" aria-hidden="true"></i>
                                        <span class="sr-only">Alterar permissões</span>
                                    </button>

                                    <button type="button" class="btn btn-sm btn-outline-danger" title="Excluir" v-b-tooltip.hover @click="remove(file)">
                                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                                        <span class="sr-only">Excluir</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </drag>
            </drop>
        </div>

        <hr class="spacer my-3" aria-hidden="true">

        <!-- Upload -->
        <div>
            <div class="custom-dropzone-files" v-if="uploads.length">
                <div class="card">
                    <div class="card-header border-bottom-0 p-0">
                        <button class="btn btn-link w-100 text-left px-2" type="button" data-toggle="collapse" data-target="#collapseUploadFiles" aria-expanded="true" aria-controls="collapseUploadFiles">
                            Lista de arquivos <i class="fas fa-angle-down ml-1"></i>
                        </button>
                    </div>
                    <div id="collapseUploadFiles" class="collapse show">
                        <ul class="list-group list-group-flush mb-0" style="max-height:260px;overflow-y:scroll">
                            <li class="list-group-item py-1 px-2" v-for="(file, index) in uploads" :key="file.id" v-bind:class="{'border-top': index === 0}">
                                <div class="d-flex">
                                    <p class="mb-0">
                                        <span>{{ file.name }}</span> <span class="text-muted">{{ file.size | formatSize }}</span>
                                    </p>

                                    <span class="ml-auto">
                                        <i v-show="!file.success && !file.error" class="fas fa-circle-notch fa-spin"></i>
                                        <i v-show="file.success" class="fas fa-check-circle text-success"></i>
                                        <i v-show="file.error" class="fas fa-times-circle text-danger"></i>
                                    </span>

                                    <button v-if="file.success || file.error" type="button" class="btn btn-link p-0 border-0 ml-2" aria-label="Excluir" @click.prevent="removeUploadFile(file)">
                                        <i class="fas fa-times"></i>
                                    </button>
                                </div>
                                <div v-show="file.error">
                                    <p v-if="file.error" v-html="file.error" class="text-danger mb-0"></p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!--<div class="custom-dropzone" v-else>
                <div class="text-center p-5">
                    <h5>Arraste os arquivos aqui ou<br/></h5>
                    <label for="uploadFiles" class="btn btn-primary btn-rounded">Clique aqui para selecionar</label>
                </div>
            </div>-->

            <div v-show="$refs.uploadArea && $refs.uploadArea.dropActive && !isDragging" class="custom-dropzone-active">
                <h3>Solte os arquivos aqui</h3>
            </div>

            <div class="mt-3" v-show="uploads.length">
                <file-upload
                    ref="uploadArea"
                    input-id="uploadFiles"
                    class="btn btn-primary mb-0"
                    post-action=""
                    :multiple="true"
                    :drop="true"
                    :drop-directory="true"
                    :size="104857600"
                    v-model="uploads"
                    @input-file="inputFile">
                    Adicionar arquivos
                </file-upload>

                <button type="button" class="btn btn-secondary ml-1" @click="clearUploads">Limpar lista</button>
            </div>

            <!--<p class="form-text text-black-50 mt-2">Tamanho máximo de upload: 20MB</p>-->
        </div>

    </div>
</template>

<script>
    import CardDefault    from './CardDefault'
    import LoadingMessage from './LoadingMessage'
    import FileUpload     from 'vue-upload-component'
    import { Drag, Drop } from 'vue-drag-drop'
    // import { mixin as clickaway } from 'vue-clickaway'

    let EditableFilename = {
        props: {
            value: String,
            editing: {
                type: Boolean,
                default: false,
            },
            delay: {
                type: Number,
                default: 250
            }
        },
        data() {
            return {
                text: this.value,
                clickCount: 0,
                clickTimer: null,
            }
        },
        methods: {
            handleEnter(evt)
            {
                evt.preventDefault();
                $(evt.target).trigger('blur');

                return false;
            },
            handleBlur(evt)
            {
                let text = evt.target.textContent;
                this.text = text.trim();

                this.$refs.elm.innerHTML = this.text;
                this.$emit('change', this.text);
            },
            handleClick(evt)
            {
                evt.preventDefault();

                this.clickCount++

                if (this.clickCount === 1) {
                    this.clickTimer = setTimeout(() => {
                        this.clickCount = 0
                        this.$emit('single-click', evt)
                    }, this.delay)
                }
                else if (this.clickCount === 2) {
                    clearTimeout(this.clickTimer)
                    this.clickCount = 0
                    this.$emit('double-click', evt)
                }
            }
        },
        watch: {
            value: function(val, oldVal) {
                if (val != oldVal) {
                    this.text = val;
                    this.$refs.elm.innerHTML = this.text;
                }
            }
        },
        template: `
            <span ref="elm"
                  class="text-editable"
                  v-bind:class="{ 'is-editing': editing }"
                  spellcheck="false"
                  autocorrect="off"
                  :contenteditable="editing"
                  @keypress.enter.stop.prevent="handleEnter($event)"
                  @blur="handleBlur($event)"
                  @click="handleClick($event)"
                  v-html="text"></span>`,
    };

    export default {
        props: {
            files: {
                type: Array,
                default: [],
            },
            path: {
                type: String,
                default: "",
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                selected: [],
                history: [],
                historyAux: [],
                uploads: [],
                errors: {},

                // movingFile: null,
                // movingFileDest: null,
                isDragging: false,
                isRenaming: false,
            }
        },
        computed: {
            breadcrumb: function ()
            {
                let items = [];
                let pathSplitted = this.path.split('/');

                pathSplitted.shift();
                pathSplitted.forEach((el, idx) => {
                    items.push({
                        label: el,
                        path: "/" + pathSplitted.slice(0, idx+1).join("/"),
                    });
                });

                return items;
            },
            renameEnabled: function()
            {
                return (this.selected.length === 1 && this.selected[0] >= 0);
            },
            downloadEnabled: function()
            {
                if (this.selected && this.selected.length >= 1) {
                    if (this.selected.length === 1) {
                        const file = this.files.find(el => el.id === this.selected[0]);
                        return (file.id >= 0);
                    }
                    else {
                        return true;
                    }
                }

                return false;
            },
            compressionEnabled: function()
            {
                return this.downloadEnabled;
            },
            removeEnabled: function()
            {
                return this.downloadEnabled;
            }
        },
        mixins: [
            // clickaway
        ],
        components: {
            CardDefault,
            LoadingMessage,
            FileUpload,
            'drag': Drag,
            'drop': Drop,
            'filename': EditableFilename,
        },
        mounted()
        {
            this.enableKeyboardEvents();
        },
        beforeDestroy()
        {
            this.disableKeyboardEvents();
        },
        methods:
        {
            rowClasses (file)
            {
                return {
                    'active': this.selected.indexOf(file.id) !== -1,
                    'is-droppable': file.type === 'dir',
                    'hover': file.hover,
                }
            },
            typeClasses (type)
            {
                let classes = {};

                switch (type) {
                    case 'dir':  classes['far fa-folder-open'] = true; break;
                    case 'pdf':  classes['far fa-file-pdf'] = true; break;
                    case 'zip':  classes['far fa-file-archive'] = true; break;
                    case 'link': classes['fas fa-link'] = true; break;
                    case 'file':
                    default: classes['far fa-file'] = true;
                }

                return classes;
            },


            // SELECT / CLICK
            // ==============================
            selectSingle(id, index, evt)
            {
                if (this.files[index].isRenaming) return;

                if (this.selected.length == 1 && this.selected[0] == id) {
                    this.selected = [];
                }
                else {
                    this.selected = [ id ];
                }
            },
            selectMultiple(id)
            {
                if (this.selected.indexOf(id) === -1) {
                    this.selected.push(id);
                }
                else {
                    this.selected = this.selected.filter(el => el !== id);
                }
            },
            selectContiguous(id)
            {
                if (!this.selected.length) {
                    this.selected = [ id ];
                }
                else {
                    const lastSelection = this.selected[this.selected.length - 1];

                    let indexStart = this.files.findIndex(el => el.id == lastSelection);
                    let indexEnd   = this.files.findIndex(el => el.id == id);
                    let direction  = 1;

                    if (indexStart > indexEnd) {
                        direction = -1;
                    }

                    let i, fileId;

                    for (i = indexStart; i !== indexEnd; i += direction) {
                        fileId = this.files[i].id;

                        if (this.selected.indexOf(fileId) !== -1) {
                            this.selected = this.selected.filter(el => el !== fileId);
                        }

                        this.selected.push( this.files[i].id );
                    }

                    this.selected.push( this.files[indexEnd].id );
                }
            },
            selectAll()
            {
                if (this.selected.length) {
                    this.selected = [];
                }
                else {
                    let selected = this.files.map(el => el.id);
                    this.$set(this, 'selected', selected);
                }
            },

            /**
             * Atalho para selecionar todos os arquivos.
             * Ctrl + A
             */
            selectAllEvent(evt)
            {
                if (!this.isRenaming && evt.keyCode === 65 && (evt.ctrlKey || (this.isMac() && this.metaKey)) && !evt.altKey && !evt.shiftKey) {
                    evt.preventDefault();

                    let selected = this.files.map(el => el.id);
                    this.$set(this, 'selected', selected);
                }
            },
            clickedAway()
            {
                // Does nothing
            },
            removeSelection()
            {
                this.$set(this, 'selected', []);
            },


            // DRAG AND DROP
            // ==============================
            onDragOver({ item, from }, evt, to)
            {
                this.$set(this.files[to], 'hover', true);
            },
            onDragLeave({ item, from }, evt, to)
            {
                if (evt.relatedTarget && (evt.target.contains(evt.relatedTarget) || evt.relatedTarget.contains(evt.target))) {
                    return false;
                }

                this.$set(this.files[to], 'hover', false);
            },
            onDrop({ item, from }, evt, to)
            {
                this.$set(this.files[to], 'hover', false);

                let items = [item];

                if (this.selected.length > 1) {
                    items = this.files.filter(el => el.id !== -1 && this.selected.indexOf(el.id) !== -1);
                }

                // Arrastar para o mesmo elemento
                if (items.length == 1 && items[0].id == this.files[to].id) return;

                // Arrastar um grupo para um elemento que está selecionado
                if (items.length > 1 && items.find(el => el.id == this.files[to].id)) return;

                if (this.files[to].type === 'dir') {
                    this.$emit('file-moved', items, this.files[to]);
                    this.selected = [];
                }

                this.isDragging = false;
            },


            // NAVIGATION
            // ==============================
            enter(file, index, evt)
            {
                if (file.loading || this.files[index].isRenaming) return;

                this.selected = [ file.id ];
                this.historyAux = [];

                this.$emit('file-entered', file);
            },
            levelUp()
            {
                if (this.path == "/") return;

                let new_path = this.path.substr(0, this.path.lastIndexOf("/"));
                new_path = new_path ? new_path : "/";

                this.$emit('folder-changed', new_path );
            },
            navBack()
            {
                if (this.history.length < 2) return;

                this.historyAux.push(this.history.pop());
                this.$emit('folder-changed', this.history[this.history.length-1], false);
            },
            navForward()
            {
                if (this.historyAux.length < 1) return;

                this.$emit('folder-changed', this.historyAux.pop());
            },
            historyPush(path)
            {
                this.history.push(path);
            },

            /**
             * Atalho para recarregar arquivos.
             * Alt + R
             */
            refreshEvent(evt)
            {
                if (evt.keyCode === 82 && evt.altKey && !evt.ctrlKey && !evt.metaKey && !evt.shiftKey) {
                    evt.preventDefault();
                    this.$emit('refresh');
                }
            },

            // RENAME
            // ==============================
            /**
             * Renomear arquivo em linha.
             */
            renameInline(file, index, evt)
            {
                if (file.loading) return;

                if (this.selected.indexOf(file.id) !== -1) {
                    evt.stopPropagation();

                    this.$set(this.files[index], 'isRenaming', true);
                    this.isRenaming = true;
                    this.selected = [file.id];

                    this.$nextTick(() => {
                        $(evt.target).focus();
                    });
                }
                else {

                }
            },

            /**
             * Finalizar ação de renomear.
             */
            renameCompleted(value, file, index)
            {
                this.$set(this.files[index], 'isRenaming', false);
                this.isRenaming = false;

                this.$emit('file-renamed', ...arguments);
            },

            /**
             * Emitir evento de renomear arquivo ou pasta.
             */
            rename(index)
            {
                let file = this.files.find(el => el.id === this.selected[0]);

                if (typeof index !== "undefined" && Number.isInteger(index)) {
                    file = this.files[index];
                }

                this.$emit('rename', file);
            },

            /**
             * Atalho para renomear arquivo.
             * F2
             */
            renameEvent(evt)
            {
                if (evt.keyCode === 113 && !evt.ctrlKey && !evt.metaKey && !evt.altKey && !evt.shiftKey && this.renameEnabled) {
                    evt.preventDefault();
                    this.rename();
                }
            },

            /**
             * Emitir evento para compactar um ou mais arquivos.
             */
            compress(file)
            {
                let items = [];

                if (typeof file !== "undefined") {
                    items.push(file);
                }
                else {
                    items = this.files.filter(el => el.id >= 0 && this.selected.indexOf(el.id) !== -1);
                }

                if (items.length) {
                    this.$emit('compress', items);
                }
            },

            /**
             * Emitir evento de descompactar um arquivo.
             */
            extract(file)
            {
                if (typeof file === "undefined") return;

                this.$emit('extract', file);
            },

            /**
             * Emitir evento de fazer download de um ou mais arquivos.
             */
            download(file)
            {
                let items = [];

                if (typeof file !== "undefined") {
                    items.push(file);
                }
                else {
                    items = this.files.filter(el => el.id >= 0 && this.selected.indexOf(el.id) !== -1);
                }

                this.$emit('download', items);
            },

            /**
             * Emitir evento de excluir arquivo ou pasta.
             */
            remove(file)
            {
                let items = [];

                if (typeof file !== "undefined") {
                    items.push(file);
                }
                else {
                    items = this.files.filter(el => el.id >= 0 && this.selected.indexOf(el.id) !== -1);
                }

                if (items.length) {
                    this.$emit('remove', items);
                }
            },

            /**
             * Atalho para remover arquivos.
             * Del
             */
            removeEvent(evt)
            {
                if (!this.isRenaming && evt.keyCode === 46 && !evt.ctrlKey && !evt.metaKey && !evt.altKey && this.removeEnabled) {
                    evt.preventDefault();
                    this.remove();
                }
            },

            // UPLOAD
            // ==============================
            inputFile(newFile, oldFile)
            {
                if (newFile && !oldFile)
                {
                    // Limite de 100 MB
                    if (newFile.size > 100 * 1024 * 1024) {
                        this.$refs.uploadArea.update(newFile, { error: 'O tamanho máximo permitido é de 100 MB.' });
                    }
                    else {
                        this.$emit('upload', newFile);
                    }
                }
            },

            updateUploadFile(file, options)
            {
                this.$refs.uploadArea.update(file, options);
                this.checkRefreshAfterUpload();
            },

            removeUploadFile(file)
            {
                this.$refs.uploadArea.remove(file)
            },

            checkRefreshAfterUpload()
            {
                if (this.uploads.length) {
                    let refreshFiles = true;

                    for (let i = 0; i < this.uploads.length; i++) {
                        if (!this.uploads[i].success && !this.uploads[i].error) {
                            refreshFiles = false;
                            break;
                        }
                    }

                    if (refreshFiles) {
                        this.$emit("refresh");
                    }
                }
            },

            clearUploads()
            {
                if (this.uploads.length) {
                    let ids = [];

                    for (let i = 0; i < this.uploads.length; i++) {
                        if (this.uploads[i].success || this.uploads[i].error) {
                            ids.push(this.uploads[i].id);
                        }
                    }

                    for (let i = 0; i < ids.length; i++) {
                        this.$refs.uploadArea.remove(ids[i]);
                    }
                }
            },

            /**
             * Alterar permissões do arquivo.
             */
            changePermissions(index)
            {
                if (typeof index !== "undefined" && Number.isInteger(index)) {
                    this.$emit('change-permissions', this.files[index]);
                }
            },

            /**
             * Habilitar atalhos do teclado.
             */
            enableKeyboardEvents()
            {
                window.addEventListener('keydown', this.selectAllEvent);
                window.addEventListener('keydown', this.refreshEvent);
                window.addEventListener('keydown', this.renameEvent);
                window.addEventListener('keydown', this.removeEvent);
            },

            /**
             * Desabilitar atalhos do teclado.
             */
            disableKeyboardEvents()
            {
                window.removeEventListener('keydown', this.selectAllEvent);
                window.removeEventListener('keydown', this.refreshEvent);
                window.removeEventListener('keydown', this.renameEvent);
                window.removeEventListener('keydown', this.removeEvent);
            },
        },
        watch: {
            // TODO: Comentado pois está inteferindo com outras funções
            // files(val, oldVal) {
            //     this.selected = [];
            // }
        }
    }
</script>