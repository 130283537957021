// services/HostingService.js

import axios from 'axios';

let _promise = null;

export const getHosting = (domain) => {
    return new Promise((resolve, reject) => {
        if (typeof Store !== "undefined" && Store.hosting && Store.domain && Store.domain === domain) {
            resolve(Store.hosting);
        }
        else {
            resolve(updateHosting(domain));
        }
    });
};

export const updateHosting = (domain) => {
    if (_promise) {
        return _promise;
    }
    else {
        _promise = new Promise((resolve, reject) => {
            axios.get(`/api/hosting/${domain}`)
                .then((response) => {
                    Store.domain = domain;
                    Store.hosting = response.data.data;

                    _promise = null;
                    resolve(Store.hosting);
                })
                .catch((error) => {
                    _promise = null;
                    reject(error);
                });
        });

        return _promise;
    }
};