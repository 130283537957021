<template>
    <div>
        <card-default class="card-hosting-apps" :title="$route.meta.title" icon="fas fa-th">
            <template slot="buttons">
                <router-link class="btn btn-primary btn-rounded" :to="'/hosting/' + resource + '/apps/install'">Instalar aplicativo</router-link>
            </template>

            <p class="mb-4">
                Todos os aplicativos instalados em sua hospedagem estarão listados abaixo.
            </p>

            <div class="card card-table">
                <table class="table" v-bind:class="[apps.length ? 'table-hover' : '']">
                    <thead>
                        <tr>
                            <th scope="col" class="col-200 col-nowrap">Aplicativo</th>
                            <th scope="col" class="col-200">Diretório</th>
                            <th scope="col">Título</th>
                            <th scope="col" class="col-datetime">Instalado em</th>
                            <th scope="col" class="col-100 col-options">
                                <span class="sr-only">Opções</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="isLoading">
                            <td colspan="5">
                                <loading-message>Carregando seus aplicativos&hellip;</loading-message>
                            </td>
                        </tr>

                        <tr v-else-if="!isLoading && !apps.length">
                            <td colspan="5">
                                Nenhum aplicativo encontrado.
                                <span v-if="isFirstInstall">
                                    <router-link :to="'/hosting/' + resource + '/apps/install'">Clique aqui para instalar o primeiro aplicativo</router-link>.
                                </span>
                            </td>
                        </tr>

                        <tr v-else v-for="(item, index) in apps">
                            <td class="col-200 col-nowrap">{{ item.app }}</td>
                            <td class="col-200">{{ item.dir }}</td>
                            <td>{{ item.title }}</td>
                            <td class="col-datetime">
                                <span :title="$moment(item.updatedAt).format('DD/MM/YYYY - HH:mm:ss')">
                                    {{ item.createdAt | moment("from", new Date()) }}
                                </span>
                            </td>
                            <td class="col-100 col-options">
                                <div class="btn-group visible-on-hover" role="group">
                                    <a class="btn btn-sm btn-outline-primary" title="Acessar" :href="item.url" target="_blank">
                                        <i class="fas fa-external-link-alt" aria-hidden="true"></i>
                                        <span class="sr-only">Acessar</span>
                                    </a>
                                    <button type="button" class="btn btn-sm btn-outline-danger" title="Remover" @click="confirmRemove(index)">
                                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                                        <span class="sr-only">Remover</span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </card-default>

        <div id="modalConfirmRemove" ref="modalConfirmRemove" role="dialog" aria-hidden="true" class="modal fade" v-modal-animated>
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div tabindex="-1" role="document" class="modal-content">
                    <header class="modal-header bg-light border-bottom-0">
                        <h5 class="modal-title">Remover aplicativo</h5>
                    </header>
                    <div class="modal-body">
                        <p class="mb-0">
                            Tem certeza que deseja remover o aplicativo <strong v-if="typeof apps[removeIndex] != 'undefined'">{{ apps[removeIndex].title }}</strong>?
                        </p>
                    </div>
                    <footer class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-rounded" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-danger btn-rounded" @click="removeApp">Remover</button>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CardDefault    from '../../components/CardDefault'
    import LoadingMessage from '../../components/LoadingMessage'

    export default {
        props: ['resource'],
        data() {
            return {
                apps: [],
                isLoading: false,
                isFirstInstall: false,
                removeIndex: null,
            }
        },
        components: {
            CardDefault,
            LoadingMessage,
        },
        mounted()
        {
            this.fetchApps();
        },
        methods: {
            fetchApps()
            {
                this.isLoading = true;

                axios.get(`/api/hosting/${this.resource}/apps`)
                    .then ((response) => {
                        this.apps = response.data.data;

                        if (!this.apps.length) {
                            this.isFirstInstall = true;
                        }
                    })
                    .catch((error) => HandleErrors.formError(error, this))
                    .then (() => this.isLoading = false);
            },
            confirmRemove(index)
            {
                this.removeIndex = index;
                $(this.$refs.modalConfirmRemove).modal("show");
            },
            removeApp()
            {
                let app = this.apps[this.removeIndex];

                $(this.$refs.modalConfirmRemove).modal("hide");
                Loading.show();

                setTimeout(() => {
                    this.apps.splice(this.removeIndex, 1);
                    Loading.hide();
                }, 2000);

                axios.delete(`/api/hosting/${this.resource}/apps/${app.id}`)
                    .then ((response) => {})
                    .catch((error) => HandleErrors.formError(error, this));
            },
        }
    }
</script>