<template>
    <tr class="has-link" v-on:click="onClick">
        <slot></slot>
    </tr>
</template>

<script>
    export default {
        props: ["link"],
        methods: {
            onClick() {
                this.$router.push(this.link);
            }
        }
    }
</script>
