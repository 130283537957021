<template>
    <div>
        <card-default v-show="!creating && !editing" :title="$route.meta.title" icon="fas fa-database">
            <template slot="buttons">
                <button type="button" class="btn btn-primary btn-rounded" @click="showCreateForm">
                    Adicionar banco de dados
                </button>
            </template>

            <div class="card card-table">
                <table class="table" v-bind:class="[databases.length ? 'table-hover' : '']">
                    <thead>
                        <tr>
                            <th>Tipo</th>
                            <th>Nome</th>
                            <th>Usuário</th>
                            <th>Servidor</th>
                            <th class="text-right">Tamanho</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="isLoading">
                            <td colspan="6">
                                <loading-message></loading-message>
                            </td>
                        </tr>
                        <tr v-else-if="!isLoading && !databases.length">
                            <td colspan="6">
                                Nenhum banco de dados encontrado.
                            </td>
                        </tr>
                        <tr v-else v-for="(item, index) in databases">
                            <td class="col-150">{{ dbmsName(item.dbms) }}</td>
                            <td>{{ item.dbname }}</td>
                            <td>{{ item.user }}</td>
                            <td>{{ item.host }}</td>
                            <td class="col-100 text-right">{{ item.size | filesize }}</td>
                            <td class="col-100 col-nowrap text-right py-0 align-middle">
                                <a v-if="item.dbms == 'mysql'" :href="phpmyadmin" target="_blank" class="btn btn-sm btn-link" title="Acesso externo">
                                    <i class="fas fa-database" aria-hidden="true"></i>
                                    <span class="sr-only">Acesso externo</span>
                                </a>
                                <a v-if="item.dbms == 'postgres'" :href="phppgadmin" target="_blank" class="btn btn-sm btn-link" title="Acesso externo">
                                    <i class="fas fa-database" aria-hidden="true"></i>
                                    <span class="sr-only">Acesso externo</span>
                                </a>
                                <button type="button" class="btn btn-sm btn-link" title="Editar" @click="showEditForm(index)">
                                    <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                                    <span class="sr-only">Editar</span>
                                </button>
                                <button type="button" class="btn btn-sm btn-link text-danger" title="Excluir" @click="deleteDatabase(index)">
                                    <i class="far fa-trash-alt" aria-hidden="true"></i>
                                    <span class="sr-only">Excluir</span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!--<hr class="spacer mb-5" aria-hidden="true">

            <h5 class="card-subtitle">Acesso remoto (MySQL)</h5>
            <p class="mb-4">
                Permite que os bancos de dados sejam acessados de um endereço externo. O acesso remoto é permitido apenas
                para bancos MySQL.
            </p>

            <div class="mb-3">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="flex-grow-1 pl-2">
                        <label class="form-label">Habilitar acesso remoto</label>
                        <p class="form-text text-muted mb-0">
                            É possível liberar o acesso para um ou mais IPs. Para informar múltiplos IPs, separe-os usando
                            vírgula.
                        </p>
                    </div>
                    <div class="pl-4">
                        <span class="switch switch-sm">
                            <input type="checkbox" class="switch" id="dbRemote" v-model="database.remote">
                            <label for="dbRemote" class="mb-0"></label>
                        </span>
                    </div>
                </div>

                <b-collapse id="collapseDbRemote" v-model="database.remote">
                    <form-control v-show="!database.remoteAny" :error="errors.remoteIp" class="mt-2 mb-0">
                        <label class="sr-only" for="dbRemoteHost">Endereço IP</label>
                        <input type="text" id="dbRemoteHost" class="form-control input-rounded" placeholder="Endereço IP ou múltiplos IPs separados por vírgula" spellcheck="false" v-model="database.remoteIp">
                    </form-control>
                    <div class="custom-control custom-checkbox ml-2 mt-2">
                        <input type="checkbox" class="custom-control-input" id="dbRemoteAny" v-model="database.remoteAny">
                        <label class="custom-control-label" for="dbRemoteAny">Permitir acesso de qualquer IP</label>
                    </div>
                </b-collapse>
            </div>-->

        </card-default>

        <card-default v-show="!creating && !editing">
            <h5 class="card-subtitle">Versão do banco de dados</h5>

            <div v-if="isLoadingInfo">
                <loading-message></loading-message>
            </div>

            <table v-else class="table table-sm table-borderless mb-0">
                <tbody>
                    <tr class="copy-text-wrapper">
                        <th class="col-50 col-nowrap pl-0">MySQL:</th>
                        <td><text-copy v-if="databaseInfo.version" :text="databaseInfo.version.mysql"></text-copy></td>
                    </tr>
                    <tr class="copy-text-wrapper">
                        <th class="col-50 col-nowrap pl-0">PostgreSQL:</th>
                        <td><text-copy v-if="databaseInfo.version" :text="databaseInfo.version.postgres"></text-copy></td>
                    </tr>
                </tbody>
            </table>
        </card-default>

        <card-default v-if="remote" v-show="!creating && !editing">
            <div class="d-flex justify-content-between align-items-center">
                <div class="flex-grow-1">
                    <h6 class="card-subtitle mt-0">Acesso remoto (MySQL)</h6>
                    <p class="mb-0">
                        Permite que os bancos de dados sejam acessados de um endereço externo. Disponível apenas para bancos MySQL.
                    </p>
                </div>

                <div v-show="isLoadingRemoteAccess" class="pl-3">
                    <i class="d-block fas fa-circle-notch fa-spin"></i>
                </div>

                <div class="pl-3">
                    <span class="switch switch-sm">
                        <input
                            type="checkbox"
                            class="switch"
                            id="dbRemoteAccess"
                            v-model="remote.active"
                            @change="updateRemoteAccess"
                            :disabled="isLoadingRemoteAccess">
                        <label for="dbRemoteAccess" class="mb-0"></label>
                    </span>
                </div>
            </div>

            <b-collapse id="collapseRemoteAccess" v-model="remote.active">
                <div class="form-group mb-0 mt-2">
                    <b-form-radio-group v-model="remote.mode" stacked @change="changeRemoteAccessMode">
                        <b-form-radio value="ALL" :disabled="isLoadingRemoteAccess">Permitir para todos os IPs</b-form-radio>
                        <b-form-radio value="IPS" :disabled="isLoadingRemoteAccess">Limitar a apenas alguns IPs</b-form-radio>
                    </b-form-radio-group>
                </div>

                <form-control class="mt-2 mb-0" v-show="remote.mode === 'IPS'">
                    <label class="sr-only" for="remoteAccessHost">Host</label>
                    <input type="text"
                           id="remoteAccessHost"
                           class="form-control input-rounded"
                           placeholder="Endereço IP ou múltiplos IPs separados por vírgula"
                           spellcheck="false"
                           v-model="remote.hosts">
                </form-control>

                <button type="submit"
                        class="btn btn-primary btn-rounded mt-3"
                        v-bind:class="{'btn-loading': isLoadingRemoteAccess}"
                        v-show="remote.mode === 'IPS'"
                        :disabled="isLoadingRemoteAccess"
                        @click="updateRemoteAccess">Salvar</button>
            </b-collapse>
        </card-default>

        <card-default v-show="creating || editing">
            <h3 class="card-title-main mb-4">
                <span v-if="creating">Novo Banco de Dados</span>
                <span v-else-if="editing">Editar Banco de Dados</span>
            </h3>

            <form accept-charset="UTF-8" @submit.prevent="save">
                <form-control v-show="creating" :error="errors.dbms">
                    <label class="form-label ml-2" for="dbms">Tipo de banco</label>
                    <div>
                        <b-form-radio-group buttons button-variant="outline-primary" v-model="database.dbms" :options="dbmsList" />
                    </div>
                </form-control>

                <div v-show="editing" class="form-group">
                    <label class="form-label ml-2" for="dbms">Tipo de banco</label>
                    <input type="text" id="dbms" class="form-control input-rounded" :value="dbmsName(database.dbms)" readonly>
                </div>

                <form-control :error="errors.dbname">
                    <label class="form-label ml-2" for="dbname">Nome do banco</label>
                    <input type="text" id="dbname" class="form-control input-rounded" v-model="database.dbname" v-input-lowercase :readonly="editing">
                </form-control>

                <form-control :error="errors.user">
                    <label class="form-label ml-2" for="user">Usuário</label>
                    <input type="text" id="user" class="form-control input-rounded" v-model="database.user" v-input-lowercase :readonly="editing">
                </form-control>

                <form-control :error="errors.password">
                    <label class="form-label ml-2" for="dbpassword">Senha</label>
                    <password-generator id="dbpassword" autocomplete="new-password" rounded v-model="database.password" />
                </form-control>

                <div class="form-buttons">
                    <button-submit ref="submit" class="btn-rounded">
                        <span v-if="editing">Salvar</span>
                        <span v-else>Criar</span>
                    </button-submit>
                    <button type="button" ref="cancel" class="btn btn-secondary btn-rounded" @click="cancelForm">Cancelar</button>
                </div>
            </form>
        </card-default>
    </div>
</template>

<script>
    import CardDefault       from '../../components/CardDefault'
    import FormControl       from '../../components/FormControl'
    import PasswordGenerator from '../../components/PasswordGenerator'
    import LoadingMessage    from '../../components/LoadingMessage'
    import GlobalAlert       from '../../services/GlobalAlertService'
    import TextCopy          from '../../components/TextCopy'

    export default {
        props: ['resource'],
        data() {
            return {
                databaseInfo: {},
                databases: [],
                database: {
                    dbms: ""
                },
                dbmsList: [
                    { text: 'MySQL', value: 'mysql' },
                    { text: 'PostgreSQL', value: 'postgres' },
                ],
                phpmyadmin: "",
                phppgadmin: "",
                creating: false,
                editing: false,
                errors: {},

                remote: null,

                isLoading: false,
                isLoadingInfo: false,
                isLoadingRemoteAccess: false,
            }
        },
        components: {
            CardDefault,
            FormControl,
            PasswordGenerator,
            LoadingMessage,
            TextCopy,
        },
        mounted()
        {
            this.getDatabaseInfo();

            this.fetch().then(() => {
                if (!this.databases.length) {
                    this.showCreateForm();
                }
            });
        },
        methods: {
            dbmsName: function(dbms)
            {
                let name = "";
                let item = this.dbmsList.find(el => el.value == dbms);

                if (item) { name = item.text }

                return name;
            },

            /**
             * Obter informações sobre os bancos de dados.
             */
            getDatabaseInfo()
            {
                this.isLoadingInfo = true;

                axios.get(`/api/hosting/${this.resource}/database/info`)
                    .then ((response) => this.databaseInfo = response.data.data)
                    .catch((error) => {})
                    .then (() => this.isLoadingInfo = false);
            },

            fetch()
            {
                this.isLoading = true;

                return axios.get(`/api/hosting/${this.resource}/database`)
                    .then ((response) => {
                        this.databases  = response.data.data.databases;
                        this.phpmyadmin = response.data.data.phpmyadmin;
                        this.phppgadmin = response.data.data.phppgadmin;

                        this.remote = response.data.data.remote;
                        this.remote.hosts = this.remote.hosts.join(",");
                    })
                    .catch((error) => HandleErrors.formError(error, this))
                    .then (() => this.isLoading = false);
            },
            save()
            {
                let vm = this;
                this.errors = {};

                this.$refs.submit.$el.focus();
                this.$refs.submit.setLoading();
                this.$refs.cancel.disabled = true;

                let postData = {
                    url: `/api/hosting/${this.resource}/database`,
                    method: 'post',
                    data: this.database,
                };

                if (this.editing) {
                    postData.url += "/" + this.database.id;
                    postData.method = "put";
                }

                axios(postData)
                    .then((response) => {
                        this.cancelForm();
                        this.fetch();
                    })
                    .catch((response) => {
                        HandleErrors.formError(response, vm);
                    })
                    .then(() => {
                        this.$refs.cancel.disabled = false;
                        this.$refs.submit.setLoading(false);
                    });
            },
            showCreateForm()
            {
                this.database = { dbms: this.dbmsList[0].value };
                this.creating = true;
                this.editing  = false;
            },
            showEditForm(index)
            {
                this.database    = $.extend({}, this.databases[index]);
                this.database.id = this.database.dbname;
                this.editing     = true;
            },
            cancelForm()
            {
                this.creating = false;
                this.editing  = false;
                this.database = {};
            },
            deleteDatabase(index)
            {
                if (!confirm('Tem certeza que deseja excluir este banco de dados?')) return;

                let data = {
                    'dbms':   this.databases[index].dbms,
                    'dbname': this.databases[index].dbname,
                };

                // Remover linha da tabela
                Loading.show();

                setTimeout(() => {
                    this.databases.splice(index, 1);
                    Loading.hide();
                }, 3000);

                // Excluir banco de dados
                axios.post(`/api/hosting/${this.resource}/database/remove`, data)
                    .then((response) => {})
                    .catch((response) => {
                        alert("Ocorreu um erro ao tentar excluir o banco de dados. Por favor, atualize sua página e tente novamente.");
                    });
            },

            /**
             *
             */
            changeRemoteAccessMode(value)
            {
                if (value === 'ALL') {
                    this.$nextTick(() => this.updateRemoteAccess());
                }
            },

            /**
             * Atualizar opção de acesso remoto.
             */
            updateRemoteAccess()
            {
                this.isLoadingRemoteAccess = true;

                axios.patch(`/api/hosting/${this.resource}/database/remote`, this.remote)
                    .then ((response) => {
                        this.remote = response.data.data;
                        this.remote.hosts = this.remote.hosts.join(",");

                        GlobalAlert.success("Configuração atualizada com sucesso.");
                    })
                    .catch((error) => {
                        HandleErrors.formError(error, this);
                    })
                    .then (() => this.isLoadingRemoteAccess = false);
            },
        }
    }
</script>
